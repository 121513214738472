import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@infinigrow/libs';

import useOnClickOutside from 'hooks/useOnClickOutside';

import Tooltip from 'components/controls/Tooltip';
import Popup from 'components/Popup';
import InfoMarker from 'components/pages/InfoMarker';
import TextArea from 'components/controls/TextArea';

import { validateFilterLogicTextInput } from 'components/pages/integrations/logic/FilterLogicExpressionPopup';

import filterStyles from 'styles/users/filters.css';

const styles = filterStyles.locals;

export default function FilterLogicExpressionPopup({
  funnel, setSelectedFunnelRow, filtersCount, updateFilterLogic, filterLogicValue, isShowFilterLogicUpdateMessage,
}) {
  const refPopupFilter = useRef();
  const [showPopup, setShowPopup] = useState(false);

  const [filterLogicInput, setFilterLogicInput] = useState({
    value: filterLogicValue,
    isDisabled: true,
    errorMessage: null,
    filtersCount,
  });

  useEffect(() => {
    setFilterLogicInput({
      value: filterLogicValue,
      isDisabled: true,
      errorMessage: null,
    });
  }, [showPopup]);

  function closePopup() {
    setShowPopup(false);
    setSelectedFunnelRow(null);
  }

  async function onApplyFilterLogic() {
    await updateFilterLogic({ value: filterLogicInput.value });
    closePopup();
  }

  useOnClickOutside(refPopupFilter, () => {
    if (showPopup) {
      closePopup();
    }
  });

  function openPopup() {
    setShowPopup(true);
    setSelectedFunnelRow(funnel);
  }

  function handleKeyDown(event) {
    const validKeys = /[0-9ANDORandor() \b]/;
    const input = event.key;
    if (!validKeys.test(input) || event.key === 'Enter') {
      event.preventDefault();
    }
  }

  function onChangeFilterLogicInput(event) {
    const { value } = event.target;
    const { isValid, errorMessage } = validateFilterLogicTextInput({ input: value, filtersCount });
    setFilterLogicInput({
      ...filterLogicInput, value, isDisabled: !isValid, errorMessage,
    });
  }

  return (
    <div ref={refPopupFilter} className={styles.filterLogicWrapper}>
      <Tooltip
        id={`tooltip-addFilterLogic-${funnel}`}
        tip="Add filter logic"
      >
        <div
          className={styles.addFilterLogic}
          onClick={() => openPopup()}
        />
      </Tooltip>
      {isShowFilterLogicUpdateMessage && <div className={styles.filterWasUpdateMessage}>Filter logic was updated</div>}
      {showPopup && (
      <Popup
        className={styles.filterLogicPopup}
      >
        <div className={styles.filterLogicPopupHeader}>
          Edit filter logic
          <InfoMarker
            tooltipText="Use operators to define a logic for this filter. For example: 1 AND (2 OR 3)"
            place="top"
          />
        </div>
        <TextArea
          className={styles.filterLogicPopupTextArea}
          handleKeyDown={(event) => handleKeyDown(event)}
          onChange={(event) => onChangeFilterLogicInput(event)}
          value={filterLogicInput.value}
          errorMessage={filterLogicInput.errorMessage}
        />
        <div className={styles.filterLogicPopupButtons}>
          <Button type="secondaryBlue" onClick={() => closePopup()}>
            Cancel
          </Button>
          <Button
            type="primaryBlue"
            onClick={() => onApplyFilterLogic()}
            disabled={filterLogicInput.isDisabled}
          >
            Apply
          </Button>
        </div>
      </Popup>
      )}
    </div>
  );
}
