import React from 'react';
import { inject, observer } from 'mobx-react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash';

import AttributionTable from 'components/pages/analyze/AttribuitonTable/AttributionTable';
import Component from 'components/Component';
import FeatureFlagsWithBlur from 'components/common/FeatureFlagsWithBlur';
import ImpactBySegment from 'components/pages/analyze/SegmentsTab/ImpactBySegment';
import BreadcrumbsTitle from 'components/common/BreadcrumbsTitle';
import interactionsStore from 'stores/interactionsStore';

import { FREQUENCY_VALUES } from 'components/utils/frequency';
import { analyzeWidgetsResultKeyNames } from 'stores/logic/enums';
import { compose, ATTRIBUTION_TYPES } from 'components/utils/utils';
import {
  getSavedSelectedSegments, parseSegmentsForRequest, getSegmentsOptionsFields,
} from 'components/pages/analyze/OverviewTab/logic';
import {
  getWidgetsData, getWidgetsDataFromStore, getWidgetsDataV2, getWidgetFullConfig, getWidgetsDataFromStoreV2,
} from 'components/pages/analyze/widgetsRequest';
import { widgetTypes, flagsNames } from 'components/pages/analyze/enums';
import { getFilteredSegmentAnalysisRecords, getFilteredImpactBySegmentRecords } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { convertShowOnlyFilters, getImpactByFieldsToEnrichAccordingSegment } from 'components/pages/analyze/SegmentsTab/logic/ImpactBySegment';
import { navigateBetweenAnalyzeTabs } from 'stores/analyze/logic/navigations';
import { isFiltersChanged, isTimeframeChanged } from 'components/pages/analyze/logic/Tabs';
import { isFlagActive } from 'components/pages/analyze/logic/FeatureFlagsWithBlur';
import { segmentsTimeValues } from 'components/pages/analyze/AttribuitonTable/logic/AttributionTable';
import { segmentsTimeOptions } from 'components/pages/analyze/AttribuitonTable/enums';
import { pageLabels } from 'enums';

import style from 'styles/analyze/analyze.css';

const enhance = compose(
  inject(({
    widgetsAnalysisStore: {
      getWidgetRequestId,
      dataPerWidget: {
        [widgetTypes.segmentsAnalysis]: segmentsAnalysis,
        [widgetTypes.impactBySegment]: impactBySegment,
        [widgetTypes.upliftBySegments]: upliftBySegments,
      },
      configPerWidget: {
        [widgetTypes.impactBySegment]: impactBySegmentSavedConfig,
      },
    },
    analysisStore: {
      dataPerWidget: {
        [widgetTypes.trendBySegments]: trendBySegments,
        [widgetTypes.accountOptimizationMetrics]: accountOptimizationMetrics,
      },
      configPerWidget: {
        [widgetTypes.segmentsAnalysis]: segmentsAnalysisConfig,
      },
      updateConfigPerWidget,
    },
    userStore: {
      userMonthPlan: {
        region,
      },
      userAccount: {
        customFieldsIdToLabelMap,
        customUtmsWhitelist,
      },
      funnels,
    },
    filterStore: {
      filtersData,
    },
    attributionStore: {
      conversionIndicator,
      previousTimeframe: isCompareToPreviousEnabled,
      setPreviousCheckbox,
      metricsOptions,
      filters,
      formattedTimeParams: timeFrame,
      attributionModel,
      originalFunnelFromIndicator,
      setFilters,
      autoFilter,
      isAccountMode,
      isCalculateAdvancedMetrics,
    },
  }) => ({
    impactBySegment,
    impactBySegmentSavedConfig,
    segmentsAnalysis,
    segmentsAnalysisConfig,
    trendBySegments,
    customFieldsIdToLabelMap,
    customUtmsWhitelist,
    conversionIndicator,
    isCompareToPreviousEnabled,
    setPreviousCheckbox,
    metricsOptions,
    filters,
    timeFrame,
    attributionModel,
    updateConfigPerWidget,
    region,
    originalFunnelFromIndicator,
    setFilters,
    accountOptimizationMetrics,
    funnels,
    filtersData,
    getWidgetRequestId,
    autoFilter,
    isAccountMode,
    isCalculateAdvancedMetrics,
    upliftBySegments,
  })),
  observer
);

class Segments extends Component {
  style = style;

  static widgetIds = {
    impactBySegment: 'analyze-segments-impact-by-segment',
    segmentsAnalysis: 'analyze-segments-impact-table',
  };

  constructor(props) {
    super(props);
    this.currentTabName = 'segments';
    let selectedSegments = props.segmentsAnalysisConfig?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.[0].segmentsAnalysisParams || {};
    if (isEmpty(selectedSegments)) {
      const segmentsOptions = getSegmentsOptionsFields({
        customFieldsIdToLabelMap: props.customFieldsIdToLabelMap,
        customUtmsWhitelist: props.customUtmsWhitelist,
      });
      const savedSegments = getSavedSelectedSegments(segmentsOptions);
      selectedSegments = parseSegmentsForRequest(savedSegments);
    }

    const isFirstSegmentIsTime = segmentsTimeValues.includes(selectedSegments.firstSegment);
    this.state = {
      selectedSegmentsParams: selectedSegments,
      selectedSegmentImpactBySegment: selectedSegments.firstSegment,
      impactBySegmentFrequency: props.impactBySegmentSavedConfig?.[this.currentTabName]?.impactBySegmentParams?.segmentFrequency || FREQUENCY_VALUES.MONTH,
      hideNoValuesRows: props.impactBySegmentConfig?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.[0].hideNoValuesRows || false,
      shouldSplitRequestByWidget: !!selectedSegments.secondSegment || isFirstSegmentIsTime,
      drilldownMetric: null,
      shouldUseRelativeTimeframe: props.impactBySegmentSavedConfig?.[this.currentTabName]?.impactBySegmentParams?.shouldUseRelativeTimeframe || false,
      upliftColumnsKeys: [],
      upliftRequestId: null,
      pageWidgetsStatus: {
        [Segments.widgetIds.impactBySegment]: {
          widgetType: widgetTypes.impactBySegment,
          isLoaded: false,
        },
        [Segments.widgetIds.segmentsAnalysis]: {
          widgetType: widgetTypes.segmentsAnalysis,
          isLoaded: false,
        },
      },
    };
  }

  componentDidMount() {
    const {
      conversionIndicator,
      location,
      impactBySegment,
      trendBySegments,
      accountOptimizationMetrics,
      originalFunnelFromIndicator,
      flags,
      isCompareToPreviousEnabled,
      isCalculateAdvancedMetrics,
    } = this.props;

    let segmentsForRequest = this.state.selectedSegmentsParams;
    if (location?.state) {
      segmentsForRequest = location.state.segments;
      const isFirstSegmentIsTime = segmentsTimeValues.includes(segmentsForRequest.firstSegment);
      this.setState({
        selectedSegmentsParams: segmentsForRequest,
        selectedSegmentImpactBySegment: segmentsForRequest.firstSegment,
        shouldSplitRequestByWidget: !!segmentsForRequest.secondSegment || isFirstSegmentIsTime,
      });
    }

    if (isFlagActive({ flag: flags.segmentAttributionTableWidget })) {
      getWidgetsDataV2({
        widget: widgetTypes.segmentsAnalysis,
        widgetConfig: {
          segmentsAnalysisParams: segmentsForRequest,
          conversionIndicator: originalFunnelFromIndicator,
        },
        isPreviousTimeFrame: isCompareToPreviousEnabled,
      });
      this.setState({ upliftColumnsKeys: [], upliftRequestId: null });
    }

    if (isFlagActive({ flag: flags.segmentsImpactBySegmentWidget }) || isFlagActive({ flag: flags.segmentAttributionTableWidget })) {
      if (!trendBySegments?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.isLoaded && isCalculateAdvancedMetrics) {
        getWidgetsData({
          widgets: [widgetTypes.trendBySegments],
          configPerWidget: {
            [widgetTypes.trendBySegments]: [{
              trendBySegmentsParams: segmentsForRequest,
              conversionIndicator: originalFunnelFromIndicator,
            }],
          },
          isPreviousTimeFrame: false,
        });
      }
      if (!accountOptimizationMetrics?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.isLoaded) {
        getWidgetsData({
          widgets: [widgetTypes.accountOptimizationMetrics],
          isPreviousTimeFrame: false,
        });
      }
    }

    if (isFlagActive({ flag: flags.segmentsImpactBySegmentWidget })) {
      if (!trendBySegments?.[`chart${analyzeWidgetsResultKeyNames.currentTimeFrame}`]?.isLoaded && this.state.shouldSplitRequestByWidget && isCalculateAdvancedMetrics) {
        getWidgetsData({
          widgets: [widgetTypes.trendBySegments],
          configPerWidget: {
            [widgetTypes.trendBySegments]: [{
              trendBySegmentsParams: { firstSegment: this.state.selectedSegmentImpactBySegment, secondSegment: null },
              conversionIndicator: originalFunnelFromIndicator,
            }],
          },
          isPreviousTimeFrame: false,
          resultKeyName: `chart${analyzeWidgetsResultKeyNames.currentTimeFrame}`,
        });
      }
      if (!impactBySegment?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.isLoaded) {
        getWidgetsDataV2({
          widget: widgetTypes.impactBySegment,
          widgetConfig: {
            impactBySegmentParams: {
              segmentFrequency: this.state.impactBySegmentFrequency,
              firstSegment: this.state.selectedSegmentImpactBySegment,
              fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: this.state.selectedSegmentImpactBySegment } }),
              shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
            },
            conversionIndicator: originalFunnelFromIndicator,
            kpiFocus: this.state.drilldownMetric || conversionIndicator,
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
          configKeyName: this.currentTabName,
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      filters,
      timeFrame,
      isCompareToPreviousEnabled,
      conversionIndicator,
      attributionModel,
      autoFilter,
      region,
      originalFunnelFromIndicator,
      flags,
      isAccountMode,
      isCalculateAdvancedMetrics,
    } = this.props;

    const didAccountModeChanged = isAccountMode !== prevProps.isAccountMode;
    const didFiltersChanged = isFiltersChanged({ filters, prevFilters: prevProps.filters });
    const didTimeFrameChanged = isTimeframeChanged({ timeFrame, prevTimeFrame: prevProps.timeFrame });
    const didCompareToPreviousChanged = isCompareToPreviousEnabled && !prevProps.isCompareToPreviousEnabled;
    const didConversionIndicatorChanged = conversionIndicator !== prevProps.conversionIndicator;
    const didAttributionModelChanged = attributionModel !== prevProps.attributionModel;
    const didPreDefinedFiltersChanged = autoFilter !== prevProps.autoFilter;
    const didRegionChanged = region !== prevProps.region;
    const didCalculateAdvancedMetricsChanged = isCalculateAdvancedMetrics !== prevProps.isCalculateAdvancedMetrics;

    if (didCalculateAdvancedMetricsChanged && isCalculateAdvancedMetrics) {
      getWidgetsData({
        widgets: [widgetTypes.trendBySegments],
        configPerWidget: {
          [widgetTypes.trendBySegments]: [{
            trendBySegmentsParams: this.state.selectedSegmentsParams,
            conversionIndicator: originalFunnelFromIndicator,
          }],
        },
        isPreviousTimeFrame: false,
      });

      if (this.state.shouldSplitRequestByWidget) {
        getWidgetsData({
          widgets: [widgetTypes.trendBySegments],
          configPerWidget: {
            [widgetTypes.trendBySegments]: [{
              trendBySegmentsParams: { firstSegment: this.state.selectedSegmentImpactBySegment, secondSegment: null },
              conversionIndicator: originalFunnelFromIndicator,
            }],
          },
          isPreviousTimeFrame: false,
          resultKeyName: `chart${analyzeWidgetsResultKeyNames.currentTimeFrame}`,
        });
      }
    }

    if (didAccountModeChanged || didFiltersChanged || didTimeFrameChanged || didAttributionModelChanged || didPreDefinedFiltersChanged || didRegionChanged) {
      if (isFlagActive({ flag: flags.segmentsImpactBySegmentWidget }) || isFlagActive({ flag: flags.segmentAttributionTableWidget })) {
        getWidgetsData({
          widgets: [widgetTypes.accountOptimizationMetrics],
        });
      }
    }

    if (didAccountModeChanged || didFiltersChanged || didTimeFrameChanged || didConversionIndicatorChanged || didAttributionModelChanged || didPreDefinedFiltersChanged || didRegionChanged) {
      if (isFlagActive({ flag: flags.segmentsImpactBySegmentWidget })) {
        let drilldownMetric = this.state.drilldownMetric;
        if (didConversionIndicatorChanged) {
          drilldownMetric = conversionIndicator;
          this.setState({ drilldownMetric });
        }
        getWidgetsDataV2({
          widget: widgetTypes.impactBySegment,
          widgetConfig: {
            impactBySegmentParams: {
              segmentFrequency: this.state.impactBySegmentFrequency,
              firstSegment: this.state.selectedSegmentImpactBySegment,
              fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: this.state.selectedSegmentImpactBySegment } }),
              shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
            },
            conversionIndicator: originalFunnelFromIndicator,
            kpiFocus: drilldownMetric || conversionIndicator,
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
          configKeyName: this.currentTabName,
        });
        if (this.state.shouldSplitRequestByWidget) {
          getWidgetsData({
            widgets: [widgetTypes.trendBySegments],
            configPerWidget: {
              [widgetTypes.trendBySegments]: [{
                trendBySegmentsParams: { firstSegment: this.state.selectedSegmentImpactBySegment, secondSegment: null },
                conversionIndicator: originalFunnelFromIndicator,
              }],
            },
            isPreviousTimeFrame: false,
            resultKeyName: `chart${analyzeWidgetsResultKeyNames.currentTimeFrame}`,
          });
        }
      }

      if (isFlagActive({ flag: flags.segmentAttributionTableWidget })) {
        getWidgetsDataV2({
          widget: widgetTypes.segmentsAnalysis,
          widgetConfig: {
            segmentsAnalysisParams: this.state.selectedSegmentsParams,
            conversionIndicator: originalFunnelFromIndicator,
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });
        this.setState({ upliftColumnsKeys: [], upliftRequestId: null });
      }

      if (isFlagActive({ flag: flags.segmentsImpactBySegmentWidget }) || isFlagActive({ flag: flags.segmentAttributionTableWidget })) {
        getWidgetsData({
          widgets: [widgetTypes.trendBySegments],
          configPerWidget: {
            [widgetTypes.trendBySegments]: [{
              trendBySegmentsParams: this.state.selectedSegmentsParams,
              conversionIndicator: originalFunnelFromIndicator,
            }],
          },
          isPreviousTimeFrame: false,
        });
      }
    }

    if (didCompareToPreviousChanged) {
      if (isFlagActive({ flag: flags.segmentsImpactBySegmentWidget })) {
        getWidgetsDataFromStoreV2({
          widget: widgetTypes.impactBySegment,
          widgetConfig: {
            impactBySegmentParams: {
              segmentFrequency: this.state.impactBySegmentFrequency,
              firstSegment: this.state.selectedSegmentImpactBySegment,
              fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: this.state.selectedSegmentImpactBySegment } }),
              shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
            },
            conversionIndicator: originalFunnelFromIndicator,
            kpiFocus: this.state.drilldownMetric || conversionIndicator,
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
          configKeyName: this.currentTabName,
        });
        if (this.state.shouldSplitRequestByWidget) {
          getWidgetsDataFromStore({
            widgets: [widgetTypes.trendBySegments],
            configPerWidget: {
              [widgetTypes.trendBySegments]: [{
                trendBySegmentsParams: { firstSegment: this.state.selectedSegmentImpactBySegment, secondSegment: null },
                conversionIndicator: originalFunnelFromIndicator,
              }],
            },
            isPreviousTimeFrame: false,
            resultKeyName: `chart${analyzeWidgetsResultKeyNames.currentTimeFrame}`,
          });
        }
      }

      if (isFlagActive({ flag: flags.segmentAttributionTableWidget })) {
        getWidgetsDataFromStoreV2({
          widget: widgetTypes.segmentsAnalysis,
          widgetConfig: {
            segmentsAnalysisParams: this.state.selectedSegmentsParams,
            conversionIndicator: originalFunnelFromIndicator,
          },
          isPreviousTimeFrame: true,
        });
        this.setState({ upliftColumnsKeys: [], upliftRequestId: null });
      }

      if (isFlagActive({ flag: flags.segmentsImpactBySegmentWidget }) || isFlagActive({ flag: flags.segmentAttributionTableWidget })) {
        getWidgetsDataFromStore({
          widgets: [widgetTypes.trendBySegments],
          configPerWidget: {
            [widgetTypes.trendBySegments]: [{
              trendBySegmentsParams: this.state.selectedSegmentsParams,
              conversionIndicator: originalFunnelFromIndicator,
            }],
          },
          isPreviousTimeFrame: false,
        });
      }
    }
  }

  updateCrmParam = ({ key, value }) => {
    const { originalFunnelFromIndicator, conversionIndicator } = this.props;
    switch (key) {
      case 'groupBy':
        this.updateSegmentsAnalysisParam({ firstSegment: value, secondSegment: null });
        break;
      case 'frequency':
        this.setState({ impactBySegmentFrequency: value });
        getWidgetsDataV2({
          widget: widgetTypes.impactBySegment,
          widgetConfig: {
            impactBySegmentParams: {
              segmentFrequency: value,
              firstSegment: this.state.selectedSegmentImpactBySegment,
              fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: this.state.selectedSegmentImpactBySegment } }),
              shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
            },
            conversionIndicator: originalFunnelFromIndicator,
            kpiFocus: this.state.drilldownMetric || conversionIndicator,
          },
          isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
          configKeyName: this.currentTabName,
        });
        break;
      default:
        break;
    }
  };

  updateHideNoValuesRows = () => {
    const { hideNoValuesRows } = this.state;
    this.setState({ hideNoValuesRows: !hideNoValuesRows });
    this.props.updateConfigPerWidget({ widget: widgetTypes.impactBySegment, configKeyName: 'hideNoValuesRows', configValue: !hideNoValuesRows });
  };

  updateSegmentsAnalysisParam = (newSelectedSegmentsParams) => {
    const {
      originalFunnelFromIndicator, conversionIndicator, flags, isCalculateAdvancedMetrics,
    } = this.props;
    const { selectedSegmentImpactBySegment } = this.state;
    const isFirstSegmentIsTime = segmentsTimeValues.includes(newSelectedSegmentsParams.firstSegment);
    const updatedSelectedSegmentImpactBySegment = isFirstSegmentIsTime ? selectedSegmentImpactBySegment : newSelectedSegmentsParams.firstSegment;
    const shouldRequestImpactBySegment = selectedSegmentImpactBySegment !== updatedSelectedSegmentImpactBySegment;

    this.setState({
      selectedSegmentsParams: newSelectedSegmentsParams,
      shouldSplitRequestByWidget: !!newSelectedSegmentsParams.secondSegment || isFirstSegmentIsTime,
      selectedSegmentImpactBySegment: updatedSelectedSegmentImpactBySegment,
      upliftColumnsKeys: [],
      upliftRequestId: null,
    });

    getWidgetsDataV2({
      widget: widgetTypes.segmentsAnalysis,
      widgetConfig: {
        segmentsAnalysisParams: newSelectedSegmentsParams,
        conversionIndicator: originalFunnelFromIndicator,
      },
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
    });

    if (isCalculateAdvancedMetrics) {
      getWidgetsData({
        widgets: [widgetTypes.trendBySegments],
        configPerWidget: {
          [widgetTypes.trendBySegments]: [{
            trendBySegmentsParams: newSelectedSegmentsParams,
            conversionIndicator: originalFunnelFromIndicator,
          }],
        },
        isPreviousTimeFrame: false,
      });
    }

    if (isFlagActive({ flag: flags.segmentsImpactBySegmentWidget })) {
      if (shouldRequestImpactBySegment) {
        getWidgetsDataV2({
          widget: widgetTypes.impactBySegment,
          widgetConfig: {
            impactBySegmentParams: {
              segmentFrequency: this.state.impactBySegmentFrequency,
              firstSegment: updatedSelectedSegmentImpactBySegment,
              fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: updatedSelectedSegmentImpactBySegment } }),
              shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
            },
            conversionIndicator: originalFunnelFromIndicator,
            kpiFocus: this.state.drilldownMetric || conversionIndicator,
          },
          isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
          configKeyName: this.currentTabName,
        });
      }

      if ((!!newSelectedSegmentsParams.secondSegment || isFirstSegmentIsTime) && isCalculateAdvancedMetrics) {
        getWidgetsData({
          widgets: [widgetTypes.trendBySegments],
          configPerWidget: {
            [widgetTypes.trendBySegments]: [{
              trendBySegmentsParams: { firstSegment: updatedSelectedSegmentImpactBySegment, secondSegment: null },
              conversionIndicator: originalFunnelFromIndicator,
            }],
          },
          isPreviousTimeFrame: false,
          resultKeyName: `chart${analyzeWidgetsResultKeyNames.currentTimeFrame}`,
        });
      }
    }
  };

  updateDrilldownMetric({ metric }) {
    this.setState({ drilldownMetric: metric?.value });

    const {
      originalFunnelFromIndicator, conversionIndicator,
    } = this.props;
    getWidgetsDataV2({
      widget: widgetTypes.impactBySegment,
      widgetConfig: {
        impactBySegmentParams: {
          segmentFrequency: this.state.impactBySegmentFrequency,
          firstSegment: this.state.selectedSegmentImpactBySegment,
          fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: this.state.selectedSegmentImpactBySegment } }),
          shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
        },
        conversionIndicator: originalFunnelFromIndicator,
        kpiFocus: metric?.value || conversionIndicator,
      },
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
      configKeyName: this.currentTabName,
    });
  }

  updateFilters() {
    const newFilters = convertShowOnlyFilters({
      filters: [...this.props.filters],
      userFunnels: this.props.funnels,
      conversionIndicator: this.props.conversionIndicator,
      filtersData: this.props.filtersData,
    });
    this.props.setFilters(newFilters, false);
  }

  updateShouldUseRelativeTimeframe() {
    const { originalFunnelFromIndicator, conversionIndicator } = this.props;
    this.setState((prevState) => ({ shouldUseRelativeTimeframe: !prevState.shouldUseRelativeTimeframe }), () => {
      getWidgetsDataV2({
        widget: widgetTypes.impactBySegment,
        widgetConfig: {
          impactBySegmentParams: {
            segmentFrequency: this.state.impactBySegmentFrequency,
            firstSegment: this.state.selectedSegmentImpactBySegment,
            fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: this.state.selectedSegmentImpactBySegment } }),
            shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
          },
          conversionIndicator: originalFunnelFromIndicator,
          kpiFocus: this.state.drilldownMetric || conversionIndicator,
        },
        isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
        configKeyName: this.currentTabName,
      });
    });
  }

  updatedUpliftColumnsKeys({ columnsKeys, upliftRequestId }) {
    this.setState({ upliftColumnsKeys: columnsKeys, upliftRequestId });

    const upliftBySegmentsData = this.props.upliftBySegments?.[upliftRequestId]?.result?.data;
    const shouldRequestUpliftBySegments = !upliftBySegmentsData && !columnsKeys.every((key) => upliftBySegmentsData?.[key]);
    if (shouldRequestUpliftBySegments) {
      getWidgetsDataV2({
        widget: widgetTypes.upliftBySegments,
        widgetConfig: {
          upliftBySegmentsParams: {
            selectedMetrics: columnsKeys,
            firstSegment: this.state.selectedSegmentsParams.firstSegment,
            secondSegment: this.state.selectedSegmentsParams.secondSegment,
          },
        },
        parentRequestId: upliftRequestId,
      });
    }
  }

  onWidgetLoaded({ widgetId, widgetType }) {
    const pageWidgetsStatus = this.state.pageWidgetsStatus;
    if (!pageWidgetsStatus[widgetId]) {
      return;
    }

    let isPageLoaded = true;
    for (const [pageWidgetId, pageWidgetData] of Object.entries(pageWidgetsStatus)) {
      if (pageWidgetId === widgetId) {
        continue;
      }

      if (interactionsStore.isWidgetSupported({ widgetType: pageWidgetData.widgetType }) && !pageWidgetData.isLoaded) {
        isPageLoaded = false;
      }
    }

    if (isPageLoaded) {
      interactionsStore.interactionTracker.pageLoaded();
    }

    this.setState((prevState) => ({
      pageWidgetsStatus: {
        ...prevState.pageWidgetsStatus,
        [widgetId]: {
          widgetType,
          isLoaded: true,
        },
      },
    }));
  }

  onWidgetLoading({ widgetId, widgetType }) {
    if (this.state.pageWidgetsStatus[widgetId] && !this.state.pageWidgetsStatus[widgetId].isLoaded) {
      return;
    }

    this.setState((prevState) => ({
      pageWidgetsStatus: {
        ...prevState.pageWidgetsStatus,
        [widgetId]: {
          widgetType,
          isLoaded: false,
        },
      },
    }));
  }

  render() {
    const {
      impactBySegmentFrequency,
      selectedSegmentsParams,
      shouldSplitRequestByWidget,
      shouldUseRelativeTimeframe,
      selectedSegmentImpactBySegment,
    } = this.state;

    const {
      impactBySegment,
      segmentsAnalysis,
      metricsOptions,
      flags,
      customFieldsIdToLabelMap,
      customUtmsWhitelist,
      filters,
      trendBySegments,
      originalFunnelFromIndicator,
      conversionIndicator,
      accountOptimizationMetrics,
      getWidgetRequestId,
      isCompareToPreviousEnabled,
      isCalculateAdvancedMetrics,
      upliftBySegments,
    } = this.props;

    const segmentsAnalysisFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        segmentsAnalysisParams: selectedSegmentsParams,
        conversionIndicator: originalFunnelFromIndicator,
      },
      isPreviousTimeFrame: isCompareToPreviousEnabled,
    });

    const segmentsAnalysisRequestId = getWidgetRequestId({ widget: widgetTypes.segmentsAnalysis, widgetConfig: segmentsAnalysisFullWidgetConfig });

    const impactBySegmentFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        impactBySegmentParams: {
          segmentFrequency: impactBySegmentFrequency,
          firstSegment: selectedSegmentImpactBySegment,
          fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: selectedSegmentImpactBySegment } }),
          shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
        },
        kpiFocus: this.state.drilldownMetric || conversionIndicator,
        conversionIndicator: originalFunnelFromIndicator,
      },
      isPreviousTimeFrame: isCompareToPreviousEnabled,
    });
    const impactBySegmentRequestId = getWidgetRequestId({ widget: widgetTypes.impactBySegment, widgetConfig: impactBySegmentFullWidgetConfig });

    const impactBySegmentData = impactBySegment?.[impactBySegmentRequestId]?.result?.impactData;
    const segmentAnalysisData = segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.dataByFunnel ?? {};

    const accountOptimizationMetricsData = accountOptimizationMetrics?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.data;
    const metadataBySegment = impactBySegment?.[impactBySegmentRequestId]?.result?.metadataBySegment;
    const filteredImpactBySegment = getFilteredImpactBySegmentRecords({
      data: impactBySegmentData, filters, segment: selectedSegmentsParams?.firstSegment, metadataBySegment,
    });

    const segmentsAnalysisTableData = {};
    for (const [stageName, stageData] of Object.entries(segmentAnalysisData)) {
      segmentsAnalysisTableData[stageName] = getFilteredSegmentAnalysisRecords({
        data: stageData,
        filters,
        segments: selectedSegmentsParams,
      });
    }

    const mergedFunnelsSegmentsAnalysisTableData = getFilteredSegmentAnalysisRecords({
      data: segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.mergedFunnelsTableData ?? [],
      filters,
      segments: selectedSegmentsParams,
    });

    const segmentsOptionsFields = getSegmentsOptionsFields({ customFieldsIdToLabelMap, customUtmsWhitelist });
    const segmentsOptionsFieldsWithTime = [...segmentsOptionsFields, ...segmentsTimeOptions];

    const trendBySegmentsData = trendBySegments?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.data;
    const trendBySegmentsTotals = trendBySegments?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.data;
    const chartDataTrendKey = shouldSplitRequestByWidget ? `chart${analyzeWidgetsResultKeyNames.currentTimeFrame}` : analyzeWidgetsResultKeyNames.currentTimeFrame;
    const trendBySegmentsChartData = trendBySegments?.[chartDataTrendKey]?.data?.[originalFunnelFromIndicator]?.segments;
    const stagesAmount = segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.countPerStage ?? {};

    const upliftBySegmentsFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        upliftBySegmentsParams: {
          selectedMetrics: this.state.upliftColumnsKeys,
          firstSegment: selectedSegmentsParams.firstSegment,
          secondSegment: selectedSegmentsParams.secondSegment,
        },
      },
    });
    const upliftBySegmentsRequestId = this.state.upliftRequestId || getWidgetRequestId({ widget: widgetTypes.upliftBySegments, widgetConfig: upliftBySegmentsFullWidgetConfig });
    const upliftBySegmentsData = upliftBySegments?.[upliftBySegmentsRequestId]?.result?.data;

    const isSegmentAnalysisLoaded = segmentsAnalysis?.[segmentsAnalysisRequestId]?.status === 'finished';
    const isSegmentAnalysisFailedToLoad = segmentsAnalysis?.[segmentsAnalysisRequestId]?.status === 'failed';
    const isImpactBySegmentLoaded = impactBySegment?.[impactBySegmentRequestId]?.status === 'finished';
    const isImpactBySegmentFailedToLoad = impactBySegment?.[impactBySegmentRequestId]?.status === 'failed';
    const isLoadedUpliftData = upliftBySegments?.[upliftBySegmentsRequestId]?.status === 'finished';

    return (
      <>
        <BreadcrumbsTitle breadcrumbs={[pageLabels.analyze, pageLabels.segments]} />

        <div className={this.classes.widgetSpace}>
          <FeatureFlagsWithBlur flag={flags.segmentsImpactBySegmentWidget} name={flagsNames.segmentsImpactBySegmentWidget}>
            <ImpactBySegment
              widgetId={Segments.widgetIds.impactBySegment}
              isLoaded={isImpactBySegmentLoaded}
              isImpactBySegmentFailedToLoad={isImpactBySegmentFailedToLoad}
              metricsOptions={metricsOptions}
              showSegmentDropdown
              showNoValueCheckbox
              segmentsOptionsFields={segmentsOptionsFieldsWithTime}
              updateCrmParam={({ key, value }) => this.updateCrmParam({ key, value })}
              segmentData={filteredImpactBySegment ?? []}
              segmentPrevData={[]}
              totalBySegment={impactBySegment?.[impactBySegmentRequestId]?.result?.totalBySegment}
              filters={filters}
              totalByFrequency={impactBySegment?.[impactBySegmentRequestId]?.result?.totalByFrequency}
              metricType={impactBySegment?.[impactBySegmentRequestId]?.result?.segmentKey ?? 'attributed'}
              firstSegment={selectedSegmentImpactBySegment}
              conversionIndicator={originalFunnelFromIndicator}
              currentFrequency={impactBySegmentFrequency}
              onChangeFrequency={({ value }) => this.updateCrmParam({ key: 'frequency', value })}
              relatedEntitiesIndication={impactBySegment?.[impactBySegmentRequestId]?.result?.shouldUseRelatedEntities}
              trendData={trendBySegmentsChartData}
              isLoadedTrendData={isCalculateAdvancedMetrics ? trendBySegments?.[chartDataTrendKey]?.isLoaded : false}
              onChangeHideNoValuesRows={this.updateHideNoValuesRows}
              hideNoValuesRows={this.state.hideNoValuesRows}
              kpiFocus={this.state.drilldownMetric || conversionIndicator}
              updateFilters={() => this.updateFilters()}
              dataTotals={accountOptimizationMetricsData ?? {}}
              innerPageNavigation={({ filters: filtersForNavigate, isNewFiltersUIOnly }) => navigateBetweenAnalyzeTabs({
                filters: filtersForNavigate, tabName: 'segments', withAnimation: false, isNewFiltersUIOnly,
              })}
              timeframe={this.props.timeFrame}
              attributionModel={this.props.attributionModel}
              updateDrilldownMetric={({ metric }) => this.updateDrilldownMetric({ metric })}
              growthByFrequency={impactBySegment?.[impactBySegmentRequestId]?.result?.growthByFrequency}
              growthBySegment={impactBySegment?.[impactBySegmentRequestId]?.result?.growthBySegment}
              growthImpactData={impactBySegment?.[impactBySegmentRequestId]?.result?.growthImpactData}
              isCompareToPreviousEnabled={isCompareToPreviousEnabled}
              isCalculateAdvancedMetrics={isCalculateAdvancedMetrics}
              onChangeCheckedSegments={({ segments }) => this.setState({ checkedSegments: segments })}
              onChangeUncheckedSegments={({ segments }) => this.setState({ uncheckedSegments: segments })}
              checkedSegments={this.state.checkedSegments || []}
              uncheckedSegments={this.state.uncheckedSegments || []}
              shouldUseRelativeTimeframe={shouldUseRelativeTimeframe}
              updateShouldUseRelativeTimeframe={() => this.updateShouldUseRelativeTimeframe()}
              onWidgetLoaded={() => this.onWidgetLoaded({ widgetId: Segments.widgetIds.impactBySegment, widgetType: widgetTypes.impactBySegment })}
              onWidgetLoading={() => this.onWidgetLoading({ widgetId: Segments.widgetIds.impactBySegment, widgetType: widgetTypes.impactBySegment })}
            />
          </FeatureFlagsWithBlur>
        </div>
        <FeatureFlagsWithBlur flag={flags.segmentAttributionTableWidget} name={flagsNames.segmentAttributionTableWidget}>
          <div id="attribution-table">
            <AttributionTable
              widgetId={Segments.widgetIds.segmentsAnalysis}
              onChangeHideNoValuesRows={this.updateHideNoValuesRows}
              hideNoValuesRows={this.state.hideNoValuesRows}
              isShowHideNoValue
              isLoaded={isSegmentAnalysisLoaded}
              data={segmentsAnalysisTableData ?? {}}
              mergedFunnelsTableData={mergedFunnelsSegmentsAnalysisTableData}
              dataTotals={accountOptimizationMetricsData ?? {}}
              trendData={trendBySegmentsData}
              trendDataTotals={trendBySegmentsTotals}
              isLoadedTrendData={isCalculateAdvancedMetrics ? trendBySegments?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.isLoaded : false}
              prevData={{}}
              segmentsOptionsFields={segmentsOptionsFieldsWithTime}
              key={originalFunnelFromIndicator}
              defaultStageKey={originalFunnelFromIndicator}
              dataNickname="Segment Analysis"
              type={ATTRIBUTION_TYPES.OVERVIEW}
              title="Segment Analysis"
              updateSegmentsAnalysisParam={this.updateSegmentsAnalysisParam}
              segmentsAnalysisParams={selectedSegmentsParams}
              includeAttributionStoreFilters
              funnels={this.props.funnels}
              filters={filters}
              updateFilters={() => this.updateFilters()}
              innerPageNavigation={({ filters: filtersForNavigate, isNewFiltersUIOnly }) => navigateBetweenAnalyzeTabs({
                filters: filtersForNavigate,
                tabName: 'segments',
                anchor: 'attribution-table',
                withAnimation: false,
                isNewFiltersUIOnly,
              })}
              isShowStageSelector={false}
              timeframe={this.props.timeFrame}
              attributionModel={this.props.attributionModel}
              stagesAmount={stagesAmount}
              isSegmentAnalysisFailedToLoad={isSegmentAnalysisFailedToLoad}
              isAttribution={segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.isAttribution}
              isCompareToPreviousEnabled={isCompareToPreviousEnabled}
              isCalculateAdvancedMetrics={isCalculateAdvancedMetrics}
              upliftColumnsKeys={this.state.upliftColumnsKeys}
              updatedUpliftColumnsKeys={({ columnsKeys }) => this.updatedUpliftColumnsKeys({ columnsKeys, upliftRequestId: upliftBySegmentsRequestId })}
              upliftData={upliftBySegmentsData}
              isLoadedUpliftData={isLoadedUpliftData}
              onWidgetLoaded={() => this.onWidgetLoaded({ widgetId: Segments.widgetIds.segmentsAnalysis, widgetType: widgetTypes.segmentsAnalysis })}
              onWidgetLoading={() => this.onWidgetLoading({ widgetId: Segments.widgetIds.segmentsAnalysis, widgetType: widgetTypes.segmentsAnalysis })}
            />
          </div>
        </FeatureFlagsWithBlur>
      </>
    );
  }
}

export default withLDConsumer()(enhance(Segments));
