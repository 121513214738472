import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import servicesStore from 'stores/servicesStore';
import Tooltip from 'components/controls/Tooltip';

import { limitedAccessTooltipMessages, limitedAccessTypes } from 'components/common/enums';
import { roles, rolesOrderPriority } from 'enums';

export default function LimitedAccessComponent({
  children,
  tooltip = {},
  limitType = limitedAccessTypes.disabled,
  maxRequiredRole = roles.user,
}) {
  const currentUserRole = servicesStore.authService.getProfileSync()?.app_metadata?.roleName || roles.viewer;
  const maxRequiredRolePriority = rolesOrderPriority[maxRequiredRole];
  const currentUserRolePriority = rolesOrderPriority[currentUserRole];
  const isUserAllowed = maxRequiredRolePriority >= currentUserRolePriority;

  if (!isUserAllowed) {
    if (limitType === limitedAccessTypes.hidden) {
      return null;
    }

    if (limitType === limitedAccessTypes.disabled) {
      const Component = React.cloneElement(React.Children.only(children), {
        disabled: true,
      });

      const tooltipProps = {
        id: tooltip.id || uuidv4(),
        isShow: true,
        place: 'bottom',
        message: limitedAccessTooltipMessages,
        ...tooltip,
      };

      if (tooltipProps.isShow) {
        return (
          <Tooltip
            tip={tooltipProps.message}
            place={tooltipProps.place}
            id={tooltipProps.id}
          >
            {Component}
          </Tooltip>
        );
      }
      return Component;
    }
  }

  return children;
}
