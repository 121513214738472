export const nameAlreadyInUseErrorMessage = 'This name is already in use, please select another name';

export const addNewOptions = {
  costPlaceholder: 'costPlaceholder',
  newCampaign: 'newCampaign',
};

export const operators = {
  notEquals: 'not_equals',
  equals: 'equals',
  contains: 'contains',
  notContains: 'not_contains',
};

export const operatorsOptions = [
  { value: operators.contains, label: 'Contains' },
  { value: operators.equals, label: 'Equals' },
  { value: operators.notContains, label: 'Doesn\'t contain' },
  { value: operators.notEquals, label: 'Not equals' },
];

export const costPlaceholdersTooltipText = 'Use cost placeholders to add an expected spend to a tag. For example, if you tagged campaigns that contain "US", and would like to add an expected cost to US LinkedIn campaigns, add a cost placeholder with the name "US" to the LinkedIn channel.';
