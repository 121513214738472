import { inject, observer } from 'mobx-react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import interactionsStore from 'stores/interactionsStore';
import AttributionTable from 'components/pages/analyze/AttribuitonTable/AttributionTable';
import Component from 'components/Component';
import FeatureFlagsWithBlur from 'components/common/FeatureFlagsWithBlur';
import ImpactBySegment from 'components/pages/analyze/SegmentsTab/ImpactBySegment';
import BreadcrumbsTitle from 'components/common/BreadcrumbsTitle';

import JourneysChannels from 'components/pages/analyze/ChannelsTab/JourneysChannels';
import { ATTRIBUTION_TYPES, compose } from 'components/utils/utils';
import { FREQUENCY_VALUES } from 'components/utils/frequency';
import { isAllWidgetsDataLoaded, isFiltersChanged, isTimeframeChanged } from 'components/pages/analyze/logic/Tabs';
import { widgetTypes, flagsNames } from 'components/pages/analyze/enums';
import { getCurrentHashAndScrollToAnchorTag, navigateBetweenAnalyzeTabs } from 'stores/analyze/logic/navigations';
import { analyzeWidgetsResultKeyNames } from 'stores/logic/enums';
import {
  getWidgetsData, getWidgetsDataFromStore, getWidgetsDataV2, getWidgetFullConfig, getWidgetsDataFromStoreV2,
} from 'components/pages/analyze/widgetsRequest';
import { navigateFromChannelsToJourneys } from 'stores/analyze/logic/channelsStore';
import { convertShowOnlyFilters, getImpactByFieldsToEnrichAccordingSegment } from 'components/pages/analyze/SegmentsTab/logic/ImpactBySegment';
import { isFlagActive } from 'components/pages/analyze/logic/FeatureFlagsWithBlur';
import { getFilteredImpactBySegmentRecords, getFilteredSegmentAnalysisRecords } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { getSegmentsOptionsFields } from 'components/pages/analyze/OverviewTab/logic';
import { segmentsTimeValues } from 'components/pages/analyze/AttribuitonTable/logic/AttributionTable';
import { segmentsTimeOptions } from 'components/pages/analyze/AttribuitonTable/enums';
import { pageLabels } from 'enums';

const enhance = compose(
  inject((stores) => {
    const {
      funnels,
      userAccount: {
        customFieldsIdToLabelMap,
        customUtmsWhitelist,
      },
      userMonthPlan: {
        region,
        accountViewRegion,
      },
    } = stores.userStore;

    const {
      attributionModel,
      formattedTimeParams: timeFrame,
      conversionIndicator,
      previousTimeframe: isCompareToPreviousEnabled,
      setPreviousCheckbox,
      isMoneyFormat,
      velocityKey,
      filters,
      autoFilter,
      setFilters,
      originalFunnelFromIndicator,
      isAccountMode,
      isCalculateAdvancedMetrics,
    } = stores.attributionStore;

    const {
      configPerTab: {
        channels: {
          isCategoryView = false,
        },
      },
    } = stores.analyzeStore;

    const {
      dataPerWidget: {
        [widgetTypes.topConversionChannelJourneys]: channelJourneys,
        [widgetTypes.topConversionContentJourneys]: contentJourneys,
        [widgetTypes.trendBySegments]: trendBySegments,
        [widgetTypes.accountOptimizationMetrics]: accountOptimizationMetrics,
      },
      updateConfigPerWidget,
    } = stores.analysisStore;

    const {
      dataPerWidget: {
        [widgetTypes.segmentsAnalysis]: segmentsAnalysis,
        [widgetTypes.impactBySegment]: impactBySegment,
        [widgetTypes.upliftBySegments]: upliftBySegments,
      },
      configPerWidget: {
        [widgetTypes.impactBySegment]: impactBySegmentSavedConfig,
      },
      getWidgetRequestId,
    } = stores.widgetsAnalysisStore;

    return {
      timeFrame,
      isCompareToPreviousEnabled,
      attributionModel,
      setPreviousCheckbox,
      isCategoryView,
      conversionIndicator,
      originalFunnelFromIndicator,
      isMoneyFormat,
      velocityKey,
      impactBySegment,
      impactBySegmentSavedConfig,
      channelJourneys,
      contentJourneys,
      funnels,
      filters,
      autoFilter,
      region,
      trendBySegments,
      accountOptimizationMetrics,
      setFilters,
      updateConfigPerWidget,
      customFieldsIdToLabelMap,
      customUtmsWhitelist,
      getWidgetRequestId,
      segmentsAnalysis,
      isAccountMode,
      accountViewRegion,
      isCalculateAdvancedMetrics,
      upliftBySegments,
    };
  }),
  observer
);

class Channels extends Component {
  static widgetIds = {
    impactBySegment: 'analyze-channels-impact-by-segment',
    segmentsAnalysis: 'analyze-channels-impact-table',
  };

  constructor(props) {
    super(props);
    this.currentTabName = 'channels';

    const firstSegment = props.isCategoryView ? 'category' : 'channel';
    this.state = {
      channelsImpactFrequency: props.impactBySegmentSavedConfig?.[this.currentTabName]?.impactBySegmentParams?.segmentFrequency || FREQUENCY_VALUES.MONTH,
      selectedSegmentsParams: { firstSegment, secondSegment: null },
      selectedSegmentImpactBySegment: firstSegment,
      trendBySegmentsKeyName: 'trendByChannels',
      accountOptimizationMetricsKeyName: 'accountOptimizationMetricsAnalyze',
      shouldSplitRequestByWidget: false,
      drilldownMetric: null,
      shouldUseRelativeTimeframe: props.impactBySegmentSavedConfig?.[this.currentTabName]?.impactBySegmentParams?.shouldUseRelativeTimeframe || false,
      checkedSegments: [],
      uncheckedSegments: [],
      isInitialLoad: null,
      upliftColumnsKeys: [],
      upliftRequestId: null,
      pageWidgetsStatus: {
        [Channels.widgetIds.impactBySegment]: {
          widgetType: widgetTypes.impactBySegment,
          isLoaded: false,
        },
        [Channels.widgetIds.segmentsAnalysis]: {
          widgetType: widgetTypes.segmentsAnalysis,
          isLoaded: false,
        },
      },
    };
  }

  componentDidMount() {
    const {
      isCompareToPreviousEnabled,
      isCategoryView,
      channelJourneys,
      contentJourneys,
      originalFunnelFromIndicator,
      trendBySegments,
      accountOptimizationMetrics,
      flags,
      location,
      isCalculateAdvancedMetrics,
    } = this.props;

    this.setState({ isInitialLoad: true });
    getCurrentHashAndScrollToAnchorTag(window.location.hash);

    const isRenderViaNavigation = location?.state?.isRenderViaNavigation && location?.action === 'PUSH';
    if (isRenderViaNavigation) {
      return;
    }

    if (isFlagActive({ flag: flags.channelImpactBySegmentWidget })) {
      if (!trendBySegments?.[`chart${this.state.trendBySegmentsKeyName}`]?.isLoaded && this.state.shouldSplitRequestByWidget && isCalculateAdvancedMetrics) {
        getWidgetsData({
          widgets: [widgetTypes.trendBySegments],
          configPerWidget: {
            [widgetTypes.trendBySegments]: [{
              trendBySegmentsParams: {
                firstSegment: this.state.selectedSegmentImpactBySegment,
                secondSegment: null,
              },
              conversionIndicator: originalFunnelFromIndicator,
            }],
          },
          isPreviousTimeFrame: false,
          resultKeyName: `chart${this.state.trendBySegmentsKeyName}`,
        });
      }
    }

    if (isFlagActive({ flag: flags.channelImpactBySegmentWidget }) || isFlagActive({ flag: flags.channelsAttributionTableWidget })) {
      if (!accountOptimizationMetrics?.[this.state.accountOptimizationMetricsKeyName]?.isLoaded) {
        getWidgetsData({
          widgets: [widgetTypes.accountOptimizationMetrics],
          isPreviousTimeFrame: false,
          resultKeyName: this.state.accountOptimizationMetricsKeyName,
        });
      }
      if (!trendBySegments?.[this.state.trendBySegmentsKeyName]?.isLoaded && isCalculateAdvancedMetrics) {
        getWidgetsData({
          widgets: [widgetTypes.trendBySegments],
          configPerWidget: {
            [widgetTypes.trendBySegments]: [{
              trendBySegmentsParams: {
                firstSegment: this.state.selectedSegmentsParams.firstSegment,
                secondSegment: null,
              },
              conversionIndicator: null,
            }],
          },
          isPreviousTimeFrame: false,
          resultKeyName: this.state.trendBySegmentsKeyName,
        });
      }
    }

    if (isFlagActive({ flag: flags.journeysChannelsWidget })) {
      if (!channelJourneys?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.isLoaded) {
        getWidgetsData({
          widgets: [widgetTypes.topConversionChannelJourneys],
          configPerWidget: {
            [widgetTypes.topConversionChannelJourneys]: [{
              isCategoryView,
            }],
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });
      }
      if (!contentJourneys?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.isLoaded) {
        getWidgetsData({
          widgets: [widgetTypes.topConversionContentJourneys],
          configPerWidget: {
            [widgetTypes.topConversionContentJourneys]: [{
              isCategoryView,
            }],
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      filters,
      timeFrame,
      attributionModel,
      isCompareToPreviousEnabled,
      conversionIndicator,
      isCategoryView,
      autoFilter,
      region,
      originalFunnelFromIndicator,
      flags,
      isAccountMode,
      isCalculateAdvancedMetrics,
    } = this.props;

    const {
      isInitialLoad,
    } = this.state;

    const didInitialLoadChanged = isInitialLoad !== prevState.isInitialLoad;
    const didAccountModeChanged = isAccountMode !== prevProps.isAccountMode;
    const didPreDefinedFiltersChanged = autoFilter !== prevProps.autoFilter;
    const didCategoryViewChanged = isCategoryView !== prevProps.isCategoryView;
    const didConversionIndicatorChanged = conversionIndicator !== prevProps.conversionIndicator;
    const didFiltersChanged = isFiltersChanged({ filters, prevFilters: prevProps.filters });
    const didTimeFrameChanged = isTimeframeChanged({ timeFrame, prevTimeFrame: prevProps.timeFrame });
    const didAttributionModelChanged = attributionModel !== prevProps.attributionModel;
    const didCompareToPreviousChanged = isCompareToPreviousEnabled && !prevProps.isCompareToPreviousEnabled;
    const didRegionChanged = region !== prevProps.region;
    const didCalculateAdvancedMetricsChanged = isCalculateAdvancedMetrics !== prevProps.isCalculateAdvancedMetrics;

    let selectedSegmentsParams = this.state.selectedSegmentsParams;
    let selectedSegmentImpactBySegment = this.state.selectedSegmentImpactBySegment;
    if (didCategoryViewChanged) {
      selectedSegmentsParams = { firstSegment: isCategoryView ? 'category' : 'channel', secondSegment: null };
      selectedSegmentImpactBySegment = selectedSegmentsParams.firstSegment;
      this.setState({ selectedSegmentsParams, selectedSegmentImpactBySegment });
    }

    if (didCalculateAdvancedMetricsChanged && isCalculateAdvancedMetrics) {
      getWidgetsData({
        widgets: [widgetTypes.trendBySegments],
        configPerWidget: {
          [widgetTypes.trendBySegments]: [{
            trendBySegmentsParams: {
              firstSegment: selectedSegmentsParams.firstSegment,
            },
            conversionIndicator: originalFunnelFromIndicator,
          }],
        },
        isPreviousTimeFrame: false,
        resultKeyName: this.state.trendBySegmentsKeyName,
      });
      if (this.state.shouldSplitRequestByWidget) {
        getWidgetsData({
          widgets: [widgetTypes.trendBySegments],
          configPerWidget: {
            [widgetTypes.trendBySegments]: [{
              trendBySegmentsParams: { firstSegment: selectedSegmentImpactBySegment, secondSegment: null },
              conversionIndicator: originalFunnelFromIndicator,
            }],
          },
          isPreviousTimeFrame: false,
          resultKeyName: `chart${this.state.trendBySegmentsKeyName}`,
        });
      }
    }

    if (didInitialLoadChanged || didAccountModeChanged || didFiltersChanged || didTimeFrameChanged || didAttributionModelChanged || didPreDefinedFiltersChanged || didCategoryViewChanged || didRegionChanged) {
      if (isFlagActive({ flag: flags.channelsAttributionTableWidget })) {
        getWidgetsDataV2({
          widget: widgetTypes.segmentsAnalysis,
          widgetConfig: {
            segmentsAnalysisParams: selectedSegmentsParams,
            conversionIndicator: null,
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });
        this.setState({ upliftColumnsKeys: [], upliftRequestId: null });
      }
    }

    if (didInitialLoadChanged || didAccountModeChanged || didFiltersChanged || didTimeFrameChanged || didAttributionModelChanged || didConversionIndicatorChanged || didPreDefinedFiltersChanged || didCategoryViewChanged) {
      if (isFlagActive({ flag: flags.channelImpactBySegmentWidget })) {
        let drilldownMetric = this.state.drilldownMetric;
        if (didConversionIndicatorChanged) {
          drilldownMetric = conversionIndicator;
          this.setState({ drilldownMetric });
        }
        getWidgetsDataV2({
          widget: widgetTypes.impactBySegment,
          widgetConfig: {
            impactBySegmentParams: {
              segmentFrequency: this.state.channelsImpactFrequency,
              firstSegment: selectedSegmentImpactBySegment,
              fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: selectedSegmentImpactBySegment } }),
              shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
            },
            conversionIndicator: originalFunnelFromIndicator,
            kpiFocus: drilldownMetric || conversionIndicator,
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
          configKeyName: this.currentTabName,
        });
        if (this.state.shouldSplitRequestByWidget && isCalculateAdvancedMetrics) {
          getWidgetsData({
            widgets: [widgetTypes.trendBySegments],
            configPerWidget: {
              [widgetTypes.trendBySegments]: [{
                trendBySegmentsParams: { firstSegment: selectedSegmentImpactBySegment, secondSegment: null },
                conversionIndicator: originalFunnelFromIndicator,
              }],
            },
            isPreviousTimeFrame: false,
            resultKeyName: `chart${this.state.trendBySegmentsKeyName}`,
          });
        }
      }
    }

    if (didAccountModeChanged || didFiltersChanged || didTimeFrameChanged || didAttributionModelChanged || didConversionIndicatorChanged || didPreDefinedFiltersChanged || didCategoryViewChanged) {
      if (isFlagActive({ flag: flags.journeysChannelsWidget })) {
        getWidgetsData({
          widgets: [widgetTypes.topConversionChannelJourneys],
          configPerWidget: {
            [widgetTypes.topConversionChannelJourneys]: [{
              isCategoryView,
            }],
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });
        getWidgetsData({
          widgets: [widgetTypes.topConversionContentJourneys],
          configPerWidget: {
            [widgetTypes.topConversionContentJourneys]: [{
              isCategoryView,
            }],
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });
      }

      if (isFlagActive({ flag: flags.channelsAttributionTableWidget }) && isCalculateAdvancedMetrics) {
        getWidgetsData({
          widgets: [widgetTypes.trendBySegments],
          configPerWidget: {
            [widgetTypes.trendBySegments]: [{
              trendBySegmentsParams: {
                firstSegment: selectedSegmentsParams.firstSegment,
              },
              conversionIndicator: originalFunnelFromIndicator,
            }],
          },
          isPreviousTimeFrame: false,
          resultKeyName: this.state.trendBySegmentsKeyName,
        });
      }
    }

    if ((didFiltersChanged || didTimeFrameChanged || didAttributionModelChanged || didConversionIndicatorChanged || didPreDefinedFiltersChanged)) {
      if (isFlagActive({ flag: flags.channelImpactBySegmentWidget })) {
        getWidgetsData({
          widgets: [widgetTypes.accountOptimizationMetrics],
          isPreviousTimeFrame: false,
          resultKeyName: this.state.accountOptimizationMetricsKeyName,
        });
      }
    }

    if (didCompareToPreviousChanged) {
      if (isFlagActive({ flag: flags.channelsAttributionTableWidget })) {
        getWidgetsDataFromStoreV2({
          widget: widgetTypes.segmentsAnalysis,
          widgetConfig: {
            segmentsAnalysisParams: selectedSegmentsParams,
            conversionIndicator: null,
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });

        this.setState({ upliftColumnsKeys: [], upliftRequestId: null });
      }

      if (isFlagActive({ flag: flags.channelImpactBySegmentWidget })) {
        getWidgetsDataFromStoreV2({
          widget: widgetTypes.impactBySegment,
          widgetConfig: {
            impactBySegmentParams: {
              segmentFrequency: this.state.channelsImpactFrequency,
              firstSegment: selectedSegmentImpactBySegment,
              fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: selectedSegmentImpactBySegment } }),
              shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
            },
            conversionIndicator: originalFunnelFromIndicator,
            kpiFocus: this.state.drilldownMetric || conversionIndicator,
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
          configKeyName: this.currentTabName,
        });
      }
      if (isFlagActive({ flag: flags.journeysChannelsWidget })) {
        getWidgetsDataFromStore({
          widgets: [widgetTypes.topConversionChannelJourneys],
          configPerWidget: {
            [widgetTypes.topConversionChannelJourneys]: [{
              isCategoryView,
            }],
          },
          isPreviousTimeFrame: true,
        });
        getWidgetsDataFromStore({
          widgets: [widgetTypes.topConversionContentJourneys],
          configPerWidget: {
            [widgetTypes.topConversionContentJourneys]: [{
              isCategoryView,
            }],
          },
          isPreviousTimeFrame: true,
        });
      }
    }
  }

  updateChannelsImpactFrequency = ({ newChannelsImpactFrequency }) => {
    const {
      originalFunnelFromIndicator, conversionIndicator,
    } = this.props;
    this.setState({ channelsImpactFrequency: newChannelsImpactFrequency });

    getWidgetsDataV2({
      widget: widgetTypes.impactBySegment,
      widgetConfig: {
        impactBySegmentParams: {
          segmentFrequency: newChannelsImpactFrequency,
          firstSegment: this.state.selectedSegmentImpactBySegment,
          fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: this.state.selectedSegmentImpactBySegment } }),
          shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
        },
        conversionIndicator: originalFunnelFromIndicator,
        kpiFocus: this.state.drilldownMetric || conversionIndicator,
      },
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
      configKeyName: this.currentTabName,
    });
  };

  updateDrilldownMetric({ metric }) {
    this.setState({ drilldownMetric: metric?.value });

    const {
      originalFunnelFromIndicator, conversionIndicator,
    } = this.props;

    getWidgetsDataV2({
      widget: widgetTypes.impactBySegment,
      widgetConfig: {
        impactBySegmentParams: {
          segmentFrequency: this.state.channelsImpactFrequency,
          firstSegment: this.state.selectedSegmentImpactBySegment,
          fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: this.state.selectedSegmentImpactBySegment } }),
          shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
        },
        conversionIndicator: originalFunnelFromIndicator,
        kpiFocus: metric?.value || conversionIndicator,
      },
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
      configKeyName: this.currentTabName,
    });
  }

  updateFilters() {
    const newFilters = convertShowOnlyFilters({
      filters: [...this.props.filters],
      userFunnels: this.props.funnels,
      conversionIndicator: this.props.conversionIndicator,
      filtersData: this.props.filtersData,
    });
    this.props.setFilters(newFilters, false);
  }

  updateSegmentsAnalysisParam(newSelectedSegmentsParams) {
    const { conversionIndicator, flags, isCalculateAdvancedMetrics } = this.props;
    const { selectedSegmentImpactBySegment } = this.state;

    const isFirstSegmentIsTime = segmentsTimeValues.includes(newSelectedSegmentsParams.firstSegment);
    const updatedSelectedSegmentImpactBySegment = isFirstSegmentIsTime ? selectedSegmentImpactBySegment : newSelectedSegmentsParams.firstSegment;
    const shouldSplitRequestByWidget = !!newSelectedSegmentsParams.secondSegment || isFirstSegmentIsTime;
    const shouldRequestImpactBySegment = selectedSegmentImpactBySegment !== updatedSelectedSegmentImpactBySegment;

    this.setState({
      selectedSegmentsParams: newSelectedSegmentsParams,
      selectedSegmentImpactBySegment: updatedSelectedSegmentImpactBySegment,
      shouldSplitRequestByWidget,
      upliftColumnsKeys: [],
      upliftRequestId: null,
    });

    if (isFlagActive({ flag: flags.channelImpactBySegmentWidget })) {
      if (shouldRequestImpactBySegment) {
        getWidgetsDataV2({
          widget: widgetTypes.impactBySegment,
          widgetConfig: {
            impactBySegmentParams: {
              segmentFrequency: this.state.channelsImpactFrequency,
              firstSegment: updatedSelectedSegmentImpactBySegment,
              fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: updatedSelectedSegmentImpactBySegment } }),
              shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
            },
            conversionIndicator,
            kpiFocus: this.state.drilldownMetric || conversionIndicator,
          },
          isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
          configKeyName: this.currentTabName,
        });
      }
    }

    getWidgetsDataV2({
      widget: widgetTypes.segmentsAnalysis,
      widgetConfig: {
        segmentsAnalysisParams: newSelectedSegmentsParams,
        conversionIndicator: null,
      },
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
    });

    if (isCalculateAdvancedMetrics) {
      getWidgetsData({
        widgets: [widgetTypes.trendBySegments],
        configPerWidget: {
          [widgetTypes.trendBySegments]: [{
            trendBySegmentsParams: newSelectedSegmentsParams,
            conversionIndicator,
          }],
        },
        isPreviousTimeFrame: false,
        resultKeyName: this.state.trendBySegmentsKeyName,
      });

      if (isFlagActive({ flag: flags.segmentsImpactBySegmentWidget })) {
        if (shouldSplitRequestByWidget) {
          getWidgetsData({
            widgets: [widgetTypes.trendBySegments],
            configPerWidget: {
              [widgetTypes.trendBySegments]: [{
                trendBySegmentsParams: { firstSegment: updatedSelectedSegmentImpactBySegment, secondSegment: null },
                conversionIndicator,
              }],
            },
            isPreviousTimeFrame: false,
            resultKeyName: `chart${this.state.trendBySegmentsKeyName}`,
          });
        }
      }
    }
  }

  updateShouldUseRelativeTimeframe() {
    const { originalFunnelFromIndicator, conversionIndicator } = this.props;
    this.setState((prevState) => ({ shouldUseRelativeTimeframe: !prevState.shouldUseRelativeTimeframe }), () => {
      getWidgetsDataV2({
        widget: widgetTypes.impactBySegment,
        widgetConfig: {
          impactBySegmentParams: {
            segmentFrequency: this.state.channelsImpactFrequency,
            firstSegment: this.state.selectedSegmentImpactBySegment,
            fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: this.state.selectedSegmentImpactBySegment } }),
            shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
          },
          conversionIndicator: originalFunnelFromIndicator,
          kpiFocus: this.state.drilldownMetric || conversionIndicator,
        },
        configKeyName: this.currentTabName,
      });
    });
  }

  updatedUpliftColumnsKeys({ columnsKeys, upliftRequestId }) {
    this.setState({ upliftColumnsKeys: columnsKeys, upliftRequestId });

    const upliftBySegmentsData = this.props.upliftBySegments?.[upliftRequestId]?.result?.data;
    const shouldRequestUpliftBySegments = !upliftBySegmentsData && !columnsKeys.every((key) => upliftBySegmentsData?.[key]);
    if (shouldRequestUpliftBySegments) {
      getWidgetsDataV2({
        widget: widgetTypes.upliftBySegments,
        widgetConfig: {
          upliftBySegmentsParams: {
            selectedMetrics: columnsKeys,
            firstSegment: this.state.selectedSegmentsParams.firstSegment,
            secondSegment: this.state.selectedSegmentsParams.secondSegment,
          },
        },
        parentRequestId: upliftRequestId,
      });
    }
  }

  onWidgetLoaded({ widgetId, widgetType }) {
    const pageWidgetsStatus = this.state.pageWidgetsStatus;
    if (!pageWidgetsStatus[widgetId]) {
      return;
    }

    let isPageLoaded = true;
    for (const [pageWidgetId, pageWidgetData] of Object.entries(pageWidgetsStatus)) {
      if (pageWidgetId === widgetId) {
        continue;
      }

      if (interactionsStore.isWidgetSupported({ widgetType: pageWidgetData.widgetType }) && !pageWidgetData.isLoaded) {
        isPageLoaded = false;
      }
    }

    if (isPageLoaded) {
      interactionsStore.interactionTracker.pageLoaded();
    }

    this.setState((prevState) => ({
      pageWidgetsStatus: {
        ...prevState.pageWidgetsStatus,
        [widgetId]: {
          widgetType,
          isLoaded: true,
        },
      },
    }));
  }

  onWidgetLoading({ widgetId, widgetType }) {
    if (this.state.pageWidgetsStatus[widgetId] && !this.state.pageWidgetsStatus[widgetId].isLoaded) {
      return;
    }

    this.setState((prevState) => ({
      pageWidgetsStatus: {
        ...prevState.pageWidgetsStatus,
        [widgetId]: {
          widgetType,
          isLoaded: false,
        },
      },
    }));
  }

  render() {
    const {
      conversionIndicator,
      isCategoryView,
      flags,
      impactBySegment,
      channelJourneys,
      contentJourneys,
      filters,
      isCompareToPreviousEnabled,
      originalFunnelFromIndicator,
      trendBySegments,
      accountOptimizationMetrics,
      customFieldsIdToLabelMap,
      customUtmsWhitelist,
      segmentsAnalysis,
      getWidgetRequestId,
      region,
      accountViewRegion,
      isAccountMode,
      isCalculateAdvancedMetrics,
      upliftBySegments,
    } = this.props;
    const {
      trendBySegmentsKeyName,
      accountOptimizationMetricsKeyName,
      selectedSegmentsParams,
      channelsImpactFrequency,
      shouldSplitRequestByWidget,
      shouldUseRelativeTimeframe,
      selectedSegmentImpactBySegment,
    } = this.state;

    const requestRegion = isAccountMode ? accountViewRegion : region;
    const segmentsAnalysisFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        segmentsAnalysisParams: selectedSegmentsParams,
        conversionIndicator: null,
      },
      isPreviousTimeFrame: isCompareToPreviousEnabled,
    });
    const segmentsAnalysisRequestId = getWidgetRequestId({ region: requestRegion, widget: widgetTypes.segmentsAnalysis, widgetConfig: segmentsAnalysisFullWidgetConfig });

    const impactBySegmentFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        impactBySegmentParams: {
          segmentFrequency: this.state.channelsImpactFrequency,
          firstSegment: selectedSegmentImpactBySegment,
          fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: selectedSegmentImpactBySegment } }),
          shouldUseRelativeTimeframe,
        },
        kpiFocus: this.state.drilldownMetric || conversionIndicator,
        conversionIndicator: originalFunnelFromIndicator,
      },
      isPreviousTimeFrame: isCompareToPreviousEnabled,
    });
    const impactBySegmentRequestId = getWidgetRequestId({ region: requestRegion, widget: widgetTypes.impactBySegment, widgetConfig: impactBySegmentFullWidgetConfig });

    const upliftBySegmentsFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        upliftBySegmentsParams: {
          selectedMetrics: this.state.upliftColumnsKeys,
          firstSegment: this.state.selectedSegmentsParams.firstSegment,
          secondSegment: this.state.selectedSegmentsParams.secondSegment,
        },
      },
    });

    const upliftBySegmentsRequestId = this.state.upliftRequestId || getWidgetRequestId({ region: requestRegion, widget: widgetTypes.upliftBySegments, widgetConfig: upliftBySegmentsFullWidgetConfig });
    const upliftBySegmentsData = upliftBySegments?.[upliftBySegmentsRequestId]?.result?.data;

    const navigateToJourneys = (funnelStage, channelKey) => navigateFromChannelsToJourneys({
      funnelStage, channelKey, isCategoryView, includeAttributionStoreFilters: true,
    });

    const impactBySegmentData = impactBySegment?.[impactBySegmentRequestId]?.result?.impactData;
    const metadataBySegment = impactBySegment?.[impactBySegmentRequestId]?.result?.metadataBySegment;
    const filteredImpactBySegment = getFilteredImpactBySegmentRecords({
      data: impactBySegmentData, filters, segment: selectedSegmentsParams.firstSegment, metadataBySegment,
    });

    const chartDataTrendKey = shouldSplitRequestByWidget ? `chart${trendBySegmentsKeyName}` : trendBySegmentsKeyName;
    const trendBySegmentsData = trendBySegments?.[chartDataTrendKey]?.data;
    const trendBySegmentsChartData = trendBySegments?.[chartDataTrendKey]?.data?.[originalFunnelFromIndicator]?.segments;
    const accountOptimizationMetricsData = accountOptimizationMetrics?.[accountOptimizationMetricsKeyName]?.data;

    const segmentsAnalysisTableData = {};
    const segmentsAnalysisData = segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.dataByFunnel ?? {};
    for (const [stageName, stageData] of Object.entries(segmentsAnalysisData)) {
      segmentsAnalysisTableData[stageName] = getFilteredSegmentAnalysisRecords({
        data: stageData,
        filters,
        segments: selectedSegmentsParams,
      });
    }

    const mergedFunnelsSegmentsAnalysisTableData = getFilteredSegmentAnalysisRecords({
      data: segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.mergedFunnelsTableData ?? [],
      filters,
      segments: selectedSegmentsParams,
    });

    const stagesAmount = segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.countPerStage ?? {};
    const segmentsOptionsFields = getSegmentsOptionsFields({ customFieldsIdToLabelMap, customUtmsWhitelist });
    const segmentsOptionsFieldsWithTime = [...segmentsOptionsFields, ...segmentsTimeOptions];

    const isSegmentAnalysisLoaded = segmentsAnalysis?.[segmentsAnalysisRequestId]?.status === 'finished';
    const isSegmentAnalysisFailedToLoad = segmentsAnalysis?.[segmentsAnalysisRequestId]?.status === 'failed';
    const isImpactBySegmentLoaded = impactBySegment?.[impactBySegmentRequestId]?.status === 'finished';
    const isImpactBySegmentFailedToLoad = impactBySegment?.[impactBySegmentRequestId]?.status === 'failed';
    const isLoadedUpliftData = upliftBySegments?.[upliftBySegmentsRequestId]?.status === 'finished';

    const impactBySegmentSegmentLabel = segmentsOptionsFieldsWithTime.find((segment) => segment.value === selectedSegmentImpactBySegment)?.label;
    const segmentAnalysisSegmentLabel = segmentsOptionsFieldsWithTime.find((segment) => segment.value === selectedSegmentsParams.firstSegment)?.label;

    return (
      <div>
        <BreadcrumbsTitle breadcrumbs={[pageLabels.analyze, pageLabels.channels]} />

        <FeatureFlagsWithBlur flag={flags.channelImpactBySegmentWidget} name={flagsNames.channelImpactBySegmentWidget}>
          <ImpactBySegment
            widgetId={Channels.widgetIds.impactBySegment}
            title={`Impact by ${impactBySegmentSegmentLabel}`}
            isLoaded={isImpactBySegmentLoaded}
            isImpactBySegmentFailedToLoad={isImpactBySegmentFailedToLoad}
            isLoadedTrendData={isCalculateAdvancedMetrics ? trendBySegments?.[chartDataTrendKey]?.isLoaded : true}
            showSegmentDropdown={false}
            showNoValueCheckbox={false}
            currentSegmentLabel={impactBySegmentSegmentLabel}
            segmentData={filteredImpactBySegment ?? []}
            segmentPrevData={[]}
            totalBySegment={impactBySegment?.[impactBySegmentRequestId]?.result?.totalBySegment}
            totalByFrequency={impactBySegment?.[impactBySegmentRequestId]?.result?.totalByFrequency}
            metricType={impactBySegment?.[impactBySegmentRequestId]?.result?.segmentKey ?? 'attributed'}
            relatedEntitiesIndication={impactBySegment?.[impactBySegmentRequestId]?.result?.shouldUseRelatedEntities}
            filters={filters}
            firstSegment={selectedSegmentImpactBySegment}
            conversionIndicator={originalFunnelFromIndicator}
            currentFrequency={channelsImpactFrequency}
            onChangeFrequency={(frequency) => this.updateChannelsImpactFrequency({ newChannelsImpactFrequency: frequency.value })}
            trendData={trendBySegmentsChartData}
            kpiFocus={this.state.drilldownMetric || conversionIndicator}
            updateFilters={() => this.updateFilters()}
            dataTotals={accountOptimizationMetricsData ?? {}}
            innerPageNavigation={({ filters: filtersForNavigate, isNewFiltersUIOnly }) => navigateBetweenAnalyzeTabs({
              filters: filtersForNavigate, tabName: 'channels', withAnimation: false, isNewFiltersUIOnly,
            })}
            timeframe={this.props.timeFrame}
            attributionModel={this.props.attributionModel}
            updateDrilldownMetric={({ metric }) => this.updateDrilldownMetric({ metric })}
            growthByFrequency={impactBySegment?.[impactBySegmentRequestId]?.result?.growthByFrequency}
            growthBySegment={impactBySegment?.[impactBySegmentRequestId]?.result?.growthBySegment}
            growthImpactData={impactBySegment?.[impactBySegmentRequestId]?.result?.growthImpactData}
            isCompareToPreviousEnabled={isCompareToPreviousEnabled}
            isCalculateAdvancedMetrics={isCalculateAdvancedMetrics}
            shouldUseRelativeTimeframe={shouldUseRelativeTimeframe}
            updateShouldUseRelativeTimeframe={() => this.updateShouldUseRelativeTimeframe()}
            onChangeCheckedSegments={({ segments }) => this.setState({ checkedSegments: segments })}
            onChangeUncheckedSegments={({ segments }) => this.setState({ uncheckedSegments: segments })}
            checkedSegments={this.state.checkedSegments || []}
            uncheckedSegments={this.state.uncheckedSegments || []}
            onWidgetLoaded={() => this.onWidgetLoaded({ widgetId: Channels.widgetIds.impactBySegment, widgetType: widgetTypes.impactBySegment })}
            onWidgetLoading={() => this.onWidgetLoading({ widgetId: Channels.widgetIds.impactBySegment, widgetType: widgetTypes.impactBySegment })}
          />
        </FeatureFlagsWithBlur>

        <FeatureFlagsWithBlur flag={flags.channelsAttributionTableWidget} name={flagsNames.channelsAttributionTableWidget}>
          <div id="attribution-table">
            <AttributionTable
              widgetId={Channels.widgetIds.segmentsAnalysis}
              key={`${conversionIndicator}-channels-table`}
              defaultStageKey={conversionIndicator}
              title={`${segmentAnalysisSegmentLabel} Impact Analysis`}
              data={segmentsAnalysisTableData ?? {}}
              mergedFunnelsTableData={mergedFunnelsSegmentsAnalysisTableData ?? []}
              prevData={{}}
              dataTotals={accountOptimizationMetricsData ?? {}}
              dataNickname={segmentAnalysisSegmentLabel}
              type={ATTRIBUTION_TYPES.CHANNELS}
              trendData={trendBySegmentsData}
              isLoadedTrendData={isCalculateAdvancedMetrics ? trendBySegments?.[trendBySegmentsKeyName]?.isLoaded : true}
              isLoaded={isSegmentAnalysisLoaded}
              includeAttributionStoreFilters
              filters={filters}
              updateFilters={() => this.updateFilters()}
              innerPageNavigation={({ filters: filtersForNavigate, isNewFiltersUIOnly }) => navigateBetweenAnalyzeTabs({
                filters: filtersForNavigate, tabName: 'channels', anchor: 'attribution-table', withAnimation: false, isNewFiltersUIOnly,
              })}
              navigateToJourneys={navigateToJourneys}
              segmentsOptionsFields={segmentsOptionsFieldsWithTime}
              updateSegmentsAnalysisParam={(newSelectedSegmentsParams) => this.updateSegmentsAnalysisParam(newSelectedSegmentsParams)}
              segmentsAnalysisParams={selectedSegmentsParams}
              exportMode
              shouldRequestAllFunnelStagesData
              timeframe={this.props.timeFrame}
              attributionModel={this.props.attributionModel}
              stagesAmount={stagesAmount}
              isSegmentAnalysisFailedToLoad={isSegmentAnalysisFailedToLoad}
              isAttribution={segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.isAttribution}
              isCompareToPreviousEnabled={isCompareToPreviousEnabled}
              isCalculateAdvancedMetrics={isCalculateAdvancedMetrics}
              upliftColumnsKeys={this.state.upliftColumnsKeys}
              updatedUpliftColumnsKeys={({ columnsKeys }) => this.updatedUpliftColumnsKeys({ columnsKeys, upliftRequestId: upliftBySegmentsRequestId })}
              upliftData={upliftBySegmentsData}
              isLoadedUpliftData={isLoadedUpliftData}
              onWidgetLoaded={() => this.onWidgetLoaded({ widgetId: Channels.widgetIds.segmentsAnalysis, widgetType: widgetTypes.segmentsAnalysis })}
              onWidgetLoading={() => this.onWidgetLoading({ widgetId: Channels.widgetIds.segmentsAnalysis, widgetType: widgetTypes.segmentsAnalysis })}
            />
          </div>
        </FeatureFlagsWithBlur>

        <FeatureFlagsWithBlur flag={flags.journeysChannelsWidget} name={flagsNames.journeysChannelsWidget}>
          <JourneysChannels
            isCategoryView={isCategoryView}
            isLoaded={isAllWidgetsDataLoaded({
              widgetsData: [channelJourneys, contentJourneys],
              isCompareToPreviousEnabled,
            })}
            channelJourneys={channelJourneys?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.data}
            contentJourneys={contentJourneys?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.data}
            conversionIndicator={conversionIndicator}
          />
        </FeatureFlagsWithBlur>
      </div>
    );
  }
}

export default withLDConsumer()(enhance(Channels));
