import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';

import useOnClickOutside from 'hooks/useOnClickOutside';

import InfoMarker from 'components/pages/InfoMarker';
import Page from 'components/Page';

import configPopupStyle from 'styles/settings/workflows/configPopup.css';

export default function ConfigPopup({
  isViewOnly,
  title,
  subTitle,
  onClose,
  children,
  withPopupBodyWrapper,
}) {
  const refPopup = useRef();

  useEffect(() => {
    configPopupStyle.use();
    return () => {
      configPopupStyle.unuse();
    };
  }, []);

  useOnClickOutside(refPopup, () => {
    onClose();
  });

  return (
    <Page className={configPopupStyle.locals.popup} popup>
      <div ref={refPopup}>
        <div
          className={configPopupStyle.locals.closeButton}
          onClick={onClose}
        />
        <div className={configPopupStyle.locals.header}>
          <div className={configPopupStyle.locals.title}>
            {title}
          </div>
          {isViewOnly && (
            <div className={configPopupStyle.locals.viewOnlyWrapper}>
              View only
              <InfoMarker tooltipText="Editing permission is restricted. Reach out to your CSM for additional information." />
            </div>
          )}
        </div>
        <div className={configPopupStyle.locals.subTitle}>
          {subTitle}
        </div>
        <div className={classnames(configPopupStyle.locals.childrenWrapper, withPopupBodyWrapper ? configPopupStyle.locals.popupWrap : null)}>
          {children}
        </div>
      </div>
    </Page>
  );
}
