exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".buttonWithOptions__optionsWrapper-2hP{position:relative}.buttonWithOptions__optionsButton-2Sg{align-items:center;background-color:var(--colorsGeneralWhite);border-radius:4px;display:flex;flex-wrap:wrap;justify-content:space-between;min-height:38px;position:relative;font-size:var(--fontsSizesBodyNormal);border:1px solid var(--colorsInputGrayLine);color:var(--colorsTextGray);padding:0 12px;gap:8px;overflow:hidden;white-space:nowrap;width:max-content}.buttonWithOptions__optionsButton-2Sg:not(.buttonWithOptions__disabledButton-2nz):hover{border-color:#b2bbd5;cursor:pointer}.buttonWithOptions__disabledButton-2nz{color:var(--colorsTextLightGray100);cursor:default!important}.buttonWithOptions__activeButton-1kS,.buttonWithOptions__activeButton-1kS:hover{border-color:var(--colorsGeneralBlue)}.buttonWithOptions__errorButton-22y{border-color:var(--colorsGeneralRed)}.buttonWithOptions__optionsPopup-72R{color:var(--colorsTextGray);min-width:165px;min-height:auto;top:47px;right:0;left:auto;font-size:var(--fontsSizesBodyNormal);line-height:20px}.buttonWithOptions__optionsMenu-1_A{list-style:none;padding:0;margin:6px;display:flex;flex-direction:column}.buttonWithOptions__optionItem-2Ve{padding:12px;white-space:nowrap;cursor:pointer;border-radius:4px}.buttonWithOptions__optionItem-2Ve:not(.buttonWithOptions__disabledButton-2nz):hover{background-color:var(--colorsBackgroundLightGray)}.buttonWithOptions__arrowDown-2-l{border-top:5px solid var(--colorsTextIgGray)}.buttonWithOptions__arrowDown-2-l,.buttonWithOptions__arrowUp-3ja{border-left:5px solid transparent;border-right:5px solid transparent}.buttonWithOptions__arrowUp-3ja{border-bottom:5px solid var(--colorsTextIgGray)}.buttonWithOptions__tooltipIcon-3V1{line-height:1rem;display:flex;gap:8px;margin:10px 0;max-width:200px}", ""]);

// exports
exports.locals = {
	"optionsWrapper": "buttonWithOptions__optionsWrapper-2hP",
	"optionsWrapper": "buttonWithOptions__optionsWrapper-2hP",
	"optionsButton": "buttonWithOptions__optionsButton-2Sg",
	"optionsButton": "buttonWithOptions__optionsButton-2Sg",
	"disabledButton": "buttonWithOptions__disabledButton-2nz",
	"disabledButton": "buttonWithOptions__disabledButton-2nz",
	"activeButton": "buttonWithOptions__activeButton-1kS",
	"activeButton": "buttonWithOptions__activeButton-1kS",
	"errorButton": "buttonWithOptions__errorButton-22y",
	"errorButton": "buttonWithOptions__errorButton-22y",
	"optionsPopup": "buttonWithOptions__optionsPopup-72R",
	"optionsPopup": "buttonWithOptions__optionsPopup-72R",
	"optionsMenu": "buttonWithOptions__optionsMenu-1_A",
	"optionsMenu": "buttonWithOptions__optionsMenu-1_A",
	"optionItem": "buttonWithOptions__optionItem-2Ve",
	"optionItem": "buttonWithOptions__optionItem-2Ve",
	"arrowDown": "buttonWithOptions__arrowDown-2-l",
	"arrowDown": "buttonWithOptions__arrowDown-2-l",
	"arrowUp": "buttonWithOptions__arrowUp-3ja",
	"arrowUp": "buttonWithOptions__arrowUp-3ja",
	"tooltipIcon": "buttonWithOptions__tooltipIcon-3V1",
	"tooltipIcon": "buttonWithOptions__tooltipIcon-3V1"
};