import moment from 'moment';
import { sortBy } from 'lodash';
import { persist } from 'mobx-persist';
import { action, decorate, observable } from 'mobx';

import hydrate from 'stores/hydrate';
import userStore from 'stores/userStore';
import attributionStore from 'stores/attributionStore';
import servicesStore from 'stores/servicesStore';

import { localStorageKeys } from 'enums';
import { getQueryParams } from 'components/utils/UrlParamsProvider';

class SecondBrainStore {
  constructor() {
    this.pagesTimeline = [];
    this.dataPerPage = {};
    this.isLoading = false;
    this.reasoning = [];
    this.lastUpdatedDate = null;
    this.shouldStop = false;

    this.secondBrainInterval = 3000;
    this.pollingInterval = 2000;
    this.retriesLimit = 3;
    this.pageStatus = {
      inProgress: 'inProgress',
      finished: 'finished',
    };
  }

  async start() {
    this.shouldStop = false;

    while (userStore.userAccount?.flags?.secondBrainEnabled && !this.shouldStop) {
      await new Promise((resolve) => { setTimeout(resolve, this.secondBrainInterval); });

      this.isLoading = true;

      await this.getRecommendations();

      this.isLoading = false;
      this.reasoning = [];
    }
  }

  stop() {
    this.shouldStop = true;
  }

  cleanSessionsHistory() {
    this.dataPerPage = {};
  }

  async getRecommendations() {
    const { UID, sessionId, region } = this.getRelevantParams();

    let response;
    let retriesCount = 0;
    while (!this.shouldStop && (!response || response.status === servicesStore.serverCommunication.responseStatus.inProgress)) {
      try {
        response = await servicesStore.serverCommunication.serverRequest({
          method: 'GET',
          route: 'second-brain/suggestions',
          queryParams: {
            sessionId,
            region,
            sinceDate: this.lastUpdatedDate,
          },
        });
      } catch (error) {
        retriesCount++;
        if (error.response.status === servicesStore.serverCommunication.errorStatus.unAuthorized) {
          this.shouldStop = true;
          return;
        }

        if (retriesCount >= this.retriesLimit) {
          servicesStore.logger.error('failed to get second brain suggestions', {
            method: 'GET',
            UID,
            sessionId,
            region,
            error,
            retriesCount,
          });

          return;
        }

        continue;
      }

      const updatedDataPerPage = { ...this.dataPerPage };
      const updatedPagesTimeline = [...this.pagesTimeline];

      for (const [pageId, data] of Object.entries(response.pageVisits)) {
        if (!updatedDataPerPage[pageId]) {
          updatedDataPerPage[pageId] = {
            reasoning: [],
            intro: [],
            interactions: [],
            recommendations: [],
          };

          updatedPagesTimeline.push({
            id: pageId,
            page: data.page,
            path: data.path,
            timestamp: moment(data.timestamp).unix(),
          });
        }

        const updatedPageData = updatedDataPerPage[pageId];
        updatedPageData.isLoaded = data.status === this.pageStatus.finished;

        if (data.status === this.pageStatus.inProgress) {
          const newReasoning = data.reasoning.map((reason) => reason.text);
          this.reasoning = [
            ...this.reasoning,
            ...newReasoning,
          ];

          updatedPageData.reasoning = data.reasoning.map((reason) => reason.text).join(' ');
        }

        if (data.status === this.pageStatus.finished) {
          for (const recommendation of data.recommendations) {
            if (!this.lastUpdatedDate || moment(this.lastUpdatedDate) < moment(recommendation.timestamp)) {
              this.lastUpdatedDate = recommendation.timestamp;
            }
          }

          updatedPageData.reasoning = [];
          updatedPageData.intro = updatedPageData.intro.concat(data.reasoning);
          updatedPageData.interactions = updatedPageData.interactions.concat(data.interactions);
          updatedPageData.recommendations = updatedPageData.recommendations.concat(data.recommendations);
        }

        updatedDataPerPage[pageId] = updatedPageData;
      }

      this.dataPerPage = updatedDataPerPage;
      this.pagesTimeline = sortBy(updatedPagesTimeline, ['timestamp']).reverse();

      await new Promise((resolve) => { setTimeout(resolve, this.pollingInterval); });
    }
  }

  async refine({ refinement }) {
    const {
      UID, sessionId, region, path,
    } = this.getRelevantParams();

    try {
      await servicesStore.serverCommunication.serverRequest({
        method: 'POST',
        route: 'second-brain/refine',
        body: {
          sessionId, region, path, refinement,
        },
      });
    } catch (exception) {
      servicesStore.logger.error('failed to send refinement to second brain', {
        method: 'POST',
        UID,
        sessionId,
        region,
        path,
        exception,
      });
    }
  }

  async feedback({ actionId, feedback }) {
    const {
      UID, sessionId, region, path,
    } = this.getRelevantParams();

    try {
      await servicesStore.serverCommunication.serverRequest({
        method: 'POST',
        route: 'second-brain/feedback',
        body: {
          sessionId, region, path, actionId, feedback,
        },
      });
    } catch (exception) {
      servicesStore.logger.error('failed to send feedback to second brain', {
        method: 'POST',
        UID,
        sessionId,
        region,
        path,
        exception,
      });
    }
  }

  getRelevantParams() {
    const { UID } = userStore.userMonthPlan;
    const { sessionId } = JSON.parse(localStorage.getItem(localStorageKeys.sessionData));
    const region = attributionStore.isAccountMode ? userStore.userMonthPlan.accountViewRegion : userStore.userMonthPlan.region;

    const pathname = window.location.pathname;
    let path = pathname;
    const reportId = getQueryParams({ queryParamKey: 'reportId' });
    if (reportId) {
      path = `${pathname}?reportId=${reportId}`;
    }

    return {
      UID, sessionId, region, path,
    };
  }
}

decorate(SecondBrainStore, {
  pagesTimeline: observable.ref,
  dataPerPage: observable.ref,
  isLoading: observable,
  reasoning: observable,
  start: action.bound,
  stop: action.bound,
  updateEnabled: action.bound,
  getSecondBrainRecommendations: action.bound,
  refine: action.bound,
  feedback: action.bound,
});

const schema = {
  pagesTimeline: { type: 'list' },
  dataPerPage: { type: 'object' },
};

const store = persist(schema)(new SecondBrainStore());

hydrate('secondBrainStore', store);

export default store;
