import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { Button } from '@infinigrow/libs';

import useOnClickOutside from 'hooks/useOnClickOutside';

import ConfirmPopup from 'components/ConfirmPopup';
import Dropdown from 'components/controls/Dropdown';
import Page from 'components/Page';
import Textfield from 'components/controls/Textfield';

import { isCampaignTagPropertiesValid, getEmptyTagProperties } from 'components/pages/plan/campaignPopups/logic/campaignTag';
import { operatorsOptions, nameAlreadyInUseErrorMessage } from 'components/pages/plan/campaignPopups/enums';

import style from 'styles/plan/campaignPopups.css';

const styles = style.locals || {};

function CampaignTag({
  planStore,
  onClose,
  updateCampaignTag,
  tagProperties,
  campaignTags = planStore?.campaignTags,
  removeCampaignTag,
}) {
  const [campaignTagProperties, setCampaignTagProperties] = useState(isEmpty(tagProperties) ? getEmptyTagProperties() : tagProperties);
  const [isShowConfirmationPopup, setIsShowConfirmationPopup] = useState(false);

  const refPopup = useRef();

  useEffect(() => {
    style.use();
    return () => {
      if (!style.unuse) {
        return;
      }
      style.unuse();
    };
  }, []);

  useOnClickOutside(refPopup, () => {
    onClose();
  });

  function updateTagRule({ ruleKey, ruleValue, ruleIndex }) {
    const updatedTagProperties = { ...campaignTagProperties };
    updatedTagProperties.rules[ruleIndex][ruleKey] = ruleValue;
    setCampaignTagProperties(updatedTagProperties);
  }

  function confirmDeletePopupCallBack({ isCanceled }) {
    if (!isCanceled) {
      removeCampaignTag({ tagId: campaignTagProperties.id });
      onClose();
    }
    setIsShowConfirmationPopup(false);
  }

  const isCampaignTagExist = campaignTags?.some((tag) => tag.id === campaignTagProperties.id);
  const isNameAlreadyExists = campaignTags?.filter((tag) => tag.id !== campaignTagProperties.id).some((tag) => tag.name.toLowerCase() === campaignTagProperties.name.toLowerCase());

  return (
    <>
      {isShowConfirmationPopup ? (
        <ConfirmPopup
          callback={(isCanceled) => confirmDeletePopupCallBack({ isCanceled })}
          confirmBtn="Delete"
          title={`Are you sure you want to delete '${campaignTagProperties.name}' tag?`}
        />
      ) : (
        <Page className={styles.pageWrapper} popup>
          <div ref={refPopup} className={styles.wrapper}>
            <div className={styles.titles}>
              <div className={classnames(styles.header, styles.title)}>
                Set campaign tag
                <div
                  className={styles.closeButton}
                  onClick={onClose}
                />
              </div>
              <div className={styles.text}>Use tags to create sliced views of your budget</div>
            </div>
            <div className={styles.bodyColumn}>
              <div className={styles.text}>Campaign name</div>
              {campaignTagProperties.rules.map((rule, ruleIndex) => {
                const ruleKey = `campaignTag${ruleIndex}`;

                return (
                  <div className={styles.row} key={ruleKey}>
                    <Dropdown
                      selectedKey={rule.operator}
                      options={operatorsOptions}
                      onChange={(newOperator) => updateTagRule({
                        ruleKey: 'operator',
                        ruleValue: newOperator.value,
                        ruleIndex,
                      })}
                      controlWidth={160}
                      placeholder="Set condition"
                    />
                    <Textfield
                      dataTestId="campaignTagValueInput"
                      value={`${rule.value}`}
                      onChange={(event) => updateTagRule({
                        ruleKey: 'value',
                        ruleValue: event.target.value,
                        ruleIndex,
                      })}
                      placeHolder="Type"
                    />
                  </div>
                );
              })}
            </div>
            <div className={styles.bodyColumn}>
              <div className={styles.text}>Name your campaign tag</div>
              <Textfield
                dataTestId="campaignTagNameInput"
                value={campaignTagProperties.name}
                onChange={(event) => setCampaignTagProperties({ ...campaignTagProperties, name: event.target.value })}
                isShowError={isNameAlreadyExists}
                inputErrorMessage={nameAlreadyInUseErrorMessage}
                placeHolder="Type name"
              />
            </div>
            <div className={isCampaignTagExist ? styles.footer : styles.footerOneItem}>
              {isCampaignTagExist && (
              <Button
                type="primaryRed"
                onClick={() => setIsShowConfirmationPopup(true)}
              >
                Delete
              </Button>
              )}
              <Button
                type="primaryBlue"
                onClick={() => {
                  updateCampaignTag({ campaignTagProperties });
                  onClose();
                }}
                disabled={!isCampaignTagPropertiesValid({ properties: campaignTagProperties }) || isNameAlreadyExists}
              >
                {isCampaignTagExist ? 'Update' : 'Create'}
              </Button>
            </div>
          </div>
        </Page>
      )}
    </>
  );
}

export default inject(
  ({
    planStore: {
      updateCampaignTag,
      campaignTags,
      removeCampaignTag,
    } = {},
  }) => ({
    updateCampaignTag,
    campaignTags,
    removeCampaignTag,
  }),
  observer
)(CampaignTag);
