import React, { useState, useRef } from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';
import useOnClickOutside from 'hooks/useOnClickOutside';

import Popup from 'components/Popup';
import Tooltip from 'components/controls/Tooltip';
import LimitedAccessComponent from 'components/common/LimitedAccessComponent';

import style from 'styles/analyze/widgetHeader.css';
import buttonWithOptionsStyle from 'styles/controls/buttonWithOptions.css';

const styles = style.locals || {};
const optionsStyle = buttonWithOptionsStyle.locals || {};

export default function CogwheelWithOptions({
  options,
  dataTestId = 'cogwheel-with-options',
}) {
  useStyles([style, buttonWithOptionsStyle]);

  const [isShowPopup, setIsShowPopup] = useState(false);

  const refPopupFilter = useRef();

  function closePopup() {
    setIsShowPopup(false);
  }

  useOnClickOutside(refPopupFilter, () => {
    closePopup();
  });

  function onClickAction({ action }) {
    action();
    closePopup();
  }

  function RenderOption({
    label, action, disabled, tooltip,
  }) {
    return (
      <div
        key={`option-${label}`}
        onClick={() => (disabled ? null : onClickAction({ action }))}
        className={classnames(optionsStyle.optionItem, disabled && optionsStyle.disabledButton)}
      >
        <Tooltip
          tip={tooltip}
          id={`cogwheel-option-${label}`}
          hidden={!disabled}
          place="top"
        >
          {label}
        </Tooltip>
      </div>
    );
  }

  return (
    <div ref={refPopupFilter} className={styles.widgetCogwheelWrapper}>
      <div
        className={styles.widgetCogwheel}
        onClick={() => setIsShowPopup((prev) => !prev)}
        data-testid={dataTestId}
      />
      <Popup
        className={styles.widgetCogwheelPopup}
        hidden={!isShowPopup}
      >
        <div className={optionsStyle.optionsMenu}>
          {options.map((option) => {
            const optionKey = `option-${option.label}`;
            if (option.limitedAccess) {
              return (
                <LimitedAccessComponent
                  key={optionKey}
                  tooltip={{ place: 'left' }}
                  {...option.limitedAccess}
                >
                  <RenderOption {...option} />
                </LimitedAccessComponent>
              );
            }
            return <RenderOption key={optionKey} {...option} />;
          })}
        </div>
      </Popup>
    </div>
  );
}
