import React, { useRef, useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import history from 'history';
import { Button } from '@infinigrow/libs';

import Page from 'components/Page';
import Textfield from 'components/controls/Textfield';
import Spinner from 'components/pages/journeys/Spinner';

import servicesStore from 'stores/servicesStore';

import { reportTemplateTypes } from 'components/pages/reports/enums';
import { generateUniqueCopyLabel, toastMessage } from 'components/pages/reports/logic/reports';

import { Events } from 'trackers/analytics/enums';

import reportsStyle from 'styles/reports/reports.css';

const styles = reportsStyle.locals || {};

function CreateNewReportPopup({
  onClosePopup,
  title,
  subTitle,
  actionText,
  secondaryText,
  reportsList,
  createNewReportRequest,
  widgetsOfSelectedReport,
  addWidgetToReportRequest,
  isRenameMode,
  isDuplicateMode,
  selectedReport = {},
  renameReportRequest,
  folderId,
}) {
  const refReportNameinput = useRef();

  useEffect(() => {
    refReportNameinput.current.focus();
  }, []);

  const [inputErrors, setInputErrors] = useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [reportNameinput, setReportNameinput] = useState(
    isRenameMode || isDuplicateMode ? selectedReport.label : ''
  );
  const [isCreateNewReportLoading, setIsCreateNewReportLoading] = useState(
    false
  );

  useEffect(() => {
    if (isDuplicateMode) {
      const existingLabels = reportsList.map((item) => item.label);
      const uniqueCopyLabel = generateUniqueCopyLabel({
        originalLabel: selectedReport.label,
        existingLabels,
      });
      setReportNameinput(uniqueCopyLabel);
      setIsSubmitButtonDisabled(false);
    }
  }, []);

  const onCreateNewReport = async () => {
    servicesStore.eventTracker.track({
      eventName: Events.reports.newReportCreated,
      properties: {
        source: reportTemplateTypes.scratch,
        template: 'none',
        timeframe: 'none',
      },
    });
    setIsCreateNewReportLoading(true);
    setIsSubmitButtonDisabled(true);
    const newReportId = await createNewReportRequest({
      name: reportNameinput,
      folderId,
      sourceType: reportTemplateTypes.scratch,
    });
    setIsCreateNewReportLoading(false);
    setIsSubmitButtonDisabled(false);
    onClosePopup();
    history.push(`/reports?reportId=${newReportId.value}`);
  };

  const onRenameReport = async () => {
    setIsCreateNewReportLoading(true);
    setIsSubmitButtonDisabled(true);
    await renameReportRequest({
      name: reportNameinput,
      reportId: selectedReport.value,
    });
    setIsCreateNewReportLoading(false);
    setIsSubmitButtonDisabled(false);
    onClosePopup();
  };

  const onDuplicateReport = async () => {
    setIsCreateNewReportLoading(true);
    setIsSubmitButtonDisabled(true);

    let reportConfiguration;
    const reportFilters = selectedReport.filters;
    const reportTimeframe = selectedReport.timeframe;
    if (reportFilters || reportTimeframe) {
      reportConfiguration = {
        filters: reportFilters,
        timeframe: reportTimeframe,
      };
    }

    const newReport = await createNewReportRequest({
      name: reportNameinput,
      folderId,
      configuration: reportConfiguration,
      sourceType: reportTemplateTypes.scratch,
    });

    try {
      for (const widget of widgetsOfSelectedReport) {
        const {
          type,
          configuration,
          channelJourneys,
          contentJourneys,
        } = widget;

        await addWidgetToReportRequest({
          reportId: newReport.value,
          title: widget.title,
          type,
          timeFrame: configuration.timeFrame,
          filters: configuration.filters,
          attributionModel: configuration.attributionModel,
          attributionCredit: configuration.attributionCredit,
          shouldUsePredefinedFilters: configuration.shouldUsePredefinedFilters,
          widgetConfig: {
            channelJourneys,
            contentJourneys,
            ...configuration,
          },
        });
      }
      toastMessage({ type: 'success', message: 'Report copied successfully!' });
    } catch (error) {
      servicesStore.logger.error('Duplicating report error', {
        error,
      });
      toastMessage({ type: 'error', message: 'Failed to copy report. Please try again' });
    }
    setIsCreateNewReportLoading(false);
    setIsSubmitButtonDisabled(false);
    onClosePopup();
  };

  const checkForEnteredValidName = (value) => {
    const inputValue = value.target.value;
    setReportNameinput(inputValue);
    const isDuplicateFound = reportsList.some(
      (item) => item.label === inputValue
    );

    if (inputValue === selectedReport.label && isRenameMode) {
      setInputErrors(false);
    } else {
      setInputErrors(isDuplicateFound);
    }
    setIsSubmitButtonDisabled(isDuplicateFound || inputValue === '');
  };

  let onClickActionHandler;
  if (isRenameMode) {
    onClickActionHandler = onRenameReport;
  } else if (isDuplicateMode) {
    onClickActionHandler = onDuplicateReport;
  } else {
    onClickActionHandler = onCreateNewReport;
  }

  return (
    <Page
      wrapBoxStyle={styles.popupBox}
      contentClassName={styles.reportPopup}
      popup
      width="350px"
    >
      <div className={styles.closePopupIcon} onClick={onClosePopup} />
      <div className={styles.popupTitle}>
        <b>{title}</b>
        {subTitle && <div className={styles.popupSubTitle}>{subTitle}</div>}
      </div>
      <div className={styles.popupFields}>
        <Textfield
          isShowError={inputErrors}
          inputErrorMessage="This name is already in use, please select another name"
          placeHolder="Report name"
          onChange={checkForEnteredValidName}
          value={reportNameinput}
          ref={refReportNameinput}
        />
      </div>
      <div className={classNames(styles.popupRightButtons, { [styles.popupBetweenButtons]: isDuplicateMode })}>
        {isCreateNewReportLoading && <Spinner />}
        {secondaryText && (
          <Button type="secondaryBlue" onClick={onClosePopup}>
            {secondaryText}
          </Button>
        )}
        <Button
          disabled={isSubmitButtonDisabled}
          type="primaryBlue"
          onClick={onClickActionHandler}
        >
          {actionText}
        </Button>
      </div>
    </Page>
  );
}

export default inject(
  ({
    reportsStore: {
      createNewReportRequest,
      renameReportRequest,
      widgetsOfSelectedReport,
      addWidgetToReportRequest,
      reportsList,
    },
  }) => ({
    createNewReportRequest,
    renameReportRequest,
    widgetsOfSelectedReport,
    addWidgetToReportRequest,
    reportsList,
  }),
  observer
)(CreateNewReportPopup);
