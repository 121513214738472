import React, {
  useState,
  useRef,
  useMemo,
} from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import useStyles from 'hooks/useStyles';
import useOnClickOutside from 'hooks/useOnClickOutside';

import Dropdown from 'components/controls/Dropdown';
import TimeframeDropdown from 'components/common/TimeframeDropdown';
import RollingTimeframeSelect from 'components/controls/RollingTimeframeSelect';
import timeFrameModule from 'modules/timeframe';

import { RangeCalendar } from 'components/controls/Calendar';
import { disabledDate } from 'stores/analyze/timeUtils';
import { getParseDateRangeLabel } from 'stores/analyze/logic/timeUtils';
import { getTimeframeOptions, TIMEFRAME_VALUES, rollingTimeframeTypesOptions } from 'components/utils/timeframe';

import style from 'styles/controls/select-timeframe-with-custom.css';
import widgetHeaderStyle from 'styles/analyze/widgetHeader.css';

const styles = style.locals || {};
const widgetHeaderStyles = widgetHeaderStyle.locals || {};

function SelectTimeFrameWithCustom({
  widgetTimeFrame,
  updateWidgetTimeframe,
  fiscalYearFirstMonth,
  isShowDropdownIndicator,
  dropdownClassName,
  isShowShortLabel,
  menuMaxWidth,
  disabled,
  customControlTextStyles,
  customDropdownIndicatorWrapperStyles,
  customDropdownIndicatorArrowStyles,
  customTimeframeTextColor,
  isDropdownAsLink = true,
  isUsePopup,
  isShowCompareToPrev,
  setWidgetPreviousTimeFrame,
  widgetPreviousTimeFrame,
  isUseCustomLabel,
  classNamePopup,
  classNameMenuButton,
  isShowPopupTitle,
  fallbackLabel,
}) {
  useStyles([
    style,
    widgetHeaderStyle,
  ]);

  const refPopupFilter = useRef();
  const refRollingPopupFilter = useRef();
  const [isShowCustomDate, setIsShowCustomDate] = useState(false);
  const [isShowRollingDate, setIsShowRollingDate] = useState(false);

  useOnClickOutside(refPopupFilter, () => {
    setIsShowCustomDate(false);
  });

  useOnClickOutside(refRollingPopupFilter, () => {
    setIsShowRollingDate(false);
  });

  const timeFrameParams = useMemo(() => {
    let { startDate, endDate } = timeFrameModule.getTimeframeParams({ ...widgetTimeFrame, fiscalYearFirstMonth });
    const timeFrameValue = widgetTimeFrame.value;
    if (timeFrameValue === TIMEFRAME_VALUES.CUSTOM) {
      startDate = widgetTimeFrame.orgStartDate || startDate;
      endDate = widgetTimeFrame.orgEndDate || endDate;
    }

    return {
      startDate,
      endDate,
    };
  }, [
    widgetTimeFrame,
    fiscalYearFirstMonth,
  ]);

  const dateRangeLabel = useMemo(() => {
    if (isShowShortLabel) {
      return getParseDateRangeLabel({ timeFrame: widgetTimeFrame }) || fallbackLabel;
    }
    return getParseDateRangeLabel({
      timeFrame: widgetTimeFrame,
      ...timeFrameParams,
    }) || fallbackLabel;
  }, [
    widgetTimeFrame,
    timeFrameParams,
    isShowShortLabel,
  ]);

  const handleChangeTimeFrame = (newTimeframe) => {
    if (newTimeframe.value === TIMEFRAME_VALUES.CUSTOM) {
      setIsShowCustomDate(true);
    } else if (newTimeframe.value === TIMEFRAME_VALUES.ROLLING) {
      setIsShowRollingDate(true);
    } else {
      updateWidgetTimeframe(newTimeframe);
    }
  };

  function handleChangeRollingTimeframe(newRollingTimeframe) {
    updateWidgetTimeframe(newRollingTimeframe);
    setIsShowRollingDate(false);
  }

  const onSelectCustomDate = (dateRange) => {
    const { startDate, endDate } = dateRange;
    updateWidgetTimeframe({ startDate, endDate, value: TIMEFRAME_VALUES.CUSTOM });
    setIsShowCustomDate(false);
  };

  if (isUsePopup) {
    return (
      <TimeframeDropdown
        disabled={disabled}
        onChange={(newTimeframe) => {
          updateWidgetTimeframe(newTimeframe);
        }}
        timeframe={{
          ...widgetTimeFrame,
          ...timeFrameParams,
        }}
        isShowCompareToPrev={isShowCompareToPrev}
        setPreviousTimeframe={setWidgetPreviousTimeFrame}
        isPreviousTimeframeEnable={widgetPreviousTimeFrame}
        customMenuButton={isUseCustomLabel ? ({
          isOpen,
          onClick,
        }) => (
          <div
            onClick={onClick}
            className={classNames(
              styles.customMenuButton,
              customControlTextStyles
            )}
          >
            {dateRangeLabel}
            {isShowDropdownIndicator ? (
              <div
                className={classNames(
                  styles.dropdownIndicator,
                  isOpen && styles.isPopupOpen
                )}
              />
            ) : null}
          </div>
        ) : null}
        customButtonLabel={isShowShortLabel ? dateRangeLabel : null}
        classNamePopup={classNamePopup}
        classNameMenuButton={classNameMenuButton}
        isShowPopupTitle={isShowPopupTitle}
      />
    );
  }

  return (
    <>
      <Dropdown
        options={getTimeframeOptions()}
        onChange={handleChangeTimeFrame}
        dropdownLabel={dateRangeLabel}
        placeholder=""
        isDropdownAsLink={isDropdownAsLink}
        isShowDropdownIndicator={isShowDropdownIndicator}
        className={dropdownClassName}
        menuMaxWidth={menuMaxWidth}
        disabled={disabled}
        customControlTextStyles={customControlTextStyles}
        customDropdownIndicatorWrapperStyles={customDropdownIndicatorWrapperStyles}
        customDropdownIndicatorArrowStyles={customDropdownIndicatorArrowStyles}
        controlTextColor={customTimeframeTextColor}
      />
      {isShowCustomDate ? (
        <div ref={refPopupFilter}>
          <RangeCalendar
            className={widgetHeaderStyles.selectDateRage}
            onSelect={onSelectCustomDate}
            onClose={() => setIsShowCustomDate(false)}
            disabledDate={disabledDate}
          />
        </div>
      ) : null}
      {isShowRollingDate ? (
        <div className={widgetHeaderStyles.selectDateRage} ref={refRollingPopupFilter}>
          <RollingTimeframeSelect
            className={widgetHeaderStyles.selectRollingDate}
            timeframeTypeOptions={rollingTimeframeTypesOptions}
            timeFrame={widgetTimeFrame}
            onApply={(newRollingTimeframe) => handleChangeRollingTimeframe(newRollingTimeframe)}
            onCancel={() => setIsShowRollingDate(false)}
          />
        </div>
      ) : null}
    </>
  );
}

export default inject(
  ({
    userStore: {
      userMonthPlan: {
        fiscalYearFirstMonth,
      } = {},
    } = {},
  }) => ({
    fiscalYearFirstMonth,
  }),
  observer
)(SelectTimeFrameWithCustom);
