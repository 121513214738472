import servicesStore from 'stores/servicesStore';
import userStore from 'stores/userStore';
import reportsStore from 'stores/reports/reportsStore';
import attributionStore from 'stores/attributionStore';

import { localStorageKeys } from 'enums';
import { Interactions } from 'trackers/interactions/enums';
import { getQueryParams } from 'components/utils/UrlParamsProvider';
import { getParseDateRangeLabel } from 'stores/analyze/logic/timeUtils';

export default class InteractionTracker {
  static noSessionIdError = 'no session ID when trying to send interaction';

  newSession() {
    const { UID, region } = userStore.userMonthPlan;
    const { email } = servicesStore.authService.getProfileSync();
    const { sessionId } = JSON.parse(localStorage.getItem(localStorageKeys.sessionData));

    const { timeFrame, attributionModel } = attributionStore;
    const timeframeLabel = getParseDateRangeLabel({ timeFrame });

    this.sendInteraction({
      interactionPath: 'config',
      UID,
      body: {
        region,
        sessionId,
        email,
        interactionTitle: Interactions.sessionStarted.name,
        interactionType: Interactions.sessionStarted.type,
        interactionText: `selected timeframe: ${timeframeLabel}, attribution model: ${attributionModel.label}`,
      },
    }).then();
  }

  page() {
    const { name, type } = Interactions.global.pageChanged;
    const { page } = InteractionTracker.getPageMetadata();

    this.trackConfig({ name, type, description: `${name} to ${page}` });
  }

  pageLoaded() {
    const { name, type } = Interactions.global.pageLoaded;

    this.trackConfig({ name, type });
  }

  trackConfig({
    name, type, description, widgetTitle,
  }) {
    const { UID, region } = userStore.userMonthPlan;
    const { email } = servicesStore.authService.getProfileSync();
    const { page, path } = InteractionTracker.getPageMetadata();

    const sessionData = JSON.parse(localStorage.getItem(localStorageKeys.sessionData));
    if (!sessionData?.sessionId) {
      servicesStore.logger.error(InteractionTracker.noSessionIdError, {
        UID, region, email, page, path,
      });
      return;
    }

    this.sendInteraction({
      interactionPath: 'config',
      UID,
      body: {
        region,
        sessionId: sessionData.sessionId,
        email,
        page,
        path,
        interactionTitle: name,
        interactionType: type,
        interactionText: description,
        widgetTitle,
      },
    }).then();
  }

  trackWidget({ widgetHeader, widgetData }) {
    const { UID, region } = userStore.userMonthPlan;
    const { email } = servicesStore.authService.getProfileSync();
    const { page, path } = InteractionTracker.getPageMetadata();

    const sessionData = JSON.parse(localStorage.getItem(localStorageKeys.sessionData));
    if (!sessionData?.sessionId) {
      servicesStore.logger.error(InteractionTracker.noSessionIdError, {
        UID, region, email, page, path,
      });
      return;
    }

    this.sendInteraction({
      interactionPath: 'widget',
      UID,
      body: {
        region,
        sessionId: sessionData.sessionId,
        email,
        page,
        path,
        widgetHeader,
        widgetData,
      },
    }).then();
  }

  async sendInteraction({ interactionPath, UID, body }) {
    const timestamp = new Date();
    try {
      await servicesStore.serverCommunication.serverRequest({
        method: 'POST',
        route: `interactions/${interactionPath}`,
        body: {
          ...body,
          timestamp,
        },
      });
    } catch (exception) {
      servicesStore.logger.error('failed to send interaction', {
        error: exception.message,
        UID,
        body,
        timestamp,
      });
    }
  }

  static getPageMetadata() {
    const pathname = window.location.pathname;
    const trimmedPath = pathname.replace(/^\/+|\/+$/g, '');
    const segments = trimmedPath.split('/');

    let page = segments.join(' > ');
    let path = pathname;

    const reportId = getQueryParams({ queryParamKey: 'reportId' });
    if (reportId) {
      const reportData = reportsStore.reportsWithWidgetsData.find((report) => report.id === reportId);
      page = `report: ${reportData?.name || reportId}`;
      path = `${pathname}?reportId=${reportId}`;
    }

    return { page, path };
  }
}
