import React, { useEffect, useState, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';
import moment from 'moment';
import { Button } from '@infinigrow/libs';

import useOnClickOutside from 'hooks/useOnClickOutside';

import Page from 'components/Page';
import Textfield from 'components/controls/Textfield';

import { campaignTagsColors, campaignTagsError } from 'components/pages/plan/logic/enums';
import { getAssociatedTags } from 'components/pages/plan/campaignPopups/logic/costPlaceholder';
import { amountTypes } from 'stores/plan/logic/enums';

import style from 'styles/plan/campaignPopups.css';

const styles = style.locals || {};

function CostPlaceholder({
  planStore,
  onClose,
  channel,
  campaignTags = planStore?.campaignTags,
  updateCampaignProperties,
  updateCampaignAmount,
  sendUpdateCampaignsAmountRequestToServer,
  sendUpdateCampaignsPropertiesRequestToServer,
}) {
  const [costPlaceholderName, setCostPlaceholderName] = useState();
  const [associatedTags, setAssociatedTags] = useState(campaignTags);

  const refPopup = useRef();

  useEffect(() => {
    style.use();
    return () => {
      if (!style.unuse) {
        return;
      }
      style.unuse();
    };
  }, []);

  useOnClickOutside(refPopup, () => {
    onClose();
  });

  function onSave() {
    const startDate = moment().startOf('month').format('YYYY-MM-DD');
    const endDate = moment().endOf('month').format('YYYY-MM-DD');
    updateCampaignProperties({
      name: costPlaceholderName, channel, sources: [channel], startDate, endDate, status: 'New', isPlaceholder: true,
    });
    updateCampaignAmount({
      campaignName: costPlaceholderName, channel, startDate, endDate, amountType: amountTypes.budget, amount: 0,
    });
    sendUpdateCampaignsPropertiesRequestToServer({});
    sendUpdateCampaignsAmountRequestToServer({ startDate, endDate });
    onClose();
  }

  return (
    <Page className={styles.pageWrapper} popup>
      <div ref={refPopup} className={styles.wrapper}>
        <div className={classnames(styles.header, styles.title)}>
          Add new Cost Placeholder
          <div
            className={styles.closeButton}
            onClick={onClose}
          />
        </div>
        <div className={styles.bodyColumn}>
          <div className={styles.text}>Placeholder name</div>
          <Textfield
            dataTestId="costPlaceholderNameInput"
            value={costPlaceholderName}
            onChange={(event) => {
              setCostPlaceholderName(event.target.value);
              if (event.target.value !== '') {
                setAssociatedTags(getAssociatedTags({ campaignName: event.target.value, campaignTags }));
              } else {
                setAssociatedTags(campaignTags);
              }
            }}
            placeHolder={associatedTags?.map((tag) => tag.name).join(' ')}
          />
        </div>
        <div className={styles.bodyColumn}>
          <div className={styles.text}>Associated Tags</div>
          {associatedTags?.length > 0 ? (
            <div className={styles.row}>
              {associatedTags?.map((campaignTag, tagIndex) => (
                <div
                  data-testid="associatedTags"
                  key={`${campaignTag.name}`}
                  className={styles.campaignTag}
                  style={{ ...campaignTagsColors[tagIndex % campaignTagsColors.length] }}
                >
                  {campaignTag.name}
                </div>
              ))}
            </div>
          ) : (<div className={styles.errorText}>{campaignTagsError}</div>)}
        </div>
        <div className={styles.footer}>
          <Button
            type="secondaryBlue"
            onClick={() => onClose()}
          >
            Close
          </Button>
          <Button
            type="primaryBlue"
            onClick={() => onSave()}
            disabled={!costPlaceholderName || associatedTags?.length === 0}
          >
            Create
          </Button>
        </div>
      </div>
    </Page>
  );
}

export default inject(
  ({
    planStore: {
      campaignTags,
      updateCampaignProperties,
      sendUpdateCampaignsPropertiesRequestToServer,
      updateCampaignAmount,
      sendUpdateCampaignsAmountRequestToServer,
    } = {},
  }) => ({
    campaignTags,
    updateCampaignProperties,
    sendUpdateCampaignsPropertiesRequestToServer,
    updateCampaignAmount,
    sendUpdateCampaignsAmountRequestToServer,
  }),
  observer
)(CostPlaceholder);
