import React, {
  useEffect, useRef, useState,
} from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import history from 'history';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';

import useStyles from 'hooks/useStyles';

import interactionsStore from 'stores/interactionsStore';

import FollowUpQuestionBox from 'components/pages/questions/FollowUpQuestionBox';
import EmptyStateWithImage from 'components/pages/analyze/EmptyStateWithImage';
import BetaTag from 'components/common/BetaTag';
import AskQuestionWithSuggestions from 'components/pages/questions/AskQuestionWithSuggestions';

import { defaultSuggestionsBox, suggestionsFloatBoxByType } from 'components/pages/questions/logic/mockAIAnswers';
import { getComponentAccordingWidgetType, getComponentProps } from 'components/pages/reports/logic/reports';
import { getWidgetConfigWithPlaceHolderValues, sendContextualSuggestionsRequest } from 'components/pages/questions/logic/askQuestion';
import { splitSuggestionTextToObjects, getWidgetTitle } from 'components/pages/questions/logic/suggestionItem';
import { suggestionTypes } from 'components/pages/questions/enums';

import style from 'styles/questions/ai-answers.css';
import questionsStyle from 'styles/questions/questions.css';

const styles = style.locals || {};

function AIAnswers({
  flags,
  setQuestionWidgetsMetadataResults,
  aiAnswersDataResults,
  questionWidgetsMetadataResults,
  currentRequestId,
  answersFlowWidgets,
  setAnswersFlowWidgets,
}) {
  useStyles([style, questionsStyle]);

  if (!flags.aiAnswersNewUi) {
    history.push('/questions');
    return null;
  }

  const [questionInputText, setQuestionInputText] = useState('');
  const [isLoadedFollowUpQuestions, setIsLoadedFollowUpQuestions] = useState(true);
  const [followUpQuestions, setFollowUpQuestions] = useState([]);
  const [pageWidgetsStatus, setPageWidgetsStatus] = useState({});

  const lastWidgetRef = useRef(null);

  useEffect(() => {
    getFollowUpQuestions({});

    return () => {
      if (!isEmpty(aiAnswersDataResults)) {
        setAnswersFlowWidgets({ widgets: [] });
      }
    };
  }, []);

  useEffect(() => {
    if (isEmpty(aiAnswersDataResults)) {
      setAnswersFlowWidgets({ widgets: [] });
    }
  }, [aiAnswersDataResults]);

  useEffect(() => {
    if (lastWidgetRef?.current) {
      lastWidgetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [answersFlowWidgets]);

  useEffect(() => {
    if (isEmpty(answersFlowWidgets)) {
      return;
    }

    setPageWidgetsStatus((prevState = {}) => {
      const initialPageWidgetsStatus = {};
      for (const widget of answersFlowWidgets) {
        initialPageWidgetsStatus[widget.id] = {
          widgetType: widget.type,
          isLoaded: prevState[widget.id]?.isLoaded || false,
        };
      }

      return initialPageWidgetsStatus;
    });
  }, [answersFlowWidgets.length]);

  useEffect(() => {
    const widgetsExists = !isEmpty(answersFlowWidgets);
    if (!widgetsExists) {
      return;
    }

    let isPageLoaded = true;
    for (const { widgetType, isLoaded } of Object.values(pageWidgetsStatus)) {
      if (interactionsStore.isWidgetSupported({ widgetType }) && !isLoaded) {
        isPageLoaded = false;
      }
    }

    if (isPageLoaded) {
      interactionsStore.interactionTracker.pageLoaded();
    }
  }, [pageWidgetsStatus]);

  // eslint-disable-next-line no-unused-vars
  async function getRequestFollowUpQuestions({ questionText }) {
    setIsLoadedFollowUpQuestions(false);
    // // TODO: add ep to get follow up questions by questionText
    setIsLoadedFollowUpQuestions(true);
    return [];
  }

  async function getFollowUpQuestions({ question, placeHoldersValues }) {
    if (flags.aiAnswersDemoOnly) {
      if (!question) {
        setFollowUpQuestions(defaultSuggestionsBox);
        return;
      }
      const questionType = question.questionType;
      setFollowUpQuestions(suggestionsFloatBoxByType[questionType] || []);
      return;
    }

    let questionText = '';
    if (question && placeHoldersValues) {
      const questionTextArray = splitSuggestionTextToObjects({ text: question.questionText, placeHoldersTypes: question.placeHoldersTypes, placeHoldersValues });
      questionText = getWidgetTitle({ questionTextArray });
    }
    const requestFollowUpQuestions = await getRequestFollowUpQuestions({ questionText });
    setFollowUpQuestions(requestFollowUpQuestions);
  }

  function onClickSuggestion({ questionData, placeHoldersValues }) {
    getFollowUpQuestions({ question: questionData, placeHoldersValues });
  }

  function onSelectedSuggestionFloatBox({ questionData, placeHoldersValues }) {
    const widgetAsJson = getWidgetConfigWithPlaceHolderValues({ placeHoldersValues, questionData });
    setQuestionWidgetsMetadataResults({ widget: widgetAsJson, questionId: questionData.questionId, shouldAppendDataById: true });
    setAnswersFlowWidgets({ widgets: [...answersFlowWidgets, { ...questionData.widget, questionId: questionData.questionId }] });
    getFollowUpQuestions({ question: questionData, placeHoldersValues });
    sendContextualSuggestionsRequest({
      question: questionData, placeHoldersValues, suggestionType: suggestionTypes.followup, currentRequestId,
    });
  }

  function onWidgetLoaded({ widgetId, widgetType }) {
    setPageWidgetsStatus((prevState = {}) => ({
      ...prevState,
      [widgetId]: {
        widgetType,
        isLoaded: true,
      },
    }));
  }

  function onWidgetLoading({ widgetId, widgetType }) {
    if (pageWidgetsStatus[widgetId] && !pageWidgetsStatus[widgetId].isLoaded) {
      return;
    }

    setPageWidgetsStatus((prevState = {}) => ({
      ...prevState,
      [widgetId]: {
        widgetType,
        isLoaded: false,
      },
    }));
  }

  const shouldShowFollowUpQuestions = answersFlowWidgets.length > 0 && followUpQuestions.length > 0 && questionInputText === '';

  return (
    <div className={styles.answersSuggestionWrapper}>
      {answersFlowWidgets.length === 0 ? (
        <div className={styles.answersInitialState}>
          <div className={styles.answersTitle}>
            <div className={styles.answersIcon} />
            AI Answers
            <BetaTag />
          </div>
          <div className={styles.answersSubTitle}>Ask questions in natural language, and InfiniGrow will automatically generate an answer and suggest follow-up questions.</div>
          <div className={styles.followUpQuestionBoxWrapper}>
            {followUpQuestions.map((suggestion) => (
              <FollowUpQuestionBox
                key={suggestion.id}
                content={suggestion.content}
                onClick={suggestion.question ? () => {
                  onSelectedSuggestionFloatBox({ questionData: suggestion.question, placeHoldersValues: suggestion.question.defaultPlaceHoldersValues });
                } : null}
                isLoaded={isLoadedFollowUpQuestions}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className={styles.answersResults}>
          {answersFlowWidgets.map((widget, index) => {
            const currentAIAnswersLoadingResults = aiAnswersDataResults[widget.questionId] || {};
            const currentWidgetsMetadataResults = questionWidgetsMetadataResults[widget.questionId] || {};
            const widgetProps = getComponentProps({ widgetData: { ...widget, ...currentWidgetsMetadataResults, ...currentAIAnswersLoadingResults }, isInReports: false, isShowTimeframeSelect: true });
            const WidgetComponent = getComponentAccordingWidgetType({ widgetType: widget.type });
            const answerKey = `widget-${currentWidgetsMetadataResults.type}-${index}`;
            return (
              <div key={answerKey}>
                {index === answersFlowWidgets.length - 1 && answersFlowWidgets.length > 1 && <div ref={lastWidgetRef} />}
                <WidgetComponent
                  {...widgetProps}
                  isLoaded={currentAIAnswersLoadingResults.isLoaded}
                  isLoadedTrendData={currentAIAnswersLoadingResults.isLoadedTrendData}
                  isReadOnly
                  emptyStateComponent={(
                    <EmptyStateWithImage
                      imageClassName={styles.answersEmptyStateImage}
                      title="This question did not return any results"
                      subTitle="Try changing the filters or the timeframe"
                      height="460px"
                      isContentCentered
                    />
                  )}
                  onWidgetLoaded={() => onWidgetLoaded({ widgetId: widget.id, widgetType: widget.type })}
                  onWidgetLoading={() => onWidgetLoading({ widgetId: widget.id, widgetType: widget.type })}
                />
              </div>
            );
          })}
        </div>
      )}

      <div className={styles.aiAnswersAskWrapper}>
        {shouldShowFollowUpQuestions ? (
          <div className={styles.followUpQuestionBoxWrapper}>
            {followUpQuestions.map((suggestion) => (
              <FollowUpQuestionBox
                key={suggestion.id}
                content={suggestion.content}
                iconPosition="left"
                onClick={suggestion.question ? () => {
                  onSelectedSuggestionFloatBox({ questionData: suggestion.question, placeHoldersValues: suggestion.question.defaultPlaceHoldersValues });
                } : null}
                isLoaded={isLoadedFollowUpQuestions}
              />
            ))}
          </div>
        ) : null}
        <div className={styles.aiAnswersAskInputWrapper}>
          <AskQuestionWithSuggestions
            questionInputText={questionInputText}
            setQuestionInputText={setQuestionInputText}
            placeholder={answersFlowWidgets.length === 0 ? 'Ask any question' : 'Ask any follow-up question'}
            onClickSuggestion={({ questionData, placeHoldersValues }) => onClickSuggestion({ questionData, placeHoldersValues })}
          />
        </div>
      </div>
    </div>
  );
}

export default withLDConsumer()(inject(
  ({
    questionsStore: {
      setQuestionWidgetsMetadataResults,
      questionWidgetsMetadataResults,
      aiAnswersDataResults,
      currentRequestId,
      answersFlowWidgets,
      setAnswersFlowWidgets,
    },
  }) => ({
    setQuestionWidgetsMetadataResults,
    questionWidgetsMetadataResults,
    aiAnswersDataResults,
    currentRequestId,
    answersFlowWidgets,
    setAnswersFlowWidgets,
  }),
  observer
)(AIAnswers));
