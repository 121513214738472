import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import interactionsStore from 'stores/interactionsStore';
import AttributionTable from 'components/pages/analyze/AttribuitonTable/AttributionTable';
import CampaignPopup from 'components/pages/campaigns/CampaignPopup';
import Component from 'components/Component';
import FeatureFlagsWithBlur from 'components/common/FeatureFlagsWithBlur';
import JourneysCampaigns from 'components/pages/analyze/CampaignsTab/JourneysCampaigns';
import ImpactBySegment from 'components/pages/analyze/SegmentsTab/ImpactBySegment';
import BreadcrumbsTitle from 'components/common/BreadcrumbsTitle';

import { ATTRIBUTION_TYPES, compose } from 'components/utils/utils';
import { AnalyzeContext } from 'components/pages/Analyze';
import { getCurrentHashAndScrollToAnchorTag, navigateBetweenAnalyzeTabs } from 'stores/analyze/logic/navigations';
import { isAllWidgetsDataLoaded, isFiltersChanged, isTimeframeChanged } from 'components/pages/analyze/logic/Tabs';
import { navigateFromCampaignsToJourneys } from 'stores/analyze/logic/campaignsStore';
import { widgetTypes, flagsNames } from 'components/pages/analyze/enums';
import { FREQUENCY_VALUES } from 'components/utils/frequency';
import { analyzeWidgetsResultKeyNames } from 'stores/logic/enums';
import {
  getWidgetsData, getWidgetsDataFromStore, getWidgetsDataV2, getWidgetFullConfig, getWidgetsDataFromStoreV2,
} from 'components/pages/analyze/widgetsRequest';
import { getFilteredImpactBySegmentRecords, getFilteredSegmentAnalysisRecords } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { convertShowOnlyFilters, getImpactByFieldsToEnrichAccordingSegment } from 'components/pages/analyze/SegmentsTab/logic/ImpactBySegment';
import { isFlagActive } from 'components/pages/analyze/logic/FeatureFlagsWithBlur';
import { getSegmentsOptionsFields } from 'components/pages/analyze/OverviewTab/logic';
import { segmentsTimeValues } from 'components/pages/analyze/AttribuitonTable/logic/AttributionTable';
import { segmentsTimeOptions } from 'components/pages/analyze/AttribuitonTable/enums';
import { pageLabels } from 'enums';

const enhance = compose(
  inject((stores) => {
    const {
      attributionModel,
      formattedTimeParams: timeFrame,
      conversionIndicator,
      previousTimeframe: isCompareToPreviousEnabled,
      setPreviousCheckbox,
      isOtherCampaignsHidden,
      isMoneyFormat,
      velocityKey,
      filters,
      autoFilter,
      setFilters,
      originalFunnelFromIndicator,
      isAccountMode,
      isCalculateAdvancedMetrics,
    } = stores.attributionStore;

    const {
      funnels,
      userAccount: {
        customFieldsIdToLabelMap,
        customUtmsWhitelist,
      },
    } = stores.userStore;

    const {
      userMonthPlan: {
        region,
      },
    } = stores.userStore;

    const {
      dataPerWidget: {
        [widgetTypes.topConversionCampaignJourneys]: campaignJourneys,
        [widgetTypes.topConversionContentCampaignJourneys]: contentCampaignJourneys,
        [widgetTypes.trendBySegments]: trendBySegments,
        [widgetTypes.accountOptimizationMetrics]: accountOptimizationMetrics,
      },
    } = stores.analysisStore;

    const {
      dataPerWidget: {
        [widgetTypes.segmentsAnalysis]: segmentsAnalysis,
        [widgetTypes.impactBySegment]: impactBySegment,
        [widgetTypes.upliftBySegments]: upliftBySegments,
      },
      configPerWidget: {
        [widgetTypes.impactBySegment]: impactBySegmentSavedConfig,
      },
      getWidgetRequestId,
    } = stores.widgetsAnalysisStore;

    return {
      autoFilter,
      timeFrame,
      isCompareToPreviousEnabled,
      setPreviousCheckbox,
      isOtherCampaignsHidden,
      attributionModel,
      conversionIndicator,
      isMoneyFormat,
      velocityKey,
      filters,
      impactBySegmentSavedConfig,
      campaignJourneys,
      contentCampaignJourneys,
      segmentsAnalysis,
      region,
      trendBySegments,
      originalFunnelFromIndicator,
      accountOptimizationMetrics,
      impactBySegment,
      setFilters,
      isAccountMode,
      customFieldsIdToLabelMap,
      customUtmsWhitelist,
      funnels,
      getWidgetRequestId,
      isCalculateAdvancedMetrics,
      upliftBySegments,
    };
  }),
  observer
);

class Campaigns extends Component {
  static widgetIds = {
    impactBySegment: 'analyze-campaigns-impact-by-segment',
    segmentsAnalysis: 'analyze-campaigns-impact-table',
  };

  constructor(props) {
    super(props);

    let firstSegment = 'campaign';
    if (props.flags?.campaignsTabPrimarySegmentAsUtmCampaign) {
      firstSegment = 'utm_campaign';
    }

    this.currentTabName = 'campaigns';
    this.state = {
      showPopup: false,
      campaign: {},
      campaignsImpactFrequency: props.impactBySegmentSavedConfig?.[this.currentTabName]?.impactBySegmentParams?.segmentFrequency || FREQUENCY_VALUES.MONTH,
      selectedSegmentsParams: { firstSegment, secondSegment: null },
      selectedSegmentImpactBySegment: firstSegment,
      trendBySegmentsKeyName: 'trendByCampaign',
      accountOptimizationMetricsKeyName: 'accountOptimizationMetricsAnalyze',
      shouldSplitRequestByWidget: false,
      drilldownMetric: null,
      shouldUseRelativeTimeframe: props.impactBySegmentSavedConfig?.[this.currentTabName]?.impactBySegmentParams?.shouldUseRelativeTimeframe || false,
      isInitialLoad: null,
      upliftColumnsKeys: [],
      upliftRequestId: null,
      pageWidgetsStatus: {
        [Campaigns.widgetIds.impactBySegment]: {
          widgetType: widgetTypes.impactBySegment,
          isLoaded: false,
        },
        [Campaigns.widgetIds.segmentsAnalysis]: {
          widgetType: widgetTypes.segmentsAnalysis,
          isLoaded: false,
        },
      },
    };
  }

  componentDidMount() {
    const {
      isCompareToPreviousEnabled,
      campaignJourneys,
      contentCampaignJourneys,
      trendBySegments,
      accountOptimizationMetrics,
      originalFunnelFromIndicator,
      flags,
      location,
      isCalculateAdvancedMetrics,
    } = this.props;

    this.setState({ isInitialLoad: true });
    getCurrentHashAndScrollToAnchorTag(window.location.hash);

    const isRenderViaNavigation = location?.state?.isRenderViaNavigation && location?.action === 'PUSH';
    if (isRenderViaNavigation) {
      return;
    }

    if (isFlagActive({ flag: flags.campaignsImpactBySegmentWidget })) {
      if (!trendBySegments?.[`chart${this.state.trendBySegmentsKeyName}`]?.isLoaded && this.state.shouldSplitRequestByWidget && isCalculateAdvancedMetrics) {
        getWidgetsData({
          widgets: [widgetTypes.trendBySegments],
          configPerWidget: {
            [widgetTypes.trendBySegments]: [{
              trendBySegmentsParams: {
                firstSegment: this.state.selectedSegmentImpactBySegment,
                secondSegment: null,
              },
              conversionIndicator: originalFunnelFromIndicator,
            }],
          },
          isPreviousTimeFrame: false,
          resultKeyName: `chart${this.state.trendBySegmentsKeyName}`,
        });
      }

      if (!trendBySegments?.[this.state.trendBySegmentsKeyName]?.isLoaded && isCalculateAdvancedMetrics) {
        getWidgetsData({
          widgets: [widgetTypes.trendBySegments],
          configPerWidget: {
            [widgetTypes.trendBySegments]: [{
              trendBySegmentsParams: this.state.selectedSegmentsParams,
              conversionIndicator: originalFunnelFromIndicator,
            }],
          },
          isPreviousTimeFrame: false,
          resultKeyName: this.state.trendBySegmentsKeyName,
        });
      }
      if (!accountOptimizationMetrics?.[this.state.accountOptimizationMetricsKeyName]?.isLoaded) {
        getWidgetsData({
          widgets: [widgetTypes.accountOptimizationMetrics],
          isPreviousTimeFrame: false,
          resultKeyName: this.state.accountOptimizationMetricsKeyName,
        });
      }
    }

    if (isFlagActive({ flag: flags.journeysCampaignsWidget })) {
      if (!campaignJourneys?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.isLoaded) {
        getWidgetsData({
          widgets: [widgetTypes.topConversionCampaignJourneys],
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });
      }
      if (!contentCampaignJourneys?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.isLoaded) {
        getWidgetsData({
          widgets: [widgetTypes.topConversionContentCampaignJourneys],
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      filters,
      timeFrame,
      attributionModel,
      isCompareToPreviousEnabled,
      conversionIndicator,
      autoFilter,
      region,
      originalFunnelFromIndicator,
      flags,
      isAccountMode,
      isCalculateAdvancedMetrics,
    } = this.props;

    const {
      isInitialLoad,
    } = this.state;

    const didInitialLoadChanged = isInitialLoad !== prevState.isInitialLoad;
    const didAccountModeChanged = isAccountMode !== prevProps.isAccountMode;
    const didPreDefinedFiltersChanged = autoFilter !== prevProps.autoFilter;
    const didConversionIndicatorChanged = conversionIndicator !== prevProps.conversionIndicator;
    const didFiltersChanged = isFiltersChanged({ filters, prevFilters: prevProps.filters });
    const didTimeFrameChanged = isTimeframeChanged({ timeFrame, prevTimeFrame: prevProps.timeFrame });
    const didAttributionModelChanged = attributionModel !== prevProps.attributionModel;
    const didCompareToPreviousChanged = isCompareToPreviousEnabled && !prevProps.isCompareToPreviousEnabled;
    const didRegionChanged = region !== prevProps.region;
    const didCalculateAdvancedMetricsChanged = isCalculateAdvancedMetrics !== prevProps.isCalculateAdvancedMetrics;

    if (didCalculateAdvancedMetricsChanged && isCalculateAdvancedMetrics) {
      getWidgetsData({
        widgets: [widgetTypes.trendBySegments],
        configPerWidget: {
          [widgetTypes.trendBySegments]: [{
            trendBySegmentsParams: {
              firstSegment: this.state.selectedSegmentsParams.firstSegment,
            },
            conversionIndicator: originalFunnelFromIndicator,
          }],
        },
        isPreviousTimeFrame: false,
        resultKeyName: this.state.trendBySegmentsKeyName,
      });
      if (this.state.shouldSplitRequestByWidget) {
        getWidgetsData({
          widgets: [widgetTypes.trendBySegments],
          configPerWidget: {
            [widgetTypes.trendBySegments]: [{
              trendBySegmentsParams: {
                firstSegment: this.state.selectedSegmentImpactBySegment,
                secondSegment: null,
              },
              conversionIndicator: originalFunnelFromIndicator,
            }],
          },
          isPreviousTimeFrame: false,
          resultKeyName: `chart${this.state.trendBySegmentsKeyName}`,
        });
      }
    }

    if (didInitialLoadChanged || didAccountModeChanged || didFiltersChanged || didTimeFrameChanged || didAttributionModelChanged || didPreDefinedFiltersChanged || didRegionChanged) {
      if (isFlagActive({ flag: flags.campaignsAttributionTableWidget })) {
        getWidgetsDataV2({
          widget: widgetTypes.segmentsAnalysis,
          widgetConfig: {
            segmentsAnalysisParams: this.state.selectedSegmentsParams,
            conversionIndicator: null,
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });
        this.setState({ upliftColumnsKeys: [], upliftRequestId: null });
      }
    }

    if (didAccountModeChanged || didFiltersChanged || didTimeFrameChanged || didAttributionModelChanged || didPreDefinedFiltersChanged || didRegionChanged || didConversionIndicatorChanged) {
      if (isFlagActive({ flag: flags.journeysCampaignsWidget })) {
        getWidgetsData({
          widgets: [widgetTypes.topConversionCampaignJourneys],
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });
        getWidgetsData({
          widgets: [widgetTypes.topConversionContentCampaignJourneys],
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });
      }
    }

    if (didInitialLoadChanged || didAccountModeChanged || didFiltersChanged || didTimeFrameChanged || didAttributionModelChanged || didConversionIndicatorChanged || didPreDefinedFiltersChanged) {
      if (isFlagActive({ flag: flags.campaignsImpactBySegmentWidget })) {
        let drilldownMetric = this.state.drilldownMetric;
        if (didConversionIndicatorChanged) {
          drilldownMetric = conversionIndicator;
          this.setState({ drilldownMetric });
        }
        getWidgetsDataV2({
          widget: widgetTypes.impactBySegment,
          widgetConfig: {
            impactBySegmentParams: {
              segmentFrequency: this.state.campaignsImpactFrequency,
              firstSegment: this.state.selectedSegmentImpactBySegment,
              fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: this.state.selectedSegmentImpactBySegment } }),
              shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
            },
            conversionIndicator: originalFunnelFromIndicator,
            kpiFocus: drilldownMetric || conversionIndicator,
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
          configKeyName: this.currentTabName,
        });
      }
    }

    if (didAccountModeChanged || didFiltersChanged || didTimeFrameChanged || didAttributionModelChanged || didConversionIndicatorChanged || didPreDefinedFiltersChanged) {
      if (isFlagActive({ flag: flags.campaignsImpactBySegmentWidget })) {
        if (this.state.shouldSplitRequestByWidget && isCalculateAdvancedMetrics) {
          getWidgetsData({
            widgets: [widgetTypes.trendBySegments],
            configPerWidget: {
              [widgetTypes.trendBySegments]: [{
                trendBySegmentsParams: {
                  firstSegment: this.state.selectedSegmentImpactBySegment,
                  secondSegment: null,
                },
                conversionIndicator: originalFunnelFromIndicator,
              }],
            },
            isPreviousTimeFrame: false,
            resultKeyName: `chart${this.state.trendBySegmentsKeyName}`,
          });
        }
        getWidgetsData({
          widgets: [widgetTypes.accountOptimizationMetrics],
          isPreviousTimeFrame: false,
          resultKeyName: this.state.accountOptimizationMetricsKeyName,
        });
      }

      if (isFlagActive({ flag: flags.campaignsAttributionTableWidget }) && isCalculateAdvancedMetrics) {
        getWidgetsData({
          widgets: [widgetTypes.trendBySegments],
          configPerWidget: {
            [widgetTypes.trendBySegments]: [{
              trendBySegmentsParams: {
                firstSegment: this.state.selectedSegmentsParams.firstSegment,
              },
              conversionIndicator: originalFunnelFromIndicator,
            }],
          },
          isPreviousTimeFrame: false,
          resultKeyName: this.state.trendBySegmentsKeyName,
        });
      }
    }

    if (didCompareToPreviousChanged) {
      if (isFlagActive({ flag: flags.campaignsAttributionTableWidget })) {
        getWidgetsDataFromStoreV2({
          widget: widgetTypes.segmentsAnalysis,
          widgetConfig: {
            segmentsAnalysisParams: this.state.selectedSegmentsParams,
            conversionIndicator: null,
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });
        this.setState({ upliftColumnsKeys: [], upliftRequestId: null });
      }

      if (isFlagActive({ flag: flags.campaignsImpactBySegmentWidget })) {
        getWidgetsDataFromStoreV2({
          widget: widgetTypes.impactBySegment,
          widgetConfig: {
            impactBySegmentParams: {
              segmentFrequency: this.state.campaignsImpactFrequency,
              firstSegment: this.state.selectedSegmentImpactBySegment,
              fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: this.state.selectedSegmentImpactBySegment } }),
              shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
            },
            conversionIndicator: originalFunnelFromIndicator,
            kpiFocus: this.state.drilldownMetric || conversionIndicator,
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
          configKeyName: this.currentTabName,
        });
      }
      if (isFlagActive({ flag: flags.journeysCampaignsWidget })) {
        getWidgetsDataFromStore({
          widgets: [widgetTypes.topConversionCampaignJourneys],
          isPreviousTimeFrame: true,
        });
        getWidgetsDataFromStore({
          widgets: [widgetTypes.topConversionContentCampaignJourneys],
          isPreviousTimeFrame: true,
        });
      }
    }
  }

  updateCampaignsImpactFrequency = ({ newCampaignsImpactFrequency }) => {
    const {
      originalFunnelFromIndicator, conversionIndicator,
    } = this.props;
    this.setState({ campaignsImpactFrequency: newCampaignsImpactFrequency });
    getWidgetsDataV2({
      widget: widgetTypes.impactBySegment,
      widgetConfig: {
        impactBySegmentParams: {
          segmentFrequency: newCampaignsImpactFrequency,
          firstSegment: this.state.selectedSegmentImpactBySegment,
          fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: this.state.selectedSegmentImpactBySegment } }),
          shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
        },
        conversionIndicator: originalFunnelFromIndicator,
        kpiFocus: this.state.drilldownMetric || conversionIndicator,
      },
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
      configKeyName: this.currentTabName,
    });
  };

  updateDrilldownMetric({ metric }) {
    this.setState({ drilldownMetric: metric?.value });

    const {
      originalFunnelFromIndicator, conversionIndicator,
    } = this.props;
    getWidgetsDataV2({
      widget: widgetTypes.impactBySegment,
      widgetConfig: {
        impactBySegmentParams: {
          segmentFrequency: this.state.campaignsImpactFrequency,
          firstSegment: this.state.selectedSegmentImpactBySegment,
          fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: this.state.selectedSegmentImpactBySegment } }),
          shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
        },
        conversionIndicator: originalFunnelFromIndicator,
        kpiFocus: metric?.value || conversionIndicator,
      },
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
      configKeyName: this.currentTabName,
    });
  }

  updateFilters() {
    const newFilters = convertShowOnlyFilters({
      filters: [...this.props.filters],
      userFunnels: this.props.funnels,
      conversionIndicator: this.props.conversionIndicator,
      filtersData: this.props.filtersData,
    });
    this.props.setFilters(newFilters, false);
  }

  updateSegmentsAnalysisParam(newSelectedSegmentsParams) {
    const { conversionIndicator, flags, isCalculateAdvancedMetrics } = this.props;
    const { selectedSegmentImpactBySegment } = this.state;

    const isFirstSegmentIsTime = segmentsTimeValues.includes(newSelectedSegmentsParams.firstSegment);
    const updatedSelectedSegmentImpactBySegment = isFirstSegmentIsTime ? selectedSegmentImpactBySegment : newSelectedSegmentsParams.firstSegment;
    const shouldSplitRequestByWidget = !!newSelectedSegmentsParams.secondSegment || isFirstSegmentIsTime;
    const shouldRequestImpactBySegment = selectedSegmentImpactBySegment !== updatedSelectedSegmentImpactBySegment;

    this.setState({
      selectedSegmentsParams: newSelectedSegmentsParams,
      selectedSegmentImpactBySegment: updatedSelectedSegmentImpactBySegment,
      shouldSplitRequestByWidget,
      upliftColumnsKeys: [],
      upliftRequestId: null,
    });

    if (isFlagActive({ flag: flags.campaignsImpactBySegmentWidget })) {
      if (shouldRequestImpactBySegment) {
        getWidgetsDataV2({
          widget: widgetTypes.impactBySegment,
          widgetConfig: {
            impactBySegmentParams: {
              segmentFrequency: this.state.campaignsImpactFrequency,
              firstSegment: updatedSelectedSegmentImpactBySegment,
              fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: updatedSelectedSegmentImpactBySegment } }),
              shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
            },
            conversionIndicator,
            kpiFocus: this.state.drilldownMetric || conversionIndicator,
          },
          isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
          configKeyName: this.currentTabName,
        });
      }
    }

    getWidgetsDataV2({
      widget: widgetTypes.segmentsAnalysis,
      widgetConfig: {
        segmentsAnalysisParams: newSelectedSegmentsParams,
        conversionIndicator: null,
      },
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
    });

    if (isCalculateAdvancedMetrics) {
      getWidgetsData({
        widgets: [widgetTypes.trendBySegments],
        configPerWidget: {
          [widgetTypes.trendBySegments]: [{
            trendBySegmentsParams: newSelectedSegmentsParams,
            conversionIndicator,
          }],
        },
        isPreviousTimeFrame: false,
        resultKeyName: this.state.trendBySegmentsKeyName,
      });

      if (isFlagActive({ flag: flags.campaignsImpactBySegmentWidget })) {
        if (shouldSplitRequestByWidget) {
          getWidgetsData({
            widgets: [widgetTypes.trendBySegments],
            configPerWidget: {
              [widgetTypes.trendBySegments]: [{
                trendBySegmentsParams: { firstSegment: updatedSelectedSegmentImpactBySegment, secondSegment: null },
                conversionIndicator,
              }],
            },
            isPreviousTimeFrame: false,
            resultKeyName: `chart${this.state.trendBySegmentsKeyName}`,
          });
        }
      }
    }
  }

  updateShouldUseRelativeTimeframe() {
    const { originalFunnelFromIndicator, conversionIndicator } = this.props;
    this.setState((prevState) => ({ shouldUseRelativeTimeframe: !prevState.shouldUseRelativeTimeframe }), () => {
      getWidgetsDataV2({
        widget: widgetTypes.impactBySegment,
        widgetConfig: {
          impactBySegmentParams: {
            segmentFrequency: this.state.campaignsImpactFrequency,
            firstSegment: this.state.selectedSegmentImpactBySegment,
            fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: this.state.selectedSegmentImpactBySegment } }),
            shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
          },
          conversionIndicator: originalFunnelFromIndicator,
          kpiFocus: this.state.drilldownMetric || conversionIndicator,
        },
        isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
        configKeyName: this.currentTabName,
      });
    });
  }

  updatedUpliftColumnsKeys({ columnsKeys, upliftRequestId }) {
    this.setState({ upliftColumnsKeys: columnsKeys, upliftRequestId });

    const upliftBySegmentsData = this.props.upliftBySegments?.[upliftRequestId]?.result?.data;
    const shouldRequestUpliftBySegments = !upliftBySegmentsData && !columnsKeys.every((key) => upliftBySegmentsData?.[key]);
    if (shouldRequestUpliftBySegments) {
      getWidgetsDataV2({
        widget: widgetTypes.upliftBySegments,
        widgetConfig: {
          upliftBySegmentsParams: {
            selectedMetrics: columnsKeys,
            firstSegment: this.state.selectedSegmentsParams.firstSegment,
            secondSegment: this.state.selectedSegmentsParams.secondSegment,
          },
        },
        parentRequestId: upliftRequestId,
      });
    }
  }

  onWidgetLoaded({ widgetId, widgetType }) {
    const pageWidgetsStatus = this.state.pageWidgetsStatus;
    if (!pageWidgetsStatus[widgetId]) {
      return;
    }

    let isPageLoaded = true;
    for (const [pageWidgetId, pageWidgetData] of Object.entries(pageWidgetsStatus)) {
      if (pageWidgetId === widgetId) {
        continue;
      }

      if (interactionsStore.isWidgetSupported({ widgetType: pageWidgetData.widgetType }) && !pageWidgetData.isLoaded) {
        isPageLoaded = false;
      }
    }

    if (isPageLoaded) {
      interactionsStore.interactionTracker.pageLoaded();
    }

    this.setState((prevState) => ({
      pageWidgetsStatus: {
        ...prevState.pageWidgetsStatus,
        [widgetId]: {
          widgetType,
          isLoaded: true,
        },
      },
    }));
  }

  onWidgetLoading({ widgetId, widgetType }) {
    if (this.state.pageWidgetsStatus[widgetId] && !this.state.pageWidgetsStatus[widgetId].isLoaded) {
      return;
    }

    this.setState((prevState) => ({
      pageWidgetsStatus: {
        ...prevState.pageWidgetsStatus,
        [widgetId]: {
          widgetType,
          isLoaded: false,
        },
      },
    }));
  }

  render() {
    const {
      conversionIndicator,
      isCompareToPreviousEnabled,
      flags,
      isOtherCampaignsHidden,
      campaignJourneys,
      contentCampaignJourneys,
      segmentsAnalysis,
      impactBySegment,
      originalFunnelFromIndicator,
      trendBySegments,
      accountOptimizationMetrics,
      filters,
      customFieldsIdToLabelMap,
      customUtmsWhitelist,
      getWidgetRequestId,
      isCalculateAdvancedMetrics,
      upliftBySegments,
    } = this.props;
    const {
      campaignsImpactFrequency,
      selectedSegmentsParams,
      trendBySegmentsKeyName,
      accountOptimizationMetricsKeyName,
      shouldSplitRequestByWidget,
      drilldownMetric,
      shouldUseRelativeTimeframe,
      selectedSegmentImpactBySegment,
    } = this.state;

    const { campaign } = this.state;

    const segmentsAnalysisFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        segmentsAnalysisParams: selectedSegmentsParams,
        conversionIndicator: null,
      },
      isPreviousTimeFrame: isCompareToPreviousEnabled,
    });

    const segmentsAnalysisRequestId = getWidgetRequestId({ widget: widgetTypes.segmentsAnalysis, widgetConfig: segmentsAnalysisFullWidgetConfig });

    const impactBySegmentFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        impactBySegmentParams: {
          segmentFrequency: campaignsImpactFrequency,
          firstSegment: this.state.selectedSegmentImpactBySegment,
          fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: this.state.selectedSegmentImpactBySegment } }),
          shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
        },
        kpiFocus: drilldownMetric || conversionIndicator,
        conversionIndicator: originalFunnelFromIndicator,
      },
      isPreviousTimeFrame: isCompareToPreviousEnabled,
    });
    const impactBySegmentRequestId = getWidgetRequestId({ widget: widgetTypes.impactBySegment, widgetConfig: impactBySegmentFullWidgetConfig });

    const impactBySegmentData = impactBySegment?.[impactBySegmentRequestId]?.result?.impactData;
    const metadataBySegment = impactBySegment?.[impactBySegmentRequestId]?.result?.metadataBySegment;

    const filteredImpactBySegment = getFilteredImpactBySegmentRecords({
      data: impactBySegmentData, filters, segment: selectedSegmentsParams.firstSegment, metadataBySegment,
    });

    const accountOptimizationMetricsData = accountOptimizationMetrics?.[accountOptimizationMetricsKeyName]?.data;

    const chartDataTrendKey = shouldSplitRequestByWidget ? `chart${trendBySegmentsKeyName}` : trendBySegmentsKeyName;
    const trendBySegmentsData = trendBySegments?.[chartDataTrendKey]?.data;
    const trendBySegmentsChartData = trendBySegments?.[chartDataTrendKey]?.data?.[originalFunnelFromIndicator]?.segments;

    const segmentsAnalysisTableData = {};
    const segmentAnalysisData = segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.dataByFunnel ?? {};
    for (const [stageName, stageData] of Object.entries(segmentAnalysisData)) {
      segmentsAnalysisTableData[stageName] = getFilteredSegmentAnalysisRecords({
        data: stageData,
        filters,
        segments: selectedSegmentsParams,
      });
    }

    const mergedFunnelsSegmentsAnalysisTableData = getFilteredSegmentAnalysisRecords({
      data: segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.mergedFunnelsTableData ?? [],
      filters,
      segments: selectedSegmentsParams,
    });

    const upliftBySegmentsFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        upliftBySegmentsParams: {
          selectedMetrics: this.state.upliftColumnsKeys,
          firstSegment: selectedSegmentsParams.firstSegment,
          secondSegment: selectedSegmentsParams.secondSegment,
        },
      },
    });
    const upliftBySegmentsRequestId = this.state.upliftRequestId || getWidgetRequestId({ widget: widgetTypes.upliftBySegments, widgetConfig: upliftBySegmentsFullWidgetConfig });
    const upliftBySegmentsData = upliftBySegments?.[upliftBySegmentsRequestId]?.result?.data;

    const stagesAmount = segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.countPerStage ?? {};
    const segmentsOptionsFields = getSegmentsOptionsFields({ customFieldsIdToLabelMap, customUtmsWhitelist });
    const segmentsOptionsFieldsWithTime = [...segmentsOptionsFields, ...segmentsTimeOptions];

    const isSegmentAnalysisLoaded = segmentsAnalysis?.[segmentsAnalysisRequestId]?.status === 'finished';
    const isSegmentAnalysisFailedToLoad = segmentsAnalysis?.[segmentsAnalysisRequestId]?.status === 'failed';
    const isImpactBySegmentLoaded = impactBySegment?.[impactBySegmentRequestId]?.status === 'finished';
    const isImpactBySegmentFailedToLoad = impactBySegment?.[impactBySegmentRequestId]?.status === 'failed';
    const isLoadedUpliftData = upliftBySegments?.[upliftBySegmentsRequestId]?.status === 'finished';

    const impactBySegmentSegmentLabel = segmentsOptionsFieldsWithTime.find((segment) => segment.value === selectedSegmentImpactBySegment)?.label;
    const segmentAnalysisSegmentLabel = segmentsOptionsFieldsWithTime.find((segment) => segment.value === selectedSegmentsParams.firstSegment)?.label;

    return (
      <div>
        <BreadcrumbsTitle breadcrumbs={[pageLabels.analyze, pageLabels.campaigns]} />

        <AnalyzeContext.Consumer>
          {(context) => {
            const {
              campaignKeyToNameMapping,
            } = context;
            return (
              <div>
                <div hidden={!this.state.showPopup}>
                  {!_.isEmpty(campaign) && (
                  <CampaignPopup
                    campaign={{
                      ...campaign,
                    }}
                    channelTitle="Main channel"
                    closePopup={() => this.setState({ showPopup: false })}
                    teamMembers={[]}
                    campaignsTemplates={{}}
                    updateCampaignsTemplates={() => {}}
                    expenses={[]}
                    campaignKeyToNameMapping={campaignKeyToNameMapping}
                    openFromAnalyze
                  />
                  )}
                </div>
              </div>
            );
          }}
        </AnalyzeContext.Consumer>
        <FeatureFlagsWithBlur flag={flags.campaignsImpactBySegmentWidget} name={flagsNames.campaignsImpactBySegmentWidget}>
          <div id="impact-by-frequency">
            <ImpactBySegment
              widgetId={Campaigns.widgetIds.impactBySegment}
              title={`Impact by ${impactBySegmentSegmentLabel}`}
              isLoaded={isImpactBySegmentLoaded}
              isImpactBySegmentFailedToLoad={isImpactBySegmentFailedToLoad}
              showSegmentDropdown={false}
              currentSegmentLabel={impactBySegmentSegmentLabel}
              segmentData={filteredImpactBySegment ?? []}
              segmentPrevData={[]}
              totalBySegment={impactBySegment?.[impactBySegmentRequestId]?.result?.totalBySegment}
              filters={filters}
              totalByFrequency={impactBySegment?.[impactBySegmentRequestId]?.result?.totalByFrequency}
              metricType={impactBySegment?.[impactBySegmentRequestId]?.result?.segmentKey ?? 'attributed'}
              firstSegment={selectedSegmentsParams.firstSegment}
              conversionIndicator={originalFunnelFromIndicator}
              currentFrequency={campaignsImpactFrequency}
              onChangeFrequency={(frequency) => this.updateCampaignsImpactFrequency({ newCampaignsImpactFrequency: frequency.value })}
              relatedEntitiesIndication={impactBySegment?.[impactBySegmentRequestId]?.result?.shouldUseRelatedEntities}
              trendData={trendBySegmentsChartData}
              isLoadedTrendData={isCalculateAdvancedMetrics ? trendBySegments?.[trendBySegmentsKeyName]?.isLoaded : false}
              showNoValueCheckbox={false}
              kpiFocus={this.state.drilldownMetric || conversionIndicator}
              updateFilters={() => this.updateFilters()}
              dataTotals={accountOptimizationMetricsData ?? {}}
              innerPageNavigation={({ filters: filtersForNavigation, isNewFiltersUIOnly }) => navigateBetweenAnalyzeTabs({
                filters: filtersForNavigation, tabName: 'campaigns', withAnimation: false, isNewFiltersUIOnly,
              })}
              hideOtherValuesRows={isOtherCampaignsHidden}
              timeframe={this.props.timeFrame}
              attributionModel={this.props.attributionModel}
              updateDrilldownMetric={({ metric }) => this.updateDrilldownMetric({ metric })}
              growthByFrequency={impactBySegment?.[impactBySegmentRequestId]?.result?.growthByFrequency}
              growthBySegment={impactBySegment?.[impactBySegmentRequestId]?.result?.growthBySegment}
              growthImpactData={impactBySegment?.[impactBySegmentRequestId]?.result?.growthImpactData}
              isCompareToPreviousEnabled={isCompareToPreviousEnabled}
              isCalculateAdvancedMetrics={isCalculateAdvancedMetrics}
              onChangeCheckedSegments={({ segments }) => this.setState({ checkedSegments: segments })}
              onChangeUncheckedSegments={({ segments }) => this.setState({ uncheckedSegments: segments })}
              checkedSegments={this.state.checkedSegments || []}
              uncheckedSegments={this.state.uncheckedSegments || []}
              shouldUseRelativeTimeframe={shouldUseRelativeTimeframe}
              updateShouldUseRelativeTimeframe={() => this.updateShouldUseRelativeTimeframe()}
              onWidgetLoaded={() => this.onWidgetLoaded({ widgetId: Campaigns.widgetIds.impactBySegment, widgetType: widgetTypes.impactBySegment })}
              onWidgetLoading={() => this.onWidgetLoading({ widgetId: Campaigns.widgetIds.impactBySegment, widgetType: widgetTypes.impactBySegment })}
            />
          </div>
        </FeatureFlagsWithBlur>

        <FeatureFlagsWithBlur flag={flags.campaignsAttributionTableWidget} name={flagsNames.campaignsAttributionTableWidget}>
          <div id="attribution-table">
            <AttributionTable
              widgetId={Campaigns.widgetIds.segmentsAnalysis}
              key={`${conversionIndicator}-campaigns-table`}
              defaultStageKey={conversionIndicator}
              title={`${segmentAnalysisSegmentLabel} Impact Analysis`}
              dataNickname={segmentAnalysisSegmentLabel}
              type={ATTRIBUTION_TYPES.CAMPAIGNS}
              includeAttributionStoreFilters
              filters={filters}
              updateFilters={() => this.updateFilters()}
              innerPageNavigation={({ filters: filtersForNavigate, isNewFiltersUIOnly }) => navigateBetweenAnalyzeTabs({
                filters: filtersForNavigate, tabName: 'campaigns', anchor: 'attribution-table', withAnimation: false, isNewFiltersUIOnly,
              })}
              navigateToJourneys={(funnelStage, campaignData) => navigateFromCampaignsToJourneys({ funnelStage, campaignData, includeAttributionStoreFilters: true })}
              exportMode
              isOtherCampaignsHidden={isOtherCampaignsHidden}
              shouldRequestAllFunnelStagesData
              timeframe={this.props.timeFrame}
              attributionModel={this.props.attributionModel}
              data={segmentsAnalysisTableData ?? {}}
              mergedFunnelsTableData={mergedFunnelsSegmentsAnalysisTableData}
              prevData={{}}
              dataTotals={accountOptimizationMetricsData ?? {}}
              trendData={trendBySegmentsData}
              isLoadedTrendData={isCalculateAdvancedMetrics ? trendBySegments?.[trendBySegmentsKeyName]?.isLoaded : false}
              isLoaded={isSegmentAnalysisLoaded}
              segmentsOptionsFields={segmentsOptionsFieldsWithTime}
              updateSegmentsAnalysisParam={(newSelectedSegmentsParams) => this.updateSegmentsAnalysisParam(newSelectedSegmentsParams)}
              segmentsAnalysisParams={selectedSegmentsParams}
              stagesAmount={stagesAmount}
              isSegmentAnalysisFailedToLoad={isSegmentAnalysisFailedToLoad}
              isAttribution={segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.isAttribution}
              showCampaignPopup={(campaignData) => this.setState({ showPopup: true, campaign: campaignData })}
              isCompareToPreviousEnabled={isCompareToPreviousEnabled}
              isCalculateAdvancedMetrics={isCalculateAdvancedMetrics}
              upliftColumnsKeys={this.state.upliftColumnsKeys}
              updatedUpliftColumnsKeys={({ columnsKeys }) => this.updatedUpliftColumnsKeys({ columnsKeys, upliftRequestId: upliftBySegmentsRequestId })}
              upliftData={upliftBySegmentsData}
              isLoadedUpliftData={isLoadedUpliftData}
              onWidgetLoaded={() => this.onWidgetLoaded({ widgetId: Campaigns.widgetIds.segmentsAnalysis, widgetType: widgetTypes.segmentsAnalysis })}
              onWidgetLoading={() => this.onWidgetLoading({ widgetId: Campaigns.widgetIds.segmentsAnalysis, widgetType: widgetTypes.segmentsAnalysis })}
            />
          </div>
        </FeatureFlagsWithBlur>
        <FeatureFlagsWithBlur flag={flags.journeysCampaignsWidget} name={flagsNames.journeysCampaignsWidget}>
          <JourneysCampaigns
            conversionIndicator={conversionIndicator}
            campaignJourneys={campaignJourneys?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.data}
            contentCampaignJourneys={contentCampaignJourneys?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.data}
            isLoaded={isAllWidgetsDataLoaded({
              widgetsData: [campaignJourneys, contentCampaignJourneys],
              isCompareToPreviousEnabled,
            })}
          />
        </FeatureFlagsWithBlur>
      </div>
    );
  }
}

export default withLDConsumer()(enhance(Campaigns));
