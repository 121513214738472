import userStore from 'stores/userStore';
import attributionStore from 'stores/attributionStore';
import analysisStore from 'stores/analysisStore';
import forecastStore from 'stores/forecastStore';
import planStore from 'stores/plan/planStore';
import reportsStore from 'stores/reports/reportsStore';
import filterStore from 'stores/filterStore';
import insightsStore from 'stores/insights/insightsStore';
import analyzeStore from 'stores/analyzeStore';
import widgetsAnalysisStore from 'stores/widgetsAnalysisStore';
import servicesStore from 'stores/servicesStore';
import questionsStore from 'stores/questions/questionsStore';
import secondBrainStore from 'stores/aiAnalysis/secondBrainStore';

const stores = {
  attributionStore,
  userStore,
  analysisStore,
  forecastStore,
  planStore,
  reportsStore,
  filterStore,
  insightsStore,
  analyzeStore,
  widgetsAnalysisStore,
  servicesStore,
  questionsStore,
  secondBrainStore,
};

export default stores;
