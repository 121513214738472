import React, {
  useState,
  useEffect,
} from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { ToastContainer, Slide } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import useStyles from 'hooks/useStyles';
import useServerRequest from 'hooks/useServerRequest';

import InfoCard from 'components/common/InfoCard';
import Skeleton from 'components/common/Skeleton';
import TemplateConfigPopup from 'components/pages/reports/TemplateConfigPopup';
import CreateNewReportPopup from 'components/pages/reports/CreateNewReportPopup';
import FeatureFlags from 'components/common/FeatureFlags';
import servicesStore from 'stores/servicesStore';

import { getTemplateProps } from 'components/pages/reports/logic/NewReport';
import { Events } from 'trackers/analytics/enums';

import appStyle from 'styles/app.css';
import reportLibraryStyle from 'styles/reports/reportLibrary.css';
import pageStyle from 'styles/page.css';

const styles = reportLibraryStyle.locals || {};
const pageStyles = pageStyle.locals || {};

function NewReport({
  region,
  flags,
  location: {
    state: locationState = {},
  } = {},
}) {
  useStyles([appStyle, reportLibraryStyle, pageStyle], [injectStyle]);

  const [isShowCreateNewReportPopup, setIsShowCreateNewReportPopup] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isRequestingTemplates, setIsRequestingTemplates] = useState(false);

  useEffect(() => {
    const properties = {
      source: 'New report',
    };

    if (locationState.isNoReports) {
      properties.source = 'No reports';
    }

    if (locationState.isOnboarding) {
      properties.source = 'Onboarding';
    }

    servicesStore.eventTracker.track({
      eventName: Events.reports.newReportPageVisit,
      properties,
    });
  }, []);

  const templatesData = useServerRequest({
    route: 'reports/templates',
    method: 'get',
    region,
    onRequestEnd: () => setIsRequestingTemplates(false),
    onRequestStart: () => setIsRequestingTemplates(true),
    processResponse: (rawTemplatesData) => getTemplateProps({ rawTemplatesData }),
  });

  return (
    <div>
      <div className={classNames(pageStyles.container, pageStyles.analyzeContainer)}>
        <div className={classNames(
          styles.section,
          styles.scratchSection
        )}
        >
          <div
            onClick={() => {
              servicesStore.eventTracker.track({
                eventName: Events.reports.startFromScratch,
              });
              setIsShowCreateNewReportPopup(true);
            }}
            className={styles.createFromScratchButton}
          >
            + Start from scratch
          </div>
        </div>

        <FeatureFlags flag={flags.createNewReportsShowTemplates}>
          <div className={classNames(
            styles.section,
            styles.templatesSection
          )}
          >
            <div className={styles.sectionHeader}>
              <div className={styles.sectionTitle}>
                Or, select a template
              </div>
            </div>

            <div className={styles.templatesContainer}>
              {isRequestingTemplates ? (
                <>
                  {[...Array(6)].map((_, i) => {
                    const skeletonKey = `infoCard_skeleton_${i}`;

                    return (
                      <Skeleton
                        key={skeletonKey}
                        height={184}
                        borderRadius={12}
                        enableAnimation
                        data-testid="skeleton-item"
                      />
                    );
                  })}
                </>
              ) : (
                templatesData?.cards.length > 0 ? (
                  templatesData.cards.map((cardProps) => {
                    const {
                      name,
                      title,
                      dataIcon,
                      description,
                    } = cardProps;

                    return (
                      <InfoCard
                        key={name}
                        title={title}
                        onClick={() => {
                          servicesStore.eventTracker.track({
                            eventName: Events.reports.templateSelected,
                            properties: {
                              template: title,
                            },
                          });
                          setSelectedTemplate(name);
                        }}
                        subTitle={description}
                        iconProps={{
                          dataIcon: `reportTemplate:${dataIcon || 'fallback'}`,
                        }}
                      />
                    );
                  })
                ) : null
              )}

            </div>

          </div>
        </FeatureFlags>

        {isShowCreateNewReportPopup ? (
          <CreateNewReportPopup
            title="Create new report"
            actionText="Create report"
            onClosePopup={() => setIsShowCreateNewReportPopup(false)}
            folderId={locationState?.originFolderId}
          />
        ) : null}

        {selectedTemplate ? (
          <TemplateConfigPopup
            onClosePopup={() => setSelectedTemplate(null)}
            templateProps={templatesData?.configMapping?.[selectedTemplate]}
            folderId={locationState?.originFolderId}
          />
        ) : null}
      </div>

      <ToastContainer
        limit={1}
        style={{ width: '400px', fontSize: '16px' }}
        position="bottom-center"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        transition={Slide}
      />
    </div>
  );
}

export default withLDConsumer()(inject(({
  userStore: {
    userMonthPlan: {
      region,
    } = {},
  },
}) => ({
  region,
}), observer)(NewReport));
