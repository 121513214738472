import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';

import useOnClickOutside from 'hooks/useOnClickOutside';

import servicesStore from 'stores/servicesStore';

import MultiCheckSelect from 'components/controls/MultiCheckSelect';
import Switch from 'components/controls/Switch';
import Textfield from 'components/controls/Textfield';
import Dropdown from 'components/controls/Dropdown';
import ChannelIcon from 'components/common/ChannelIcon';
import SortByColumn from 'components/controls/SortByColumn';
import InfoMarker from 'components/pages/InfoMarker';

import { COMPARISON_OPERATORS } from 'components/utils/filters';
import { getIndicatorDisplaySign } from 'components/utils/indicators';
import { getCategoryIcon, getChannelIcon } from 'components/utils/filters/channels';
import { contentTypeSegmentValue } from 'components/pages/analyze/SegmentsTab/logic/enums';
import { inlineFiltersKind } from 'components/pages/analyze/AttribuitonTable/enums';
import { getOptionsByOffset, getUpliftSwitchTooltip } from 'components/pages/analyze/AttribuitonTable/logic/inlineColumnFilters';
import { getColumnMetricNickName } from 'components/pages/analyze/AttribuitonTable/logic/AttributionTable';
import { Events } from 'trackers/analytics/enums';
import { upliftLabel } from 'components/common/logic/enums';

import useStyles from 'hooks/useStyles';

import style from 'styles/analyze/attributionTable/inlineColumnFilters.css';

const styles = style.locals || {};

function InlineColumnFilters({
  columnKey,
  columnLabel = '',
  selectedSegments,
  sortByColumn,
  setSortByColumn,
  isNumeric,
  updateExpandColumnsKeys,
  isExpendOpen,
  filtersByColumn,
  updateFiltersByColumn,
  columnFiltersOptions,
  isLastColumn,
  isShowSortBy = true,
  updateUpliftColumns,
  upliftColumnsKeys = [],
}) {
  useStyles([style]);

  const columnHandlerRef = useRef(null);

  const currentFiltersByColumn = filtersByColumn[columnKey]?.values;

  const [isShowPopup, setIsShowPopup] = useState(false);
  const [numericFilter, setNumericFilter] = useState({});
  const [multiCheckFilter, setMultiCheckFilter] = useState([]);

  useEffect(() => {
    if (isNumeric) {
      setNumericFilter({ operator: currentFiltersByColumn?.operator, value: currentFiltersByColumn?.value || '' });
    } else {
      setMultiCheckFilter(currentFiltersByColumn || []);
    }
  }, [currentFiltersByColumn]);

  useOnClickOutside(columnHandlerRef, () => {
    if (currentFiltersByColumn?.value === '') {
      onRemoveColumnFilter();
    }
    setIsShowPopup(false);
  });

  const isShowExpandButton = !!updateExpandColumnsKeys;
  const isSortActive = sortByColumn.id === columnKey;
  const isSortArrowUp = isNumeric ? !sortByColumn.desc : sortByColumn.desc;

  function onRemoveColumnFilter() {
    const newFiltersByColumn = { ...filtersByColumn };
    delete newFiltersByColumn[columnKey];
    setNumericFilter({ operator: null, value: '' });
    setMultiCheckFilter([]);
    updateFiltersByColumn({ filters: newFiltersByColumn });
  }

  function onUpdateFiltersByColumn({ values, kind }) {
    const newFiltersByColumn = { ...filtersByColumn };
    newFiltersByColumn[columnKey] = { values, kind, columnLabel };
    updateFiltersByColumn({ filters: newFiltersByColumn });

    servicesStore.eventTracker.track({
      eventName: Events.inlineFilters.appliedFilterInSegmentsAnalysisTable,
      properties: {
        column: columnKey,
        isNumericColumn: kind === inlineFiltersKind.numeric,
        isUpliftColumn: !!updateUpliftColumns,
        isExpendOpen,
      },
    });
  }

  function onUpdateNumericFilter({ filter }) {
    setNumericFilter(filter);

    if (filter.operator) {
      onUpdateFiltersByColumn({ values: filter, kind: inlineFiltersKind.numeric });
    }
  }

  function onChangeMultiCheckFilter({ values }) {
    setMultiCheckFilter(values);
    if (values.length === 0) {
      onRemoveColumnFilter();
    } else {
      onUpdateFiltersByColumn({ values, kind: inlineFiltersKind.multiCheck });
    }
  }

  function showInlineFilters() {
    if (!isShowPopup) {
      servicesStore.eventTracker.track({
        eventName: Events.inlineFilters.clickedViewInlineFilters,
        properties: {
          column: columnKey,
        },
      });

      setIsShowPopup(true);
    }
  }

  const currentSegment = selectedSegments[columnKey]?.value;
  const isChannelColumn = columnKey === 'channels' || currentSegment === 'channel' || selectedSegments[columnKey]?.value === contentTypeSegmentValue;
  const isCategoryColumn = currentSegment === 'category';
  const columnTitle = getColumnMetricNickName({ metric: columnKey });

  return (
    <div
      onClick={() => showInlineFilters()}
      ref={columnHandlerRef}
      className={isLastColumn ? styles.lastColumn : null}
      data-testid="filter-icon-wrapper"
    >
      <div className={classNames(styles.wrapper, isShowPopup && styles.activeColor)}>
        <div data-testid={currentFiltersByColumn ? 'filter-icon-active' : null} className={classNames(styles.filterColumnIcon, currentFiltersByColumn && styles.filterColumnIconActive)} />
        {isSortActive ? (
          <div data-testid="sort-arrow-active" className={classNames(styles.sortArrow, isSortArrowUp && styles.sortArrowDesc)} />
        ) : null}
      </div>

      {isShowPopup ? (
        <div
          className={classNames(styles.filterPopup, isNumeric && styles.numericFilterPopup)}
          data-testid={`inline-filters-${isNumeric ? 'numeric' : 'multiCheck'}`}
        >
          {isShowExpandButton ? (
            <div className={styles.expandSwitch}>
              Expand metric
              <Switch
                onSwitchClick={() => updateExpandColumnsKeys(columnKey)}
                isActive={isExpendOpen}
                dataTestId="expand-metric"
                style={{ marginLeft: 'auto' }}
              />
            </div>
          ) : null}

          {updateUpliftColumns ? (
            <div className={styles.expandSwitch}>
              {'Uplift '}
              <InfoMarker
                TooltipProps={{ className: styles.upliftTooltip }}
                tooltipText={getUpliftSwitchTooltip({ metric: columnTitle })}
              />
              <Switch
                onSwitchClick={() => updateUpliftColumns(columnKey)}
                isActive={upliftColumnsKeys.includes(columnKey.replace(upliftLabel, ''))}
                dataTestId="expand-metric"
                style={{ marginLeft: 'auto' }}
              />
            </div>
          ) : null}

          {isShowSortBy ? (
            <SortByColumn
              columnKey={columnKey}
              setSortByColumn={setSortByColumn}
              isSortActive={isSortActive}
              isSortArrowUp={isSortArrowUp}
              isNumeric={isNumeric}
            />
          ) : null}
          {updateFiltersByColumn ? (
            <div>
              {currentFiltersByColumn ? (
                <div data-testid="remove-filter" onClick={() => onRemoveColumnFilter()} className={styles.removeColumnFilter}>
                  Remove filter
                </div>
              ) : null}

              {isNumeric ? (
                <>
                  <div className={styles.filterByConditionTitle}>
                    Filter by condition
                  </div>
                  <div className={styles.filterByConditionCollapse}>
                    <Dropdown
                      onChange={(value) => onUpdateNumericFilter({ filter: { ...numericFilter, operator: value } })}
                      options={Object.entries(COMPARISON_OPERATORS).map(([value, label]) => ({ value, label }))}
                      selectedKey={numericFilter.operator?.value}
                      controlWidth={150}
                    />
                    <Textfield
                      type="number"
                      onChange={(value) => onUpdateNumericFilter({ filter: { ...numericFilter, value: value.target.value } })}
                      value={numericFilter.value}
                      dataTestId="numeric-filter-input"
                    />
                    <div>
                      {getIndicatorDisplaySign(columnKey)}
                    </div>
                  </div>
                </>
              ) : (
                <MultiCheckSelect
                  key={`inline-filters-multiCheckSelect-${columnKey}`}
                  className={styles.multiOptionSelect}
                  maxHeight={240}
                  onChange={(values) => onChangeMultiCheckFilter({ values })}
                  selectAll
                  selected={multiCheckFilter}
                  controlHeight={36}
                  isAsyncPagination
                  overrideLoadOptions={(searchValue, prevOptions) => getOptionsByOffset({ searchValue, prevOptions, options: columnFiltersOptions })}
                  allOptions={columnFiltersOptions}
                  debounceTimeout={0}
                  optionRenderer={(isChannelColumn || isCategoryColumn) ? (item) => (
                    <div className={styles.optionWithIcon}>
                      <ChannelIcon
                        className={isCategoryColumn ? styles.categoryIcon : null}
                        channelIcon={isChannelColumn ? getChannelIcon(item.value) : getCategoryIcon(item.value)}
                        channel={item.value}
                      />
                      <div className={styles.optionWithIconLabel}>
                        {item.label}
                      </div>
                    </div>
                  ) : null}
                />
              )}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default InlineColumnFilters;
