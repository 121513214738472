import React from 'react';
import classnames from 'classnames';
import userStore from 'stores/userStore';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';
import Page from 'components/Page';
import Label from 'components/ControlsLabel';
import Textfield from 'components/controls/Textfield';
import Toggle from 'components/controls/Toggle';
import OnboardingAddPreset from 'components/pages/account/OnboardingAddPreset';
import Switch from 'components/controls/Switch';
import InfoMarker from 'components/pages/InfoMarker';
import Spinner from 'components/pages/journeys/Spinner';

import { customizeOnboardingTitle, customizeOnboardingTooltip, inviteMemberSteps } from 'components/pages/account/enum';
import { getPermissionsTooltip } from 'components/pages/logic/welcome';
import { roles } from 'enums';

import style from 'styles/onboarding/onboarding.css';
import popupStyle from 'styles/welcome/add-member-popup.css';

export default class AddMemberPopup extends Component {
  style = style;

  styles = [popupStyle];

  getInitialState({ selectedMember }) {
    let roleName = roles.admin;
    if (selectedMember) {
      roleName = selectedMember.roleName ?? (selectedMember.isAdmin ? roles.admin : roles.viewer);
    }

    return ({
      firstName: selectedMember?.firstName || '',
      lastName: selectedMember?.lastName || '',
      email: selectedMember?.email || '',
      jobTitle: selectedMember?.roleData?.jobTitle || '',
      roleName,
      isOnboarding: !!selectedMember?.onboardingPresetConfigId,
      specificChannels: selectedMember?.specificChannels || [],
      isSpecificChannels: selectedMember ? selectedMember?.isSpecificChannels : false,
      emailError: '',
      emailErrorMessage: '',
      progressCurrentStep: inviteMemberSteps.basicDetails,
      selectedPreset: selectedMember?.onboardingPresetConfigId || null,
      isShowGettingStartedPage: selectedMember?.isShowGettingStartedPage || true,
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState({ selectedMember: props.selectedMember }),
      isPresetsLoading: false,
      presetOptions: [],
    };
    this.handleChangeChannels = this.handleChangeChannels.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
  }

  componentDidMount() {
    this.getAccountOnboardingPreset();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedMember !== this.props.selectedMember) {
      this.setState({ ...this.getInitialState({ selectedMember: this.props.selectedMember }) });
    }
  }

  getAccountOnboardingPreset() {
    const onboardingPresetConfigs = userStore.userAccount?.onboardingPresetConfigs || {};
    if (onboardingPresetConfigs) {
      const userPresetOptions = [];
      for (const [presetId, presetConfig] of Object.entries(onboardingPresetConfigs)) {
        userPresetOptions.push({ label: presetConfig.name, value: presetId });
      }
      this.setState({ presetOptions: userPresetOptions });
    }
  }

  handleChangeChannels(event) {
    const update = event.map((obj) => obj.value);
    this.setState({ specificChannels: update });
  }

  async inviteOrUpdatedMember() {
    this.setState({ isPresetsLoading: true });

    await this.props.onInviteOrUpdatedMember({
      member: {
        ...this.props.selectedMember,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        roleName: this.state.roleName,
        specificChannels: this.state.specificChannels,
        isSpecificChannels: this.state.isSpecificChannels,
        onboardingPresetConfigId: this.state.isOnboarding ? this.state.selectedPreset : null,
        isShowGettingStartedPage: this.state.isOnboarding ? this.state.isShowGettingStartedPage : false,
        isSSO: this.props.isSSO,
      },
      isNewMember: !this.props.selectedMember,
    });
    this.setState({ isPresetsLoading: false });
  }

  handleEmailChange(event) {
    const enteredEmail = event.target.value;
    let emailErrorMessage = '';
    const isEmailExists = this.props.teamMembers.some(
      (member) => member.email === enteredEmail.trim().toLowerCase()
    );
    if (isEmailExists) {
      emailErrorMessage = 'This email is already signed up to InfiniGrow.';
    }

    this.setState({
      email: enteredEmail,
      emailError: isEmailExists,
      emailErrorMessage,
    });
  }

  isInviteButtonDisabled() {
    const {
      firstName, lastName, email, emailError,
    } = this.state;
    return !firstName || !lastName || !email || emailError;
  }

  renderBasicDetails() {
    const isEditMode = this.props.selectedMember;

    return (
      <>
        <div className={this.classes.row}>
          <Label>First Name</Label>
          <Textfield
            value={this.state.firstName}
            onChange={(event) => {
              this.setState({ firstName: event.target.value });
            }}
          />
        </div>
        <div className={this.classes.row}>
          <Label>Last Name</Label>
          <Textfield
            value={this.state.lastName}
            onChange={(event) => {
              this.setState({ lastName: event.target.value });
            }}
          />
        </div>
        <div className={this.classes.row}>
          <Label>Email</Label>
          <Textfield
            value={this.state.email}
            onChange={this.handleEmailChange}
            isShowError={this.state.emailError}
            inputErrorMessage={this.state.emailErrorMessage}
            disabled={isEditMode}
          />
        </div>
        { isEditMode && this.state.jobTitle
          ? (
            <div className={this.classes.row}>
              <Label>Job Title</Label>
              <Textfield
                value={this.state.jobTitle}
                disabled
              />
            </div>
          )
          : null}
        <div className={this.classes.row} style={{ display: 'inline-block' }}>
          <Label>
            <div className={popupStyle.locals.titleContainer}>
              Permissions
              <InfoMarker tooltipText={getPermissionsTooltip()} place="right" />
            </div>
          </Label>
          <Toggle
            options={[
              {
                text: 'Admin',
                value: roles.admin,
              },
              {
                text: 'Editor',
                value: roles.user,
              },
              {
                text: 'Viewer',
                value: roles.viewer,
              },
            ]}
            selectedValue={this.state.roleName}
            onClick={(value) => {
              this.setState({ roleName: value });
            }}
          />
        </div>
        <div className={this.classes.row}>
          <Switch
            onSwitchClick={() => this.setState((prevState) => ({ isOnboarding: !prevState.isOnboarding }))}
            isActive={this.state.isOnboarding}
            style={{
              flexDirection: 'row-reverse', justifyContent: 'flex-end', gap: '8px', marginBottom: '10px',
            }}
            title={(
              <div className={classnames(popupStyle.locals.switchTitle, popupStyle.locals.titleContainer)}>
                {customizeOnboardingTitle}
                <InfoMarker tooltipText={customizeOnboardingTooltip} />
              </div>
            )}
          />
        </div>
        <div className={this.classes.footerButtons}>
          <Button
            type="secondaryBlue"
            onClick={() => this.props.close()}
          >
            Cancel
          </Button>
          {this.state.isPresetsLoading
            ? <Spinner />
            : (
              <Button
                type="primaryBlue"
                disabled={this.isInviteButtonDisabled()}
                onClick={() => (this.state.isOnboarding
                  ? this.setState({ progressCurrentStep: inviteMemberSteps.customizeOnboarding })
                  : this.inviteOrUpdatedMember())}
              >
                {this.state.isOnboarding ? 'Next' : 'Save'}
              </Button>
            )}
        </div>
      </>
    );
  }

  render() {
    const isBasicDetailsStep = this.state.progressCurrentStep === inviteMemberSteps.basicDetails;
    const isCustomizeOnboardingStep = this.state.progressCurrentStep === inviteMemberSteps.customizeOnboarding;
    const isEditMode = this.props.selectedMember;

    return (
      <Page
        popup
        width="600px"
        contentClassName={popupStyle.locals.content}
        innerClassName={popupStyle.locals.inner}
      >
        <div className={popupStyle.locals.title}>
          {isEditMode ? 'Update User ' : 'Invite User '}
          and Customize Onboarding
        </div>

        <div className={this.classes.progressSteps}>
          <div className={classnames(
            this.classes.stepItem,
            this.classes.stepItemLine,
            isBasicDetailsStep && this.classes.stepActive
          )}
          >
            <div className={this.classes.stepCounter} />
            <div>Basic Details</div>
          </div>
          <div className={classnames(
            this.classes.stepItem,
            isCustomizeOnboardingStep && this.classes.stepActive
          )}
          >
            <div className={this.classes.stepCounter} />
            <div>Customize Onboarding</div>
          </div>
        </div>

        {isBasicDetailsStep ? (
          this.renderBasicDetails()
        ) : null}

        {isCustomizeOnboardingStep ? (
          <OnboardingAddPreset
            selectedPreset={this.state.selectedPreset}
            setSelectedPreset={(selectedPreset) => this.setState({ selectedPreset })}
            onGoBack={() => this.setState({ progressCurrentStep: inviteMemberSteps.basicDetails })}
            inviteOrUpdatedMember={() => this.inviteOrUpdatedMember()}
            isEditMode={isEditMode}
            isPresetsLoading={this.state.isPresetsLoading}
            presetOptions={this.state.presetOptions}
            setPresetOptions={(presetOptions) => this.setState({ presetOptions })}
          />
        ) : null}
      </Page>
    );
  }
}
