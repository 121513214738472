import moment from 'moment';
import { cloneDeep, isEmpty } from 'lodash';

import userStore from 'stores/userStore';

import { getNickname } from 'components/utils/channels';
import { VARIANTS, filterKinds, filterKindsToBeforeStages } from 'components/utils/filters/logic';
import { getFilterLabel, makeRawFilters, isFilterInList } from 'components/utils/filters';
import { columnTypesForExport, contentTypeSegmentValue } from 'components/pages/analyze/SegmentsTab/logic/enums';
import { isContentSegment } from 'components/pages/analyze/OverviewTab/logic';
import { FREQUENCY_OPTIONS } from 'components/utils/frequency';
import { getSegmentLabel, parseSegmentValue } from 'components/pages/analyze/SegmentsTab/logic/segments';

export function convertShowOnlyFilters({
  filters, userFunnels, conversionIndicator, filtersData,
}) {
  const updatedFilters = cloneDeep(filters);

  for (const filter of updatedFilters) {
    if (!filter.isUiOnly) {
      continue;
    }

    const copyFilter = cloneDeep(filter);
    copyFilter.isUiOnly = false;
    copyFilter.data.variant = VARIANTS.INCLUDE_ANY_OF;
    copyFilter.data.selectedFunnels = userFunnels;
    copyFilter.data.selectedFunnel = conversionIndicator;
    copyFilter.data.relatedEntities = filter.kind === filterKinds.CUSTOM_FIELDS;

    if (copyFilter.label) {
      const config = filtersData.find((c) => c.kind === copyFilter.kind);
      copyFilter.label = getFilterLabel({
        data: copyFilter.data, filterKind: copyFilter.kind, filterFieldNames: config.fieldNames, filterFieldKey: config.fieldKey,
      });
    }

    updatedFilters.push(copyFilter);

    const beforeConversionKind = filterKindsToBeforeStages[filter.kind];
    if (beforeConversionKind) {
      const beforeConversionFilter = cloneDeep(copyFilter);
      beforeConversionFilter.kind = beforeConversionKind;
      const config = filtersData.find((c) => c.kind === copyFilter.kind);
      beforeConversionFilter.label = getFilterLabel({
        data: beforeConversionFilter.data, filterKind: beforeConversionFilter.kind, filterFieldNames: config?.fieldNames, filterFieldKey: config?.fieldKey,
      });

      updatedFilters.push(beforeConversionFilter);
    }
  }

  return updatedFilters;
}

export function getImpactByFieldsToEnrich({ secondSegment, extraFields = [] }) {
  const fieldsToEnrich = new Set(extraFields);
  if (secondSegment) {
    fieldsToEnrich.add(secondSegment);
  }

  return Array.from(fieldsToEnrich);
}

export function getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams }) {
  if (!selectedSegmentsParams) {
    return [];
  }

  const { firstSegment, secondSegment } = selectedSegmentsParams;
  const fieldsToEnrich = [];
  const isAddChannel = firstSegment === 'campaign';
  if (isAddChannel) {
    fieldsToEnrich.push('channel');
  }

  const isAddContentChannel = isContentSegment({ segment: firstSegment });
  if (isAddContentChannel) {
    fieldsToEnrich.push(contentTypeSegmentValue);
  }

  return getImpactByFieldsToEnrich({ secondSegment, extraFields: fieldsToEnrich });
}

export function getShouldConvertShowOnlyFilter({
  filters,
  userFunnels,
  conversionIndicator,
  filtersData,
}) {
  if (!filters) {
    return false;
  }

  const uiOnlyFilters = filters.filter((filterItem) => filterItem.isUiOnly);
  if (uiOnlyFilters.length === 0) {
    return false;
  }

  const rawUiOnlyFilters = makeRawFilters({ filters: uiOnlyFilters });
  const convertedFilters = convertShowOnlyFilters({
    filters: rawUiOnlyFilters,
    userFunnels,
    conversionIndicator,
    filtersData,
  });
  const isAlreadyConverted = convertedFilters.every((filterItem) => isFilterInList({
    filterItem,
    filtersList: filters,
  }));

  return !isAlreadyConverted;
}

export function getImpactBySegmentEventData({ widgetConfig }) {
  if (isEmpty(widgetConfig?.impactBySegmentParams)) {
    return {};
  }

  const selectedColumns = widgetConfig.impactBySegmentParams.selectedColumns;
  const timeFrame = `${moment(widgetConfig.timeFrame.startDate).toISOString()} - ${moment(widgetConfig.timeFrame.endDate).toISOString()}`;

  return {
    widget: widgetConfig.type,
    segmentType: widgetConfig.impactBySegmentParams.firstSegment,
    primaryDimension: selectedColumns.find((column) => column.key === columnTypesForExport.segment)?.label,
    secondaryDimension: null,
    metric: selectedColumns.find((column) => column.key === columnTypesForExport.metric)?.label,
    frequency: selectedColumns.find((column) => column.key === columnTypesForExport.frequency)?.label,
    timeFrame,
    isToDate: widgetConfig.impactBySegmentParams.shouldUseRelativeTimeframe,
  };
}

export function filterImpactBySegmentRowsWithoutData({ data }) {
  const impactData = data?.impactData || [];
  const totalBySegment = data?.totalBySegment || {};
  const segmentNamesToFilter = [];
  for (const [segmentName, segmentCount] of Object.entries(totalBySegment)) {
    if (segmentCount === 0) {
      segmentNamesToFilter.push(segmentName);
    }
  }

  const filteredObj = [];
  for (const impactBarData of impactData) {
    const filteredImpactData = {};
    for (const [segmentName, segmentCount] of Object.entries(impactBarData)) {
      if (!segmentNamesToFilter.includes(segmentName)) {
        filteredImpactData[segmentName] = segmentCount;
      }
    }
    filteredObj.push(filteredImpactData);
  }

  return filteredObj;
}

export function parseImpactBySegmentDataForExport({ data: impactData, selectedColumns, widgetConfig }) {
  const selectedColumnsAsValues = Object.values(selectedColumns);
  const headers = selectedColumnsAsValues.map((column) => column.label);

  const segmentType = widgetConfig.impactBySegmentParams.firstSegment;
  const parsedData = [];
  for (const impactBarData of impactData) {
    for (const [segmentName, segmentCount] of Object.entries(impactBarData)) {
      if (segmentName === 'name') {
        continue;
      }

      const row = [];
      const segmentNickname = segmentType === 'channel' ? getNickname(segmentName) : segmentName;
      row.push(impactBarData.name);
      row.push(segmentNickname);
      row.push(segmentCount);
      parsedData.push(row);
    }
  }

  const sortedRowsBySegment = parsedData.sort((rowA, rowB) => {
    const rowASegment = rowA[1];
    const rowBSegment = rowB[1];
    if (rowASegment < rowBSegment) { return -1; }
    if (rowASegment > rowBSegment) { return 1; }
    return 0;
  });

  sortedRowsBySegment.unshift(headers);
  return sortedRowsBySegment;
}

export function getImpactBySegmentExportColumns({ frequency, segment, kpiFocus }) {
  return ([
    {
      key: columnTypesForExport.frequency,
      label: Object.values(FREQUENCY_OPTIONS).find(
        (option) => option.value === frequency
      )?.label,
    },
    {
      key: columnTypesForExport.segment,
      label: segment,
    },
    {
      key: columnTypesForExport.metric,
      label: kpiFocus,
    },
  ]);
}

export function createWidgetDataForAI({
  data,
  kpiFocus,
  segmentType,
  customFieldsIdToLabelMap,
  customUtmsWhitelist,
}) {
  const filteredData = filterImpactBySegmentRowsWithoutData({ data });
  const kpiFocusLabel = userStore.getMetricNickname({ metric: kpiFocus });
  const segmentLabel = getSegmentLabel({ segment: segmentType, customFieldsIdToLabelMap, customUtmsWhitelist });

  const impactBySegmentData = {};
  for (const impactBarData of filteredData) {
    for (const [segmentName, segmentCount] of Object.entries(impactBarData)) {
      if (segmentName === 'name') {
        continue;
      }

      if (!impactBySegmentData[segmentName]) {
        impactBySegmentData[segmentName] = {};
      }

      impactBySegmentData[segmentName][impactBarData.name] = parseSegmentValue({
        metric: kpiFocus,
        segmentValue: segmentCount,
        shouldFormatCost: true,
        shouldFormatVelocity: true,
        shouldFormatCR: true,
        shouldFormatROI: true,
      });
    }
  }

  const parsedData = [];
  for (const [segmentName, segmentData] of Object.entries(impactBySegmentData)) {
    parsedData.push({
      [segmentLabel]: segmentType === 'channel' ? getNickname(segmentName) : segmentName,
      [kpiFocusLabel]: segmentData,
    });
  }

  return parsedData;
}

export function getWidgetTitle({ title, kpiFocus }) {
  const kpiFocusLabel = userStore.getMetricNickname({ metric: kpiFocus });
  return `${title} On ${kpiFocusLabel}`;
}
