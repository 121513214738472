import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import userStore from 'stores/userStore';
import Component from 'components/Component';
import Label from 'components/ControlsLabel';
import Textfield from 'components/controls/Textfield';
import SaveButton from 'components/pages/profile/SaveButton';
import GeneralPopup from 'components/common/GeneralPopup';

import style from 'styles/onboarding/onboarding.css';

export default class Setup extends Component {
  style = style;

  static lookup = {
    blog: 'content_contentCreation_companyBlog',
    caseStudies: 'content_contentCreation_caseStudies',
    presentations: 'content_contentCreation_presentations',
    eBooks: 'content_contentCreation_eBook',
    whitepapers: 'content_contentCreation_researchPaper',
    videos: 'content_contentCreation_videos',
    landingPages: 'web_landingPages',
  };

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    userStore.getAttributionMappingRules();
  }

  handleChange = (type) => (event, newVal) => {
    const update = this.props.siteStructure || {};
    update[type] = event ? event.target.value : newVal;
    this.props.updateState({ siteStructure: update });
  };

  makeMappingRule = (channel, url) => ({
    channel,
    conditions: [{
      operation: 'contains',
      param: 'url',
      value: url,
    }],
    id: uuidv4(),
    weight: 1,
  });

  saveMappingRules = async (siteStructure) => {
    const { attributionMappingRules = [] } = this.props;
    const newAttributionMappingRules = [];
    Object.keys(siteStructure).forEach((siteKey) => {
      const url = siteStructure[siteKey];
      if (url) {
        const channelForMapping = Setup.lookup[siteKey];
        if (channelForMapping) {
          newAttributionMappingRules.push(this.makeMappingRule(channelForMapping, url));
        }
      }
    });

    const deletedRules = attributionMappingRules.filter((rule) => {
      const isStructureRule = Object.values(Setup.lookup).includes(rule.channel)
       && rule.conditions.every((cond) => cond.param === 'url' && cond.operation === 'contains');
      return isStructureRule;
    });

    const deletedRuleIds = deletedRules.map((rule) => rule.id);

    await userStore.updateAttributionMappingRulesRequest({ newRules: newAttributionMappingRules, deletedRuleIds });
  };

  onSubmit = () => {
    let { siteStructure } = this.props;
    siteStructure = siteStructure || {};
    this.setState({ saveFail: false, saveSuccess: false });
    this.props.updateUserMonthPlan({ siteStructure }, this.props.region)
      .then(() => {
        this.setState({ saveSuccess: true });
      });
    this.saveMappingRules(siteStructure);
  };

  openPopup = () => this.popup.open();

  closePopup = () => this.popup.close();

  render() {
    let { siteStructure } = this.props;
    const { companyWebsite = '' } = this.props;
    const domain = companyWebsite.replace(/(^\w+:|^)\/\//, '');

    siteStructure = siteStructure || {};
    const {
      homepage, pricing, blog, caseStudies, contact, aboutUs,
      presentations, eBooks, whitepapers, videos, landingPages,
    } = siteStructure;
    const labelStyle = { width: '110px', marginTop: '12px', textTransform: 'capitalize' };

    const mainDomain = domain || 'site.com';
    const buttonProps = [{
      title: `https://blog.${mainDomain}`,
      onClick: () => {},
    }, {
      title: `https://${mainDomain}blog/`,
      onClick: () => {},
    }];
    return (
      <div>
        <div className={this.classes.cell}>
          <Label style={labelStyle}>Homepage</Label>
          <Textfield
            value={homepage}
            onChange={this.handleChange('homepage')}
            style={{ width: '300px' }}
          />
        </div>
        <div className={this.classes.cell}>
          <Label style={labelStyle}>Pricing</Label>
          <Textfield
            value={pricing}
            onChange={this.handleChange('pricing')}
            style={{ width: '300px' }}
          />
        </div>
        <div className={this.classes.cell}>
          <div style={{ position: 'relative', display: 'flex' }}>
            <Label style={labelStyle}>Blog</Label>
            <Textfield
              value={blog}
              onChange={(e) => {
                this.handleChange('blog')(e);
                this.closePopup();
              }}
              style={{ width: '300px' }}
              onFocus={this.openPopup}
            />
            <GeneralPopup
              ref={(el) => {
                this.popup = el;
              }}
              popupClass={this.classes.popup}
            >
              <div style={{ position: 'relative' }}>
                <div className={this.classes.iconAbs}>
                  <div
                    className={this.classes.closeIcon}
                    onClick={this.closePopup}
                  />
                </div>
                <div className={this.classes.popupHeader}>
                  Choose your structure
                </div>
                {buttonProps.map(({ title }) => (
                  <div
                    key={title}
                    className={this.classes.popupButton}
                    onClick={() => {
                      this.handleChange('blog')(null, title);
                      this.closePopup();
                    }}
                  >
                    {title}
                  </div>
                ))}
              </div>
            </GeneralPopup>
          </div>
        </div>
        <div className={this.classes.cell}>
          <Label style={labelStyle}>Case-studies</Label>
          <Textfield
            value={caseStudies}
            onChange={this.handleChange('caseStudies')}
            style={{ width: '300px' }}
          />
        </div>
        <div className={this.classes.cell}>
          <Label style={labelStyle}>Contact us</Label>
          <Textfield
            value={contact}
            onChange={this.handleChange('contact')}
            style={{ width: '300px' }}
          />
        </div>
        <div className={this.classes.cell}>
          <Label style={labelStyle}>About us</Label>
          <Textfield
            value={aboutUs}
            onChange={this.handleChange('aboutUs')}
            style={{ width: '300px' }}
          />
        </div>
        <div className={this.classes.cell}>
          <Label style={labelStyle}>Presentations</Label>
          <Textfield
            value={presentations}
            onChange={this.handleChange('presentations')}
            style={{ width: '300px' }}
          />
        </div>
        <div className={this.classes.cell}>
          <Label style={labelStyle}>E-books</Label>
          <Textfield
            value={eBooks}
            onChange={this.handleChange('eBooks')}
            style={{ width: '300px' }}
          />
        </div>
        <div className={this.classes.cell}>
          <Label style={labelStyle}>Whitepapers</Label>
          <Textfield
            value={whitepapers}
            onChange={this.handleChange('whitepapers')}
            style={{ width: '300px' }}
          />
        </div>
        <div className={this.classes.cell}>
          <Label style={labelStyle}>Videos</Label>
          <Textfield
            value={videos}
            onChange={this.handleChange('videos')}
            style={{ width: '300px' }}
          />
        </div>
        <div className={this.classes.cell}>
          <Label style={labelStyle}>Landing Pages</Label>
          <Textfield
            value={landingPages}
            onChange={this.handleChange('landingPages')}
            style={{ width: '300px' }}
          />
        </div>
        <div style={{ marginLeft: '272px', marginTop: '12px' }}>
          <SaveButton
            onClick={this.onSubmit}
            success={this.state.saveSuccess}
            fail={this.state.saveFail}
          />
        </div>
      </div>
    );
  }
}
