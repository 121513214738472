export const Events = {
  global: {
    widgetError: 'Widget failed to load',
  },
  addToReport: 'addToReport',
  questionSearched: 'questionSearched',
  questionAsked: 'questionAsked',
  widgetCreated: 'Created a widget',
  widgetExported: 'Exported data from a widget',
  customSegmentNewSegmentClicked: 'Clicked on Add New Segment (Custom Segments)',
  customSegmentNewBucketCreated: 'Created a segment bucket (Custom Segments)',
  customSegmentSaved: 'Saved custom segment (Custom Segments)',
  reportSyncToggled: 'Report-sync was toggled',
  reportSentToSync: 'Report was sent to be synced',
  createReportFailed: 'Report creation failed',
  onboardingDemoClicked: 'Clicked to Watch Demo (Onboarding)',
  onboardingGuideClicked: 'Clicked on use case (Onboarding)',
  onboardingGuideCompleted: 'Completed use case(Onboarding)',
  reports: {
    startFromScratch: 'Clicked on Start from scratch (Reports)',
    templateSelected: 'Clicked on a template (Reports)',
    newReportCreated: 'New report created (Reports)',
    reportViewed: 'Existing report viewed (Reports)',
    newReportPageVisit: 'New report page visit',
  },
  selectedSegment: 'Selected segment',
  filtersAddFilter: 'Clicked on Add Filters',
  filtersAppliedFilter: 'Applied filter',
  alertsNewAlertClicked: 'Clicked to create New Alert (Alerts)',
  alertsSavedAlert: 'Saved alert (Alerts)',
  alertsDeletedAlert: 'Deleted an alert (Alerts)',
  alertsToggleStatus: 'Toggled alert status (Alerts)',
  ChannelTabShowAdvancedSettings: 'Clicked show all settings (Channel config)',
  channelTabDeleteChannel: 'Deleted channel (Channel config)',
  channelSaveChannelSettings: 'Saved channel settings (Channel config)',
  channelMappingRulesWeights: 'Saved mapping rules weights (Channel config)',
  channelMappingRulesSort: 'Sorted by attribution weight (Channel config)',
  whatIfChangedPredictedKPI: 'Changed predicted KPI (What if)',
  whatIfWarningMessageShown: 'Warning message shown (What if)',
  whatIfChangedTimeframe: 'Changed timeframe (What if)',
  whatIfChangedSamplePeriod: 'Changed sample period (What if)',
  whatIfAddedNewScenario: 'Added a new scenario (What if)',
  whatIfClosedConfigurationPanel: 'Closed configuration panel (What if)',
  whatIfOpenConfigurationPanel: 'Opened configuration panel (What if)',
  whatIfClickedAddChangeInScenario: 'Clicked add change in scenario (What if)',
  whatIfSavedScenario: 'Saved scenario (What if)',
  whatIfSavedRuleInScenario: 'Saved rule in scenario (What if)',
  whatIfDeletedScenario: 'Deleted scenario (What if)',
  whatIfExpandedCard: 'Expanded card (What if)',
  whatIfCollapsedCard: 'Collapsed card (What if)',
  segmentsAnalysisUplift: 'Toggled to view uplift in table (Uplift)',
  quickFilters: {
    clickedAddQuickFilter: 'Clicked to add a quick filter (Quick filters)',
    continuedQuickFilterAfterType: 'Continued new quick filter after type (Quick filters)',
    continuedQuickFilterAfterStagesAndLogic: 'Continued new quick filter after stages & logic (Quick filters)',
    clickedToViewQuickFilterSettings: 'Clicked to view quick filter settings (Quick filters)',
    savedNewQuickFilter: 'Saved new quick filter (Quick filters)',
    showMoreAIRecommended: 'Show more AI recommended quick filters (Quick filters)',
    toggledAIRecommendedQuickFilters: 'Toggled AI recommended quick filters (Quick filters)',
    pinnedAIQuickFilter: 'Pinned AI quick filter (Quick filters)',
    deletedQuickFilter: 'Deleted quick filter (Quick filters)',
    toggledQuickFilter: 'Toggled quick filter (Quick filters)',
    openedQuickFilter: 'Opened quick filter (Quick filters)',
    appliedQuickFilter: 'Applied quick filter (Quick filters)',
    resetQuickFilter: 'Reset quick filter (Quick filters)',
  },
  funnelAnalysis: {
    clickedOnFunnelStagesReportLink: 'Clicked on funnel stage\'s report link (Funnel)',
    clickedOnFunnelStage: 'Clicked on funnel stage (Funnel)',
    toggledVolumeRevenueView: 'Toggled volume/revenue view (Funnel)',
    selectFunnelStages: 'Selected funnel stages (Funnel)',
    selectMetrics: 'Selected metrics (Funnel)',
    nextActionFromFunnel: 'Selected next action from funnel (Funnel)',
  },
  funnelDrilldown: {
    selectBreakdown: 'Changed breakdown by (Funnel Drilldown)',
    selectColumns: 'Changed metrics selection in table (Funnel Drilldown)',
    toggledCompareTo: 'Toggled compare to prev (Funnel Drilldown)',
    selectFunnelStagesConversion: 'Changed funnel stages CR (Funnel Drilldown)',
    closeDrilldownPopup: 'Closed drilldown popup (Funnel Drilldown)',
    clickOnReferencesButton: 'Clicked on references button (Funnel Drilldown)',
    selectReferencesButton: 'Selected reference metrics (Funnel Drilldown)',
    rollupTable: 'Rollup drilldown table (Funnel Drilldown)',
    nextActionFromSegment: 'Selected next action from table (Funnel Drilldown)',
  },
  inlineFilters: {
    clickedViewInlineFilters: 'Clicked to view inline filter',
    clickedSortBySegmentsAnalysisTable: 'Sorted a column in table',
    appliedFilterInSegmentsAnalysisTable: 'Applied an inline filter in table',
  },
  optimalJourney: {
    selectFunnelStages: 'Selected funnel stages (Optimal Journey)',
    changeKPI: 'Changed KPI (Optimal Journey)',
    addedBlock: 'Added a journey block (Optimal Journey)',
    removedBlock: 'Removed a journey block (Optimal Journey)',
  },
};
