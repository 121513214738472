import classnames from 'classnames';
import React, { useEffect, useState, useRef } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { inject, observer } from 'mobx-react';

import useStyles from 'hooks/useStyles';
import useOnClickOutside from 'hooks/useOnClickOutside';

import AskQuestionInput from 'components/pages/questions/AskQuestionInput';
import Suggestions from 'components/pages/questions/Suggestions';

import { Events } from 'trackers/analytics/enums';
import { getWidgetConfigWithPlaceHolderValues, sendContextualSuggestionsRequest, trackQuestionEvent } from 'components/pages/questions/logic/askQuestion';
import { suggestionTypes } from 'components/pages/questions/enums';
import { suggestionsResultsByType, getMockSuggestionsTypeByText } from 'components/pages/questions/logic/mockAIAnswers';

import style from 'styles/questions/ai-answers.css';
import questionsStyle from 'styles/questions/questions.css';

const styles = style.locals || {};
const questionsStyles = questionsStyle.locals || {};

function AskQuestionWithSuggestions({
  questionInputText,
  setQuestionInputText,
  placeholder = 'Ask a question',
  getQuestionSuggestions,
  currentRequestId,
  setQuestionWidgetsMetadataResults,
  setAnswersFlowWidgets,
  onClickSuggestion,
  answersFlowWidgets,
  flags,
}) {
  useStyles([style, questionsStyle]);

  const [suggestionsData, setSuggestionsData] = useState([]);
  const [isSuggestionsPopupOpen, setIsSuggestionsPopupOpen] = useState(false);

  const askQuestionRef = useRef(null);

  useOnClickOutside(askQuestionRef, () => {
    if (isSuggestionsPopupOpen) {
      setIsSuggestionsPopupOpen(false);
    }
  });

  useEffect(() => {
    if (questionInputText !== '') {
      const delayDebounce = setTimeout(async () => {
        setSuggestionsData([]);
        setIsSuggestionsPopupOpen(true);
        trackQuestionEvent({ eventName: Events.questionSearched, questionInputText });

        if (flags.aiAnswersDemoOnly) {
          if (suggestionsData.length > 0) {
            setSuggestionsData([]);
          }
          const suggestionsType = getMockSuggestionsTypeByText({ text: questionInputText });
          setTimeout(() => setSuggestionsData(suggestionsResultsByType[suggestionsType]), 500);
          return;
        }

        const questionSuggestions = await getQuestionSuggestions({ questionText: questionInputText });
        setSuggestionsData(questionSuggestions);
      }, 1000);
      return () => clearTimeout(delayDebounce);
    }
    return () => ({});
  }, [questionInputText]);

  function onSelectedSuggestion({ questionId, placeHoldersValues }) {
    const questionData = suggestionsData.find((question) => question.questionId === questionId);
    if (questionData?.widget) {
      const widgetAsJson = getWidgetConfigWithPlaceHolderValues({ placeHoldersValues, questionData });
      setIsSuggestionsPopupOpen(false);
      setQuestionInputText('');
      setQuestionWidgetsMetadataResults({ widget: widgetAsJson, questionId, shouldAppendDataById: true });
      setAnswersFlowWidgets({ widgets: [...answersFlowWidgets, { ...questionData.widget, questionId }] });
      trackQuestionEvent({ eventName: Events.questionAsked, additionalProperties: { question: questionData }, questionInputText });
      sendContextualSuggestionsRequest({
        question: questionData, placeHoldersValues, suggestionType: suggestionTypes.search, currentRequestId,
      });

      if (onClickSuggestion) {
        onClickSuggestion({ questionData, placeHoldersValues });
      }
    }
  }

  return (
    <div ref={askQuestionRef}>
      <AskQuestionInput
        value={questionInputText}
        placeholder={placeholder}
        onFocus={() => (isSuggestionsPopupOpen || !questionInputText ? null : setIsSuggestionsPopupOpen(true))}
        onChange={(event) => setQuestionInputText(event.target.value)}
      />
      <div className={classnames(styles.aiAnswersResultsWrapper, questionsStyles.questionResultsWrapper, isSuggestionsPopupOpen && styles.aiAnswersResultsHeight)}>
        <Suggestions
          data={suggestionsData}
          onSelectSuggestion={({ questionId, placeHoldersValues }) => onSelectedSuggestion({ questionId, placeHoldersValues })}
          isShowHeaderTitle={false}
        />
      </div>
    </div>
  );
}

export default withLDConsumer()(inject(
  ({
    questionsStore: {
      getQuestionSuggestions,
      currentRequestId,
      setQuestionWidgetsMetadataResults,
      setAnswersFlowWidgets,
      answersFlowWidgets,
    },
  }) => ({
    getQuestionSuggestions,
    currentRequestId,
    setQuestionWidgetsMetadataResults,
    setAnswersFlowWidgets,
    answersFlowWidgets,
  }),
  observer
)(AskQuestionWithSuggestions));
