import { decorate, observable, action } from 'mobx';
import { cloneDeep, isEqual } from 'lodash';

import InteractionTracker from 'trackers/interactions/InteractionTracker';
import { widgetTypes } from 'components/pages/analyze/enums';

class InteractionsStore {
  constructor() {
    this.widgetsAIData = {};
    this.shouldSendNewSessionInteraction = false;
  }

  createInteractionTracker() {
    this.interactionTracker = new InteractionTracker();

    if (this.shouldSendNewSessionInteraction) {
      this.interactionTracker.newSession();
      this.shouldSendNewSessionInteraction = false;
    }
  }

  updateWidgetData({ widgetId, widgetHeader, currentData }) {
    if (!this.widgetsAIData[widgetId]) {
      this.widgetsAIData[widgetId] = {
        current: currentData,
        prev: null,
      };

      this.interactionTracker.trackWidget({ widgetHeader, widgetData: currentData });
      return true;
    }

    this.widgetsAIData[widgetId].prev = cloneDeep(this.widgetsAIData[widgetId].current);
    this.widgetsAIData[widgetId].current = currentData;

    if (this.isWidgetDataChanged({ widgetId })) {
      this.interactionTracker.trackWidget({ widgetHeader, widgetData: currentData });
      return true;
    }

    return false;
  }

  isWidgetDataChanged({ widgetId }) {
    const relevantWidget = this.widgetsAIData[widgetId];

    return relevantWidget && !isEqual(relevantWidget.prev, relevantWidget.current);
  }

  // TODO: Should be removed when we support all widgets
  isWidgetSupported({ widgetType }) {
    return [
      widgetTypes.segmentsAnalysis,
      widgetTypes.impactBySegment,
    ].includes(widgetType);
  }
}

decorate(InteractionsStore, {
  widgetsAIData: observable,
  interactionTracker: observable,
  createInteractionTracker: action.bound,
  updateWidgetData: action.bound,
  isWidgetSupported: action.bound,
});

export default new InteractionsStore();
