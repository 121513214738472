import React, { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import useStyles from 'hooks/useStyles';
import useOnClickOutside from 'hooks/useOnClickOutside';

import Popup from 'components/Popup';
import Dropdown from 'components/controls/Dropdown';

import style from 'styles/questions/searchableDropdownPopup.css';
import manageColumnsButtonStyle from 'styles/plan/manage-columns-button.css';

const styles = style.locals || {};
const columnsButtonStyle = manageColumnsButtonStyle.locals || {};

export default function SearchableDropdownPopup({
  options = [],
  selectedValue,
  onSelectValue,
  classNameLabel,
  classNamePopup,
  displayPlaceholder,
  dropdownProps,
  dropdownAsLink,
  dropdownLabel,
  controlWidth,
  disabled,
  dataTestId = 'displayedValue',
  classNameLabelContainer,
}) {
  useStyles([
    style,
    manageColumnsButtonStyle,
  ]);

  const refWrapper = useRef();

  const [isShowPopup, setIsShowPopup] = useState(false);

  function closePopup() {
    setIsShowPopup(false);
  }

  useOnClickOutside(refWrapper, () => closePopup());

  if (!onSelectValue || !options || options.length === 0) {
    return null;
  }

  const selectedOption = useMemo(() => {
    for (const option of options) {
      const groupedOptions = option.options;
      if (groupedOptions) {
        const foundOption = groupedOptions.find((groupOption) => groupOption.value === selectedValue);
        if (foundOption) {
          return foundOption;
        }
      }
      if (option.value === selectedValue) {
        return option;
      }
    }
    return {
      label: displayPlaceholder || 'Select...',
    };
  }, [selectedValue, options]);

  return (
    <div
      ref={refWrapper}
      className={classNames(
        styles.searchableDropdownPopup,
        columnsButtonStyle.relative,
        disabled && styles.disabled
      )}
      data-testid="searchableDropdownPopup"
    >
      <div
        className={classNames(
          styles.displayedValue,
          columnsButtonStyle.manageColumnsButton,
          dropdownAsLink && columnsButtonStyle.dropdownAsLink,
          classNameLabelContainer
        )}
        onClick={disabled ? null : () => (setIsShowPopup((prev) => !prev))}
        data-testid={dataTestId}
        style={{ width: controlWidth }}
        data-disabled={disabled || null}
      >
        {dropdownAsLink ? (
          <b className={classNames(styles.displayedLabel, classNameLabel)}>
            {selectedOption.label}
          </b>
        ) : (
          <div className={classNames(styles.displayedDropdownLabel, classNameLabel)}>
            {dropdownLabel ? (
              <span>{dropdownLabel}</span>
            ) : null}
            <span className={dropdownLabel ? styles.boldDropdownLabel : null}>
              {selectedOption.label}
            </span>
            {selectedOption.icon ? (
              <span className={dropdownLabel ? styles.boldDropdownLabel : null}>
                {selectedOption.icon}
              </span>
            ) : null}
          </div>
        )}
        {!disabled ? (
          <div
            className={classNames(
              columnsButtonStyle.dropdownIndicator,
              dropdownAsLink && styles.dropdownIndicator,
              isShowPopup && styles.isShowPopup
            )}
          />
        ) : null}
      </div>

      {isShowPopup ? (
        <Popup className={classNames(dropdownAsLink ? styles.dropdownAsLinkPopup : styles.dropdownPopup, classNamePopup)}>
          <Dropdown
            options={options}
            selectedKey=""
            onChange={(selectedItem) => {
              onSelectValue(selectedItem);
              closePopup();
            }}
            menuIsOpen={isShowPopup}
            isSearchable
            classNameMenu={styles.dropdownMenu}
            isShowDropdownIndicator={false}
            placeholder="Search..."
            autoFocus
            {...dropdownProps}
          />
        </Popup>
      ) : null}
    </div>
  );
}
