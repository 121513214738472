import React, {
  useEffect, useState, useRef, useMemo,
} from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import useStyles from 'hooks/useStyles';
import useOnClickOutside from 'hooks/useOnClickOutside';

import servicesStore from 'stores/servicesStore';
import userStore from 'stores/userStore';
import interactionsStore from 'stores/interactionsStore';

import AddToReportPopup from 'components/pages/reports/AddToReportPopup';
import CogwheelWithOptions from 'components/common/CogwheelWithOptions';
import ConfirmPopup from 'components/ConfirmPopup';
import InfoMarker from 'components/pages/InfoMarker';
import SelectTimeFrameWithCustom from 'components/controls/SelectTimeFrameWithCustom';
import Textfield from 'components/controls/Textfield';
import Tooltip from 'components/controls/Tooltip';
import WidgetAttributionConfigs from 'components/pages/reports/WidgetAttributionConfigs';
import WidgetFiltersTags from 'components/pages/reports/WidgetFiltersTags';
import WidgetHeaderDescription from 'components/common/WidgetHeaderDescription';
import FeatureFlags from 'components/common/FeatureFlags';
import AIAnalysis from 'components/common/AIAnalysis';
import PredefinedFiltersMenuOption from 'components/pages/reports/PredefinedFiltersMenuOption';

import { generateUniqueCopyLabel, toastMessage } from 'components/pages/reports/logic/reports';
import { widgetHeaderConfigKeys } from 'components/common/logic/enums';
import { getAIPropsByType } from 'components/common/logic/AIAnalysis';
import { getParseDateRangeLabel } from 'stores/analyze/logic/timeUtils';
import { Interactions, switcherInteractionTypes } from 'trackers/interactions/enums';
import { applyFiltersInteraction } from 'stores/logic/attributionStore';
import { makeFilters } from 'components/utils/filters';

import style from 'styles/analyze/widgetHeader.css';

const styles = style.locals || {};

function WidgetHeader({
  isReadOnly,
  isInReports,
  isShowPropertiesAsCollapsed,
  isHideAddToReport,
  widgetHeaderConfig = {},
  updateWidgetConfig,
  updateWidgetTitle,
  titleTooltip,
  isShowTimeframeSelect,
  isShowShortTimeframeLabel,
  customTimeframeUpdate,
  isShowFilterContainer,
  filtersData,
  children,
  isShowAttributionModel,
  isShowIndicatorDropdown,
  isShowWidgetDescription,
  updateWidgetDescription,
  customCogwheelPopup,
  cogwheelOptions = [],
  widgetsOfSelectedReport,
  addWidgetToReportRequest,
  deleteWidgetRequest,
  classNameWidgetTitle,
  flags,
}) {
  const [widgetHeaderConfiguration, setWidgetHeaderConfiguration] = useState(widgetHeaderConfig);
  const [isShowAddToReportPopup, setIsShowAddToReportPopup] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState({ isShow: false, message: '' });
  const [isShowPropertiesPopup, setIsShowPropertiesPopup] = useState(false);
  const refPropertiesPopup = useRef();

  useStyles([style]);

  useEffect(() => {
    setWidgetHeaderConfiguration(widgetHeaderConfig);
  }, [widgetHeaderConfig]);

  useOnClickOutside(refPropertiesPopup, () => {
    setIsShowPropertiesPopup(false);
  });

  function inputTitleFinishRename() {
    if (widgetHeaderConfiguration.title === '') {
      setWidgetHeaderConfiguration({
        ...widgetHeaderConfiguration,
        title: widgetHeaderConfig.title,
      });
    } else {
      updateWidgetTitle({ title: widgetHeaderConfiguration.title });
    }
  }

  function removeWidgetsHandler() {
    const popupMessage = (
      <>
        {'Are you sure you want to remove '}
        <b>{widgetHeaderConfiguration.title}</b>
        {' widget?'}
      </>
    );
    setShowConfirmPopup({ isShow: true, message: popupMessage });
  }

  async function duplicateWidgetsHandler() {
    const foundWidget = await widgetsOfSelectedReport.find((widget) => widget.id === widgetHeaderConfig.widgetId);
    const existingWidgetLabels = widgetsOfSelectedReport.map((widget) => widget.title);
    const uniqueCopyLabel = generateUniqueCopyLabel({
      originalLabel: foundWidget.title,
      existingLabels: existingWidgetLabels,
    });
    try {
      await addWidgetToReportRequest({
        reportId: foundWidget.reportId,
        title: uniqueCopyLabel,
        type: foundWidget.type,
        timeFrame: foundWidget.configuration.timeFrame,
        filters: foundWidget.configuration.filters,
        attributionModel: foundWidget.configuration.attributionModel,
        attributionCredit: foundWidget.configuration.attributionCredit,
        shouldUsePredefinedFilters: foundWidget.configuration.shouldUsePredefinedFilters,
        widgetConfig: {
          channelJourneys: foundWidget.channelJourneys,
          contentJourneys: foundWidget.contentJourneys,
          ...foundWidget.configuration,
        },
      });
      toastMessage({ type: 'success', message: 'Widget copied successfully!' });
    } catch (error) {
      servicesStore.logger.error('Duplicating report error', {
        error,
      });
      toastMessage({ type: 'error', message: 'Failed to copy widget. Please try again' });
    }
  }

  function confirmDeletePopupCallBack(abortAction) {
    if (!abortAction) {
      deleteWidgetRequest({ reportId: widgetHeaderConfig.reportId, widgetId: widgetHeaderConfig.widgetId });
    }
    setShowConfirmPopup({ isShow: false, message: '' });
  }

  const allCogwheelOptions = useMemo(() => {
    const updatedOptions = [...cogwheelOptions];
    if (isInReports) {
      const shouldUsePredefinedFilters = isNil(widgetHeaderConfiguration.shouldUsePredefinedFilters) ? true : widgetHeaderConfiguration.shouldUsePredefinedFilters;

      updatedOptions.push({ label: 'Remove', action: removeWidgetsHandler });
      updatedOptions.push({ label: 'Duplicate', action: duplicateWidgetsHandler });
      updatedOptions.push({
        label: (
          <PredefinedFiltersMenuOption
            shouldUsePredefinedFilters={shouldUsePredefinedFilters}
            updateShouldUsePredefinedFilter={() => onUpdatePredefinedFiltersSwitch()}
            containerClassName={styles.predefinedFiltersContainer}
          />
        ),
      });
    } else if (!isHideAddToReport) {
      updatedOptions.push({
        label: 'Add to report',
        action: () => setIsShowAddToReportPopup(true),
        limitedAccess: {},
      });
    }
    return updatedOptions;
  }, [cogwheelOptions]);

  function handleSelectTimeframe(newTimeframe) {
    if (customTimeframeUpdate) {
      customTimeframeUpdate({ newTimeframe });
    } else {
      updateWidgetConfig({ configKey: widgetHeaderConfigKeys.timeframe, configValue: newTimeframe });
    }

    const timeFrameLabel = getParseDateRangeLabel({ timeFrame: newTimeframe });
    interactionsStore.interactionTracker.trackConfig({
      type: Interactions.global.timeframeChanged.type,
      name: Interactions.global.timeframeChanged.name,
      description: timeFrameLabel,
      widgetTitle: widgetHeaderConfig.title,
    });
  }

  function onUpdateWidgetFilters({ newFilters }) {
    updateWidgetConfig({ configKey: widgetHeaderConfigKeys.filters, configValue: newFilters });

    applyFiltersInteraction({ filters: makeFilters(newFilters, filtersData) });
  }

  function onUpdateWidgetAttributionModel({ newAttributionModel }) {
    updateWidgetConfig({ configKey: widgetHeaderConfigKeys.attributionModel, configValue: newAttributionModel });

    const attributionModelLabel = `${newAttributionModel.label} (${newAttributionModel.description})`;
    interactionsStore.interactionTracker.trackConfig({
      type: Interactions.global.attributionModelChanged.type,
      name: Interactions.global.attributionModelChanged.name,
      description: attributionModelLabel,
      widgetTitle: widgetHeaderConfig.title,
    });
  }

  function onUpdateWidgetIndicator({ newIndicator }) {
    updateWidgetConfig({ configKey: widgetHeaderConfigKeys.conversionIndicator, configValue: newIndicator });

    const kpiFocusLabel = userStore.getMetricNickname({ metric: newIndicator });
    interactionsStore.interactionTracker.trackConfig({
      type: Interactions.global.kpiFocusChanged.type,
      name: Interactions.global.kpiFocusChanged.name,
      description: kpiFocusLabel,
      widgetTitle: widgetHeaderConfig.title,
    });
  }

  function onUpdatePredefinedFiltersSwitch() {
    const shouldUsePredefinedFilters = isNil(widgetHeaderConfiguration.shouldUsePredefinedFilters) ? false : !widgetHeaderConfiguration.shouldUsePredefinedFilters;
    updateWidgetConfig({ configKey: widgetHeaderConfigKeys.shouldUsePredefinedFilters, configValue: shouldUsePredefinedFilters });

    const predefinedFiltersLabel = shouldUsePredefinedFilters ? switcherInteractionTypes.on : switcherInteractionTypes.off;
    interactionsStore.interactionTracker.trackConfig({
      type: Interactions.global.predefinedFiltersSwitched.type,
      name: Interactions.global.predefinedFiltersSwitched.name,
      description: predefinedFiltersLabel,
      widgetTitle: widgetHeaderConfig.title,
    });
  }

  return (
    <>
      <div className={styles.widgetHeader}>
        <div className={styles.widgetTitleGap}>
          <div className={classNames(styles.widgetTitleWidth, classNameWidgetTitle)}>
            {widgetHeaderConfiguration.title != null ? (
              <>
                {updateWidgetTitle != null ? (
                  <Tooltip
                    id="widgetRename"
                    tip="Rename"
                  >
                    <Textfield
                      dataTestId="widgetTitle"
                      disabled={isReadOnly}
                      value={widgetHeaderConfiguration.title}
                      onBlur={() => inputTitleFinishRename()}
                      className={styles.widgetTitleInput}
                      onChange={(event) => setWidgetHeaderConfiguration({
                        ...widgetHeaderConfiguration,
                        title: event.target.value,
                      })}
                    />
                  </Tooltip>
                ) : (
                  <h2>{widgetHeaderConfiguration.title}</h2>
                )}

                {titleTooltip ? (
                  <InfoMarker tooltipText={titleTooltip} />
                ) : null}
              </>
            ) : null}

            {isShowWidgetDescription ? (
              <WidgetHeaderDescription
                description={widgetHeaderConfiguration.description}
                updateDescription={({ value }) => updateWidgetDescription({ description: value })}
              />
            ) : null}

            {widgetHeaderConfiguration.subTitle ? (
              <div className={classNames(styles.widgetSubTitle, widgetHeaderConfiguration.subTitleClassName)}>
                {widgetHeaderConfiguration.subTitle}
              </div>
            ) : null}

            {isShowTimeframeSelect ? (
              <div className={styles.widgetDateRange}>
                <SelectTimeFrameWithCustom
                  disabled={isReadOnly}
                  widgetTimeFrame={widgetHeaderConfiguration.timeFrame}
                  isShowShortLabel={isShowShortTimeframeLabel}
                  isShowCompareToPrev
                  updateWidgetTimeframe={(newTimeframe) => handleSelectTimeframe(newTimeframe)}
                  widgetPreviousTimeFrame={widgetHeaderConfiguration.isCompareToPreviousEnabled}
                  setWidgetPreviousTimeFrame={(newIsCompareToPreviousEnabled) => {
                    updateWidgetConfig({
                      configKey: widgetHeaderConfigKeys.isCompareToPreviousEnabled,
                      configValue: newIsCompareToPreviousEnabled,
                    });
                  }}
                  customControlTextStyles={styles.selectTimeframeButton}
                  classNamePopup={styles.selectTimeframePopup}
                  isUseCustomLabel
                  isShowPopupTitle={false}
                  isUsePopup
                />
              </div>
            ) : null}
          </div>

          <div className={styles.widgetRelative}>
            {isShowPropertiesAsCollapsed ? (
              <div className={styles.showProperties} onClick={() => setIsShowPropertiesPopup(!isShowPropertiesPopup)}>
                Show Properties
              </div>
            ) : null}

            <div hidden={isShowPropertiesAsCollapsed && !isShowPropertiesPopup} ref={refPropertiesPopup}>
              <div className={isShowPropertiesAsCollapsed ? styles.propertiesAsCollapsed : styles.settingsWrapper}>
                {isShowPropertiesAsCollapsed ? (
                  <div className={styles.propertiesTitle}>
                    Properties
                  </div>
                ) : null}

                {isShowPropertiesAsCollapsed ? (
                  <div className={styles.widgetPropertiesSubtitle}>
                    Filters
                  </div>
                ) : null}

                {isShowFilterContainer ? (
                  <WidgetFiltersTags
                    widgetHeaderConfig={{ filters: widgetHeaderConfiguration.filters }}
                    filtersData={filtersData}
                    updateWidgetFilters={(newFilters) => onUpdateWidgetFilters({ newFilters })}
                    key="widget-filters-tags"
                    disabled={isReadOnly}
                    isShowFiltersAsCollapsed={!!isShowPropertiesAsCollapsed}
                  />
                ) : null}

                <div className={styles.widgetConfigurations}>
                  {children}

                  {isShowAttributionModel ? (
                    <WidgetAttributionConfigs
                      indicator={isShowIndicatorDropdown ? widgetHeaderConfiguration.indicator : null}
                      attributionModel={widgetHeaderConfiguration.attributionModel}
                      updateWidgetAttributionModel={(newAttributionModel) => onUpdateWidgetAttributionModel({ newAttributionModel })}
                      updateWidgetIndicator={(newIndicator) => onUpdateWidgetIndicator({ newIndicator })}
                      disabled={isReadOnly}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.widgetRightOptions}>
          {customCogwheelPopup ? (
            <>{customCogwheelPopup}</>
          ) : (
            <>
              { allCogwheelOptions.length > 0 ? (
                <CogwheelWithOptions options={allCogwheelOptions} />
              ) : null}
            </>
          )}

          <FeatureFlags flag={flags.aiAnalysisButtonDemoOnly}>
            <AIAnalysis
              {...getAIPropsByType({ type: widgetHeaderConfiguration.type })}
            />
          </FeatureFlags>
        </div>
      </div>

      {isShowAddToReportPopup ? (
        <AddToReportPopup
          onClosePopup={() => setIsShowAddToReportPopup(false)}
          isInReports={isInReports}
          widgetHeaderConfig={widgetHeaderConfiguration}
        />
      ) : null}

      <ConfirmPopup
        hidden={!showConfirmPopup.isShow}
        callback={(abortAction) => confirmDeletePopupCallBack(abortAction)}
        confirmBtn="Remove"
        title={showConfirmPopup.message}
      />
    </>
  );
}

export default withLDConsumer()(inject(
  ({
    reportsStore: {
      widgetsOfSelectedReport,
      addWidgetToReportRequest,
      deleteWidgetRequest,
    } = {},
    filterStore: {
      filtersData,
    } = {},
  }) => ({
    filtersData,
    widgetsOfSelectedReport,
    addWidgetToReportRequest,
    deleteWidgetRequest,
  }),
  observer
)(WidgetHeader));
