export const InteractionTypes = {
  session: 'session',
  page: 'page',
  timeframe: 'timeframe',
  filters: 'filters',
  config: 'config',
  widgetData: 'widgetData',
  pageLoaded: 'pageLoaded',
};

export const switcherInteractionTypes = {
  off: 'Off',
  on: 'On',
};

export const Interactions = {
  sessionStarted: {
    name: 'Session Started',
    type: InteractionTypes.session,
    hidden: true,
  },
  global: {
    pageChanged: {
      name: 'User switched a page',
      type: InteractionTypes.page,
    },
    pageLoaded: {
      name: 'Page loaded',
      type: InteractionTypes.pageLoaded,
      hidden: true,
    },
    filtersApplied: {
      name: 'Filters applied',
      type: InteractionTypes.filters,
    },
    filtersRemoved: {
      name: 'Filters removed',
      type: InteractionTypes.filters,
    },
    timeframeChanged: {
      name: 'Timeframe changed',
      type: InteractionTypes.timeframe,
    },
    attributionModelChanged: {
      name: 'Attribution model changed',
      type: InteractionTypes.config,
    },
    kpiFocusChanged: {
      name: 'KPI focus changed',
      type: InteractionTypes.config,
    },
    accountModeChanged: {
      name: 'Account view toggled',
      type: InteractionTypes.config,
    },
    predefinedFiltersSwitched: {
      name: 'Predefined filters toggled',
      type: InteractionTypes.config,
    },
    segmentTabToggleChanged: {
      name: 'Segment tab changed',
      type: InteractionTypes.config,
    },
    selectedStagesUpdated: {
      name: 'Stages updated',
      type: InteractionTypes.config,
    },
    businessUnitChanged: {
      name: 'Business unit changed',
      type: InteractionTypes.config,
    },
  },
  segmentsAnalysisTable: {
    segmentsChanged: {
      name: 'Segments changed',
      type: InteractionTypes.config,
    },
    searchChanged: {
      name: 'Search changed',
      type: InteractionTypes.config,
    },
    searchCleared: {
      name: 'Search cleared',
      type: InteractionTypes.config,
    },
    stageSelected: {
      name: 'Stage selected',
      type: InteractionTypes.config,
    },
    columnsChanged: {
      name: 'Columns changed',
      type: InteractionTypes.config,
    },
    inlineFilterApplied: {
      name: 'Inline filter applied',
      type: InteractionTypes.config,
    },
    inlineFilterRemoved: {
      name: 'Inline filter removed',
      type: InteractionTypes.config,
    },
    sortApplied: {
      name: 'Sort applied',
      type: InteractionTypes.config,
      hidden: true,
    },
  },
  impactBySegment: {
    segmentChanged: {
      name: 'Segment changed',
      type: InteractionTypes.config,
    },
    kpiFocusChanged: {
      name: 'KPI focus changed',
      type: InteractionTypes.config,
    },
    metricDropdownChanged: {
      name: 'Metric dropdown changed',
      type: InteractionTypes.config,
    },
    frequencyDropdownChanged: {
      name: 'Frequency dropdown changed',
      type: InteractionTypes.config,
    },
    monthToDateToggled: {
      name: 'Month to date toggled',
      type: InteractionTypes.config,
    },

  },
  funnelAnalysis: {
    viewTypeChanged: {
      name: 'View type changed',
      type: InteractionTypes.config,
    },
    funnelStagesUpdated: {
      name: 'Funnel stages updated',
      type: InteractionTypes.config,
    },
    metricsUpdated: {
      name: 'Metrics updated',
      type: InteractionTypes.config,
    },
  },
  historicalPerformance: {
    presetsChanged: {
      name: 'Presets changed',
      type: InteractionTypes.config,
    },
    drilldownClicked: {
      name: 'Drilldown',
      type: InteractionTypes.config,
    },
    frequencyDropdownChanged: {
      name: 'Frequency dropdown changed',
      type: InteractionTypes.config,
    },
  },
};
