import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import useStyles from 'hooks/useStyles';
import useOnClickOutside from 'hooks/useOnClickOutside';

import { getWidgetShortDescription } from 'components/common/logic/widgetHeaderDescription';

import style from 'styles/analyze/widgetHeader.css';

const styles = style.locals || {};

function WidgetHeaderDescription({ updateDescription, description }) {
  useStyles([style]);

  const [widgetDescription, setWidgetDescription] = useState({ isEdit: false, value: description, isShowFullDescription: isShouldShowFullDescription({ value: description }) });

  const refDescriptionTextArea = useRef(null);
  const refDescriptionTextValue = useRef(null);

  useEffect(() => {
    refDescriptionTextArea.current?.focus();
  }, [widgetDescription.isEdit]);

  useEffect(() => {
    if (refDescriptionTextArea.current) {
      refDescriptionTextArea.current.style.height = '0px';
      const scrollHeight = refDescriptionTextArea.current.scrollHeight;
      refDescriptionTextArea.current.style.height = `${scrollHeight + 5}px`;
    }
  }, [widgetDescription.value, widgetDescription.isEdit]);

  useOnClickOutside(refDescriptionTextValue, () => {
    if (widgetDescription.isShowFullDescription) {
      setWidgetDescription({ ...widgetDescription, isShowFullDescription: isShouldShowFullDescription({ value: widgetDescription.value }) });
    }
  });

  function onChangeDescriptionInput(event) {
    setWidgetDescription({ isEdit: true, value: event.target.value });
  }

  function textAreaDescriptionFinishRename() {
    setWidgetDescription({ ...widgetDescription, isEdit: false, isShowFullDescription: isShouldShowFullDescription({ value: widgetDescription.value }) });
    updateDescription({ value: widgetDescription.value });
  }

  function onClickEditDescription() {
    setWidgetDescription({ ...widgetDescription, isEdit: true });
  }

  function onclickShowMoreDescription() {
    setWidgetDescription({ ...widgetDescription, isShowFullDescription: true });
  }

  function isShouldShowFullDescription({ value }) {
    const shortDescription = getWidgetShortDescription({ value });
    return shortDescription === value;
  }

  return (
    <>
      {widgetDescription.isEdit ? (
        <textarea
          value={widgetDescription.value}
          onBlur={() => textAreaDescriptionFinishRename()}
          onChange={(event) => onChangeDescriptionInput(event)}
          placeholder="E.g., “This filtered widget can be used to analyze...”"
          className={styles.widgetDescriptionTextArea}
          ref={refDescriptionTextArea}
          data-testid="description-text-area"
        />
      ) : (
        <>
          {(!widgetDescription.isEdit && widgetDescription?.value?.length > 0) ? (
            <div
              onClick={() => (widgetDescription.isShowFullDescription ? onClickEditDescription() : onclickShowMoreDescription())}
              className={classNames(styles.widgetDescriptionValue, widgetDescription.isShowFullDescription ? styles.fullDescription : null)}
              data-testid="description-text-value"
              ref={refDescriptionTextValue}
            >
              {widgetDescription.isShowFullDescription ? (
                <>{widgetDescription.value}</>
              ) : (
                <>
                  {getWidgetShortDescription({ value: widgetDescription.value })}
                  {'... '}
                  <span data-testid="description-read-more" className={styles.readMoreDescription}>Read more</span>
                </>
              )}
            </div>
          ) : (
            <div data-testid="add-description" onClick={() => onClickEditDescription()} className={styles.addDescription}>
              + Add description
            </div>
          )}
        </>
      )}
    </>
  );
}

export default WidgetHeaderDescription;
