import {
  forwardRef, useImperativeHandle, useRef, useState,
} from 'react';

import classNames from 'classnames';

import useStyles from 'hooks/useStyles';
import useOnClickOutside from 'hooks/useOnClickOutside';

import Popup from 'components/Popup';
import MenuButton from 'components/common/MenuButton';

import style from 'styles/controls/buttonWithOptions.css';

const styles = style.locals || {};

const ButtonWithOptions = forwardRef(({
  options,
  renderMenuButton,
  renderMenuOption,
  popupClassName,
  buttonTitle = 'Actions',
  menuClassName,
  dataTestId = 'button-with-options',
  disabled,
}, ref) => {
  useStyles([style]);

  const [isShowPopup, setIsShowPopup] = useState(false);
  const refPopupFilter = useRef();

  useImperativeHandle(ref, () => ({
    closePopup,
  }));

  const closePopup = () => {
    setIsShowPopup(false);
  };

  useOnClickOutside(refPopupFilter, () => {
    closePopup();
  });

  const onClickOptionsPopup = () => {
    if (disabled) {
      return;
    }
    setIsShowPopup((prev) => !prev);
  };

  const onClickAction = (optionAction) => {
    optionAction();
    closePopup();
  };

  return (
    <div
      ref={refPopupFilter}
      className={styles.optionsWrapper}
      data-testid={dataTestId}
    >
      {renderMenuButton ? (
        <div onClick={onClickOptionsPopup}>{renderMenuButton()}</div>
      ) : (
        <MenuButton
          title={buttonTitle}
          onClick={onClickOptionsPopup}
          isOpen={isShowPopup}
          className={menuClassName}
        />
      )}
      <Popup
        className={classNames(styles.optionsPopup, popupClassName)}
        hidden={!isShowPopup}
      >
        <div className={styles.optionsMenu}>
          {options.map((option) => {
            if (renderMenuOption) {
              return renderMenuOption(option);
            }
            return (
              <div
                key={`action-${option.label}`}
                onClick={() => onClickAction(option.action)}
                data-testid={option.dataTestId}
                className={styles.optionItem}
              >
                {option.label}
              </div>
            );
          })}
        </div>
      </Popup>
    </div>
  );
});

export default ButtonWithOptions;
