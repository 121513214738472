import React from 'react';
import { inject, observer } from 'mobx-react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import interactionsStore from 'stores/interactionsStore';
import AttributionTable from 'components/pages/analyze/AttribuitonTable/AttributionTable';
import Component from 'components/Component';
import ImpactBySegment from 'components/pages/analyze/SegmentsTab/ImpactBySegment';
import FeatureFlagsWithBlur from 'components/common/FeatureFlagsWithBlur';
import BreadcrumbsTitle from 'components/common/BreadcrumbsTitle';

import { ATTRIBUTION_TYPES, compose } from 'components/utils/utils';
import { isFiltersChanged, isTimeframeChanged } from 'components/pages/analyze/logic/Tabs';
import { widgetTypes, flagsNames } from 'components/pages/analyze/enums';
import { getCurrentHashAndScrollToAnchorTag, navigateBetweenAnalyzeTabs } from 'stores/analyze/logic/navigations';
import { FREQUENCY_VALUES } from 'components/utils/frequency';
import {
  getWidgetsData, getWidgetsDataV2, getWidgetFullConfig, getWidgetsDataFromStoreV2,
} from 'components/pages/analyze/widgetsRequest';
import { convertShowOnlyFilters } from 'components/pages/analyze/SegmentsTab/logic/ImpactBySegment';
import { contentTypeSegmentValue, contentTitleSegmentValue, contentURLSegmentValue } from 'components/pages/analyze/SegmentsTab/logic/enums';
import { isFlagActive } from 'components/pages/analyze/logic/FeatureFlagsWithBlur';
import { getFilteredImpactBySegmentRecords, getFilteredSegmentAnalysisRecords } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { getSegmentsContentOptionsFields, getSegmentsOptionsFields } from 'components/pages/analyze/OverviewTab/logic';
import { pageLabels } from 'enums';

const enhance = compose(
  inject((stores) => {
    const { companyWebsite } = stores.userStore.userAccount;

    const {
      formattedTimeParams: timeFrame,
      previousTimeframe: isCompareToPreviousEnabled,
      attributionModel,
      conversionIndicator,
      setPreviousCheckbox,
      isMoneyFormat,
      filters,
      autoFilter,
      setFilters,
      originalFunnelFromIndicator,
      isAccountMode,
      isCalculateAdvancedMetrics,
    } = stores.attributionStore;

    const {
      updateTabConfig,
      configPerTab: {
        content: {
          isContentPages = false,
        },
      },
    } = stores.analyzeStore;

    const {
      funnels,
      userAccount: {
        customFieldsIdToLabelMap,
        customUtmsWhitelist,
      },
      userMonthPlan: {
        region,
      },
    } = stores.userStore;

    const {
      dataPerWidget: {
        [widgetTypes.trendBySegments]: trendBySegments,
        [widgetTypes.accountOptimizationMetrics]: accountOptimizationMetrics,
      },
    } = stores.analysisStore;

    const {
      dataPerWidget: {
        [widgetTypes.impactBySegment]: impactBySegment,
        [widgetTypes.segmentsAnalysis]: segmentsAnalysis,
        [widgetTypes.upliftBySegments]: upliftBySegments,
      },
      configPerWidget: {
        [widgetTypes.impactBySegment]: impactBySegmentSavedConfig,
      },
      getWidgetRequestId,
    } = stores.widgetsAnalysisStore;

    return {
      timeFrame,
      companyWebsite,
      attributionModel,
      isCompareToPreviousEnabled,
      setPreviousCheckbox,
      isContentPages,
      conversionIndicator,
      isMoneyFormat,
      impactBySegmentSavedConfig,
      updateTabConfig,
      filters,
      autoFilter,
      region,
      setFilters,
      originalFunnelFromIndicator,
      trendBySegments,
      accountOptimizationMetrics,
      impactBySegment,
      isAccountMode,
      segmentsAnalysis,
      funnels,
      getWidgetRequestId,
      customFieldsIdToLabelMap,
      customUtmsWhitelist,
      isCalculateAdvancedMetrics,
      upliftBySegments,
    };
  }),
  observer
);

class Content extends Component {
  static widgetIds = {
    impactBySegment: 'analyze-content-impact-by-segment',
    segmentsAnalysis: 'analyze-content-impact-table',
  };

  constructor(props) {
    super(props);
    this.currentTabName = 'content';
    this.state = {
      contentImpactFrequency: props.impactBySegmentSavedConfig?.[this.currentTabName]?.impactBySegmentParams?.segmentFrequency || FREQUENCY_VALUES.MONTH,
      impactBySegmentSelectedSegment: props.isContentPages ? contentTitleSegmentValue : contentTypeSegmentValue,
      trendBySegmentsKeyName: 'trendByCampaigns',
      accountOptimizationMetricsKeyName: 'accountOptimizationMetricsAnalyze',
      isHideNoValuesRows: true,
      drilldownMetric: null,
      selectedSegmentsParams: {
        firstSegment: props.isContentPages ? contentURLSegmentValue : contentTypeSegmentValue,
        secondSegment: props.isContentPages ? contentTypeSegmentValue : null,
      },
      shouldUseRelativeTimeframe: props.impactBySegmentSavedConfig?.[this.currentTabName]?.impactBySegmentParams?.shouldUseRelativeTimeframe || false,
      isInitialLoad: null,
      upliftColumnsKeys: [],
      upliftRequestId: null,
      pageWidgetsStatus: {
        [Content.widgetIds.impactBySegment]: {
          widgetType: widgetTypes.impactBySegment,
          isLoaded: false,
        },
        [Content.widgetIds.segmentsAnalysis]: {
          widgetType: widgetTypes.segmentsAnalysis,
          isLoaded: false,
        },
      },
    };
  }

  componentDidMount() {
    const {
      originalFunnelFromIndicator,
      trendBySegments,
      accountOptimizationMetrics,
      flags,
      location,
      isCalculateAdvancedMetrics,
    } = this.props;

    this.setState({ isInitialLoad: true });
    getCurrentHashAndScrollToAnchorTag(window.location.hash);

    const isRenderViaNavigation = location?.state?.isRenderViaNavigation && location?.action === 'PUSH';
    if (isRenderViaNavigation) {
      return;
    }

    if (isFlagActive({ flag: flags.contentAttributionTableWidget })) {
      if (!trendBySegments?.[this.state.trendBySegmentsKeyName]?.isLoaded && isCalculateAdvancedMetrics) {
        getWidgetsData({
          widgets: [widgetTypes.trendBySegments],
          configPerWidget: {
            [widgetTypes.trendBySegments]: [{
              trendBySegmentsParams: this.state.selectedSegmentsParams,
              conversionIndicator: originalFunnelFromIndicator,
            }],
          },
          isPreviousTimeFrame: false,
          resultKeyName: this.state.trendBySegmentsKeyName,
        });
      }
    }

    if (isFlagActive({ flag: flags.contentImpactBySegmentWidget })) {
      if (!accountOptimizationMetrics?.[this.state.accountOptimizationMetricsKeyName]?.isLoaded) {
        getWidgetsData({
          widgets: [widgetTypes.accountOptimizationMetrics],
          isPreviousTimeFrame: false,
          resultKeyName: this.state.accountOptimizationMetricsKeyName,
        });
      }

      if (!trendBySegments?.[`chart${this.state.trendBySegmentsKeyName}`]?.isLoaded && isCalculateAdvancedMetrics) {
        getWidgetsData({
          widgets: [widgetTypes.trendBySegments],
          configPerWidget: {
            [widgetTypes.trendBySegments]: [{
              trendBySegmentsParams: {
                firstSegment: this.state.impactBySegmentSelectedSegment,
              },
              conversionIndicator: originalFunnelFromIndicator,
            }],
          },
          isPreviousTimeFrame: false,
          resultKeyName: `chart${this.state.trendBySegmentsKeyName}`,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      filters,
      timeFrame,
      attributionModel,
      isCompareToPreviousEnabled,
      conversionIndicator,
      isContentPages,
      autoFilter,
      region,
      originalFunnelFromIndicator,
      flags,
      isAccountMode,
      isCalculateAdvancedMetrics,
    } = this.props;

    const {
      isInitialLoad,
    } = this.state;

    const didInitialLoadChanged = isInitialLoad !== prevState.isInitialLoad;
    const didAccountModeChanged = isAccountMode !== prevProps.isAccountMode;
    const didPreDefinedFiltersChanged = autoFilter !== prevProps.autoFilter;
    const didContentPageChanged = isContentPages !== prevProps.isContentPages;
    const didConversionIndicatorChanged = conversionIndicator !== prevProps.conversionIndicator;
    const didFiltersChanged = isFiltersChanged({ filters, prevFilters: prevProps.filters });
    const didTimeFrameChanged = isTimeframeChanged({ timeFrame, prevTimeFrame: prevProps.timeFrame });
    const didAttributionModelChanged = attributionModel !== prevProps.attributionModel;
    const didCompareToPreviousChanged = isCompareToPreviousEnabled && !prevProps.isCompareToPreviousEnabled;
    const didRegionChanged = region !== prevProps.region;
    const didCalculateAdvancedMetricsChanged = isCalculateAdvancedMetrics !== prevProps.isCalculateAdvancedMetrics;

    let impactBySegmentSelectedSegment = this.state.impactBySegmentSelectedSegment;
    let selectedSegmentsParams = this.state.selectedSegmentsParams;

    if (didCalculateAdvancedMetricsChanged && isCalculateAdvancedMetrics) {
      getWidgetsData({
        widgets: [widgetTypes.trendBySegments],
        configPerWidget: {
          [widgetTypes.trendBySegments]: [{
            trendBySegmentsParams: selectedSegmentsParams,
            conversionIndicator: originalFunnelFromIndicator,
          }],
        },
        isPreviousTimeFrame: false,
        resultKeyName: this.state.trendBySegmentsKeyName,
      });

      getWidgetsData({
        widgets: [widgetTypes.trendBySegments],
        configPerWidget: {
          [widgetTypes.trendBySegments]: [{
            trendBySegmentsParams: {
              firstSegment: impactBySegmentSelectedSegment,
            },
            conversionIndicator: originalFunnelFromIndicator,
          }],
        },
        isPreviousTimeFrame: false,
        resultKeyName: `chart${this.state.trendBySegmentsKeyName}`,
      });
    }

    if (didContentPageChanged) {
      impactBySegmentSelectedSegment = isContentPages ? contentTitleSegmentValue : contentTypeSegmentValue;
      selectedSegmentsParams = {
        firstSegment: isContentPages ? contentURLSegmentValue : contentTypeSegmentValue,
        secondSegment: isContentPages ? contentTypeSegmentValue : null,
      };
      this.setState({ impactBySegmentSelectedSegment, selectedSegmentsParams });
    }

    if (didInitialLoadChanged || didAccountModeChanged || didFiltersChanged || didTimeFrameChanged || didAttributionModelChanged || didPreDefinedFiltersChanged || didContentPageChanged || didRegionChanged) {
      if (isFlagActive({ flag: flags.contentAttributionTableWidget })) {
        getWidgetsDataV2({
          widget: widgetTypes.segmentsAnalysis,
          widgetConfig: {
            segmentsAnalysisParams: selectedSegmentsParams,
            conversionIndicator: null,
          },
        });
        this.setState({ upliftColumnsKeys: [], upliftRequestId: null });
      }
    }

    if (didAccountModeChanged || didFiltersChanged || didTimeFrameChanged || didAttributionModelChanged || didConversionIndicatorChanged || didPreDefinedFiltersChanged || didContentPageChanged) {
      if (isFlagActive({ flag: flags.contentAttributionTableWidget }) && isCalculateAdvancedMetrics) {
        getWidgetsData({
          widgets: [widgetTypes.trendBySegments],
          configPerWidget: {
            [widgetTypes.trendBySegments]: [{
              trendBySegmentsParams: selectedSegmentsParams,
              conversionIndicator: originalFunnelFromIndicator,
            }],
          },
          isPreviousTimeFrame: false,
          resultKeyName: this.state.trendBySegmentsKeyName,
        });

        getWidgetsData({
          widgets: [widgetTypes.trendBySegments],
          configPerWidget: {
            [widgetTypes.trendBySegments]: [{
              trendBySegmentsParams: {
                firstSegment: impactBySegmentSelectedSegment,
              },
              conversionIndicator: originalFunnelFromIndicator,
            }],
          },
          isPreviousTimeFrame: false,
          resultKeyName: `chart${this.state.trendBySegmentsKeyName}`,
        });
      }
    }

    if (didInitialLoadChanged || didAccountModeChanged || didFiltersChanged || didTimeFrameChanged || didAttributionModelChanged || didConversionIndicatorChanged || didPreDefinedFiltersChanged || didContentPageChanged) {
      if (isFlagActive({ flag: flags.contentImpactBySegmentWidget })) {
        let drilldownMetric = this.state.drilldownMetric;
        if (didConversionIndicatorChanged) {
          drilldownMetric = conversionIndicator;
          this.setState({ drilldownMetric });
        }
        getWidgetsDataV2({
          widget: widgetTypes.impactBySegment,
          widgetConfig: {
            impactBySegmentParams: {
              segmentFrequency: this.state.contentImpactFrequency,
              firstSegment: impactBySegmentSelectedSegment,
              fieldsToEnrich: [contentTypeSegmentValue],
              shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
            },
            conversionIndicator: originalFunnelFromIndicator,
            kpiFocus: drilldownMetric || conversionIndicator,
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
          configKeyName: this.currentTabName,
        });
      }
    }

    if (didAccountModeChanged || didFiltersChanged || didTimeFrameChanged || didAttributionModelChanged || didConversionIndicatorChanged || didPreDefinedFiltersChanged) {
      getWidgetsData({
        widgets: [widgetTypes.accountOptimizationMetrics],
        isPreviousTimeFrame: false,
        resultKeyName: this.state.accountOptimizationMetricsKeyName,
      });
    }

    if (didCompareToPreviousChanged) {
      if (isFlagActive({ flag: flags.contentAttributionTableWidget })) {
        getWidgetsDataFromStoreV2({
          widget: widgetTypes.segmentsAnalysis,
          widgetConfig: {
            segmentsAnalysisParams: this.state.selectedSegmentsParams,
            conversionIndicator: null,
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });
        this.setState({ upliftColumnsKeys: [], upliftRequestId: null });
      }
      if (isFlagActive({ flag: flags.contentImpactBySegmentWidget })) {
        getWidgetsDataFromStoreV2({
          widget: widgetTypes.impactBySegment,
          widgetConfig: {
            impactBySegmentParams: {
              segmentFrequency: this.state.contentImpactFrequency,
              firstSegment: impactBySegmentSelectedSegment,
              fieldsToEnrich: [contentTypeSegmentValue],
              shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
            },
            kpiFocus: this.state.drilldownMetric || conversionIndicator,
            conversionIndicator: originalFunnelFromIndicator,
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
          configKeyName: this.currentTabName,
        });
      }
    }
  }

  updateContentImpactFrequency = ({ newContentImpactByFrequency }) => {
    const {
      originalFunnelFromIndicator, conversionIndicator,
    } = this.props;
    this.setState({ contentImpactFrequency: newContentImpactByFrequency });
    getWidgetsDataV2({
      widget: widgetTypes.impactBySegment,
      widgetConfig: {
        impactBySegmentParams: {
          segmentFrequency: newContentImpactByFrequency,
          firstSegment: this.state.impactBySegmentSelectedSegment,
          fieldsToEnrich: [contentTypeSegmentValue],
          shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
        },
        conversionIndicator: originalFunnelFromIndicator,
        kpiFocus: this.state.drilldownMetric || conversionIndicator,
      },
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
      configKeyName: this.currentTabName,
    });
  };

  updateDrilldownMetric({ metric }) {
    this.setState({ drilldownMetric: metric?.value });

    const {
      originalFunnelFromIndicator, conversionIndicator,
    } = this.props;
    getWidgetsDataV2({
      widget: widgetTypes.impactBySegment,
      widgetConfig: {
        impactBySegmentParams: {
          segmentFrequency: this.state.contentImpactFrequency,
          firstSegment: this.state.impactBySegmentSelectedSegment,
          fieldsToEnrich: [contentTypeSegmentValue],
          shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
        },
        conversionIndicator: originalFunnelFromIndicator,
        kpiFocus: metric?.value || conversionIndicator,
      },
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
      configKeyName: this.currentTabName,
    });
  }

  updateFilters() {
    const newFilters = convertShowOnlyFilters({
      filters: [...this.props.filters],
      userFunnels: this.props.funnels,
      conversionIndicator: this.props.conversionIndicator,
      filtersData: this.props.filtersData,
    });
    this.props.setFilters(newFilters, false);
  }

  updateSegmentsAnalysisParam(newSelectedSegmentsParams) {
    const { conversionIndicator, isCalculateAdvancedMetrics } = this.props;
    this.setState({ selectedSegmentsParams: newSelectedSegmentsParams, upliftColumnsKeys: [], upliftRequestId: null });
    getWidgetsDataV2({
      widget: widgetTypes.segmentsAnalysis,
      widgetConfig: {
        segmentsAnalysisParams: newSelectedSegmentsParams,
        conversionIndicator: null,
      },
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
    });

    if (isCalculateAdvancedMetrics) {
      getWidgetsData({
        widgets: [widgetTypes.trendBySegments],
        configPerWidget: {
          [widgetTypes.trendBySegments]: [{
            trendBySegmentsParams: newSelectedSegmentsParams,
            conversionIndicator,
          }],
        },
        isPreviousTimeFrame: false,
        resultKeyName: this.state.trendBySegmentsKeyName,
      });
    }
  }

  updateShouldUseRelativeTimeframe() {
    const { originalFunnelFromIndicator, conversionIndicator } = this.props;
    this.setState((prevState) => ({ shouldUseRelativeTimeframe: !prevState.shouldUseRelativeTimeframe }), () => {
      getWidgetsDataV2({
        widget: widgetTypes.impactBySegment,
        widgetConfig: {
          impactBySegmentParams: {
            segmentFrequency: this.state.contentImpactFrequency,
            firstSegment: this.state.impactBySegmentSelectedSegment,
            fieldsToEnrich: [contentTypeSegmentValue],
            shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
          },
          conversionIndicator: originalFunnelFromIndicator,
          kpiFocus: this.state.drilldownMetric || conversionIndicator,
        },
        isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
        configKeyName: this.currentTabName,
      });
    });
  }

  updatedUpliftColumnsKeys({ columnsKeys, upliftRequestId }) {
    this.setState({ upliftColumnsKeys: columnsKeys, upliftRequestId });

    const upliftBySegmentsData = this.props.upliftBySegments?.[upliftRequestId]?.result?.data;
    const shouldRequestUpliftBySegments = !upliftBySegmentsData && !columnsKeys.every((key) => upliftBySegmentsData?.[key]);
    if (shouldRequestUpliftBySegments) {
      getWidgetsDataV2({
        widget: widgetTypes.upliftBySegments,
        widgetConfig: {
          upliftBySegmentsParams: {
            selectedMetrics: columnsKeys,
            firstSegment: this.state.selectedSegmentsParams.firstSegment,
            secondSegment: this.state.selectedSegmentsParams.secondSegment,
          },
        },
        parentRequestId: upliftRequestId,
      });
    }
  }

  onWidgetLoaded({ widgetId, widgetType }) {
    const pageWidgetsStatus = this.state.pageWidgetsStatus;
    if (!pageWidgetsStatus[widgetId]) {
      return;
    }

    let isPageLoaded = true;
    for (const [pageWidgetId, pageWidgetData] of Object.entries(pageWidgetsStatus)) {
      if (pageWidgetId === widgetId) {
        continue;
      }

      if (interactionsStore.isWidgetSupported({ widgetType: pageWidgetData.widgetType }) && !pageWidgetData.isLoaded) {
        isPageLoaded = false;
      }
    }

    if (isPageLoaded) {
      interactionsStore.interactionTracker.pageLoaded();
    }

    this.setState((prevState) => ({
      pageWidgetsStatus: {
        ...prevState.pageWidgetsStatus,
        [widgetId]: {
          widgetType,
          isLoaded: true,
        },
      },
    }));
  }

  onWidgetLoading({ widgetId, widgetType }) {
    if (this.state.pageWidgetsStatus[widgetId] && !this.state.pageWidgetsStatus[widgetId].isLoaded) {
      return;
    }

    this.setState((prevState) => ({
      pageWidgetsStatus: {
        ...prevState.pageWidgetsStatus,
        [widgetId]: {
          widgetType,
          isLoaded: false,
        },
      },
    }));
  }

  render() {
    const {
      isContentPages,
      conversionIndicator,
      flags,
      impactBySegment,
      originalFunnelFromIndicator,
      trendBySegments,
      accountOptimizationMetrics,
      filters,
      getWidgetRequestId,
      segmentsAnalysis,
      isCompareToPreviousEnabled,
      isCalculateAdvancedMetrics,
      customFieldsIdToLabelMap,
      customUtmsWhitelist,
      upliftBySegments,
    } = this.props;
    const {
      trendBySegmentsKeyName,
      accountOptimizationMetricsKeyName,
      impactBySegmentSelectedSegment,
      isHideNoValuesRows,
      contentImpactFrequency,
      selectedSegmentsParams,
      shouldUseRelativeTimeframe,
    } = this.state;

    const impactBySegmentFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        impactBySegmentParams: {
          segmentFrequency: this.state.contentImpactFrequency,
          firstSegment: impactBySegmentSelectedSegment,
          fieldsToEnrich: [contentTypeSegmentValue],
          shouldUseRelativeTimeframe: this.state.shouldUseRelativeTimeframe,
        },
        kpiFocus: this.state.drilldownMetric || conversionIndicator,
        conversionIndicator: originalFunnelFromIndicator,
      },
      isPreviousTimeFrame: isCompareToPreviousEnabled,
    });
    const impactBySegmentRequestId = getWidgetRequestId({ widget: widgetTypes.impactBySegment, widgetConfig: impactBySegmentFullWidgetConfig });

    const impactBySegmentData = impactBySegment?.[impactBySegmentRequestId]?.result?.impactData;
    const metadataBySegment = impactBySegment?.[impactBySegmentRequestId]?.result?.metadataBySegment;
    const filteredImpactBySegment = getFilteredImpactBySegmentRecords({
      data: impactBySegmentData, filters, segment: impactBySegmentSelectedSegment, metadataBySegment,
    });

    const impactBySegmentTrendBySegmentsData = trendBySegments?.[`chart${trendBySegmentsKeyName}`]?.data?.[originalFunnelFromIndicator]?.segments;
    const segmentAnalysisTrendBySegmentsData = trendBySegments?.[trendBySegmentsKeyName]?.data;
    const accountOptimizationMetricsData = accountOptimizationMetrics?.[accountOptimizationMetricsKeyName]?.data;

    const segmentsAnalysisFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        segmentsAnalysisParams: selectedSegmentsParams, conversionIndicator: null,
      },
      isPreviousTimeFrame: isCompareToPreviousEnabled,
    });

    const segmentsAnalysisRequestId = getWidgetRequestId({ widget: widgetTypes.segmentsAnalysis, widgetConfig: segmentsAnalysisFullWidgetConfig });
    const segmentAnalysisData = segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.dataByFunnel ?? {};
    const segmentsAnalysisTableData = {};
    for (const [stageName, stageData] of Object.entries(segmentAnalysisData)) {
      segmentsAnalysisTableData[stageName] = getFilteredSegmentAnalysisRecords({
        data: stageData,
        filters,
        segments: selectedSegmentsParams,
      });
    }

    const mergedFunnelsSegmentsAnalysisTableData = getFilteredSegmentAnalysisRecords({
      data: segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.mergedFunnelsTableData ?? [],
      filters,
      segments: selectedSegmentsParams,
    });

    const upliftBySegmentsFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        upliftBySegmentsParams: {
          selectedMetrics: this.state.upliftColumnsKeys,
          firstSegment: selectedSegmentsParams.firstSegment,
          secondSegment: selectedSegmentsParams.secondSegment,
        },
      },
    });
    const upliftBySegmentsRequestId = this.state.upliftRequestId || getWidgetRequestId({ widget: widgetTypes.upliftBySegments, widgetConfig: upliftBySegmentsFullWidgetConfig });
    const upliftBySegmentsData = upliftBySegments?.[upliftBySegmentsRequestId]?.result?.data;

    const isImpactBySegmentLoaded = impactBySegment?.[impactBySegmentRequestId]?.status === 'finished';
    const isImpactBySegmentFailedToLoad = impactBySegment?.[impactBySegmentRequestId]?.status === 'failed';
    const stagesAmount = segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.countPerStage ?? {};
    const segmentsOptionsFields = getSegmentsContentOptionsFields();
    const secondSegmentsOptionsFields = getSegmentsOptionsFields({ customFieldsIdToLabelMap, customUtmsWhitelist });

    const isSegmentAnalysisLoaded = segmentsAnalysis?.[segmentsAnalysisRequestId]?.status === 'finished';
    const isSegmentAnalysisFailedToLoad = segmentsAnalysis?.[segmentsAnalysisRequestId]?.status === 'failed';
    const isLoadedUpliftData = upliftBySegments?.[upliftBySegmentsRequestId]?.status === 'finished';

    return (
      <div>
        <BreadcrumbsTitle breadcrumbs={[pageLabels.analyze, pageLabels.content]} />

        <FeatureFlagsWithBlur flag={flags.contentImpactBySegmentWidget} name={flagsNames.contentImpactBySegmentWidget}>
          <ImpactBySegment
            widgetId={Content.widgetIds.impactBySegment}
            title="Impact by Content"
            isLoaded={isImpactBySegmentLoaded}
            isImpactBySegmentFailedToLoad={isImpactBySegmentFailedToLoad}
            showSegmentDropdown={false}
            showNoValueCheckbox
            hideNoValuesRows={isHideNoValuesRows}
            onChangeHideNoValuesRows={() => this.setState({ isHideNoValuesRows: !isHideNoValuesRows })}
            currentSegmentLabel={isContentPages ? 'Content' : 'Content Type'}
            segmentData={filteredImpactBySegment ?? []}
            segmentPrevData={[]}
            totalBySegment={impactBySegment?.[impactBySegmentRequestId]?.result?.totalBySegment}
            filters={filters}
            totalByFrequency={impactBySegment?.[impactBySegmentRequestId]?.result?.totalByFrequency}
            metricType={impactBySegment?.[impactBySegmentRequestId]?.result?.segmentKey ?? 'attributed'}
            firstSegment={impactBySegmentSelectedSegment}
            conversionIndicator={originalFunnelFromIndicator}
            currentFrequency={contentImpactFrequency}
            onChangeFrequency={(frequency) => this.updateContentImpactFrequency({ newContentImpactByFrequency: frequency.value })}
            relatedEntitiesIndication={impactBySegment?.[impactBySegmentRequestId]?.result?.shouldUseRelatedEntities}
            trendData={impactBySegmentTrendBySegmentsData}
            isLoadedTrendData={isCalculateAdvancedMetrics ? trendBySegments?.[trendBySegmentsKeyName]?.isLoaded : false}
            kpiFocus={this.state.drilldownMetric || conversionIndicator}
            updateFilters={() => this.updateFilters()}
            dataTotals={accountOptimizationMetricsData ?? {}}
            innerPageNavigation={({ filters: filtersForNavigate, isNewFiltersUIOnly }) => navigateBetweenAnalyzeTabs({
              filters: filtersForNavigate, tabName: 'content', withAnimation: false, isNewFiltersUIOnly,
            })}
            timeframe={this.props.timeFrame}
            attributionModel={this.props.attributionModel}
            updateDrilldownMetric={({ metric }) => this.updateDrilldownMetric({ metric })}
            growthByFrequency={impactBySegment?.[impactBySegmentRequestId]?.result?.growthByFrequency}
            growthBySegment={impactBySegment?.[impactBySegmentRequestId]?.result?.growthBySegment}
            growthImpactData={impactBySegment?.[impactBySegmentRequestId]?.result?.growthImpactData}
            isCompareToPreviousEnabled={isCompareToPreviousEnabled}
            isCalculateAdvancedMetrics={isCalculateAdvancedMetrics}
            onChangeCheckedSegments={({ segments }) => this.setState({ checkedSegments: segments })}
            onChangeUncheckedSegments={({ segments }) => this.setState({ uncheckedSegments: segments })}
            checkedSegments={this.state.checkedSegments || []}
            uncheckedSegments={this.state.uncheckedSegments || []}
            shouldUseRelativeTimeframe={shouldUseRelativeTimeframe}
            updateShouldUseRelativeTimeframe={() => this.updateShouldUseRelativeTimeframe()}
            onWidgetLoaded={() => this.onWidgetLoaded({ widgetId: Content.widgetIds.impactBySegment, widgetType: widgetTypes.impactBySegment })}
            onWidgetLoading={() => this.onWidgetLoading({ widgetId: Content.widgetIds.impactBySegment, widgetType: widgetTypes.impactBySegment })}
          />
        </FeatureFlagsWithBlur>
        <FeatureFlagsWithBlur flag={flags.contentAttributionTableWidget} name={flagsNames.contentAttributionTableWidget}>
          <div id="attribution-table">
            <AttributionTable
              widgetId={Content.widgetIds.segmentsAnalysis}
              key={`${conversionIndicator}-content-table`}
              defaultStageKey={conversionIndicator}
              title="Content Impact Analysis"
              data={segmentsAnalysisTableData ?? {}}
              mergedFunnelsTableData={mergedFunnelsSegmentsAnalysisTableData}
              prevData={{}}
              dataNickname={isContentPages ? 'Content' : 'Content Type'}
              type={ATTRIBUTION_TYPES.CONTENT}
              filters={filters}
              dataTotals={accountOptimizationMetricsData ?? {}}
              trendData={segmentAnalysisTrendBySegmentsData}
              isLoadedTrendData={isCalculateAdvancedMetrics ? trendBySegments?.[trendBySegmentsKeyName]?.isLoaded : false}
              isLoaded={isSegmentAnalysisLoaded}
              stagesAmount={stagesAmount}
              attributionModel={this.props.attributionModel}
              timeframe={this.props.timeFrame}
              exportMode
              includeAttributionStoreFilters
              shouldRequestAllFunnelStagesData
              isSegmentAnalysisFailedToLoad={isSegmentAnalysisFailedToLoad}
              segmentsOptionsFields={segmentsOptionsFields}
              secondSegmentsOptionsFields={secondSegmentsOptionsFields}
              isAttribution={segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.isAttribution}
              segmentsAnalysisParams={selectedSegmentsParams}
              updateFilters={() => this.updateFilters()}
              innerPageNavigation={({ filters: filtersForNavigate, isNewFiltersUIOnly }) => navigateBetweenAnalyzeTabs({
                filters: filtersForNavigate, tabName: 'content', anchor: 'attribution-table', withAnimation: false, isNewFiltersUIOnly,
              })}
              updateSegmentsAnalysisParam={(newSelectedSegmentsParams) => this.updateSegmentsAnalysisParam(newSelectedSegmentsParams)}
              onChangeHideNoValuesRows={() => this.setState({ isHideNoValuesRows: !isHideNoValuesRows })}
              hideNoValuesRows={this.state.isHideNoValuesRows}
              isShowHideNoValue
              isCompareToPreviousEnabled={isCompareToPreviousEnabled}
              isCalculateAdvancedMetrics={isCalculateAdvancedMetrics}
              upliftColumnsKeys={this.state.upliftColumnsKeys}
              updatedUpliftColumnsKeys={({ columnsKeys }) => this.updatedUpliftColumnsKeys({ columnsKeys, upliftRequestId: upliftBySegmentsRequestId })}
              upliftData={upliftBySegmentsData}
              isLoadedUpliftData={isLoadedUpliftData}
              onWidgetLoaded={() => this.onWidgetLoaded({ widgetId: Content.widgetIds.segmentsAnalysis, widgetType: widgetTypes.segmentsAnalysis })}
              onWidgetLoading={() => this.onWidgetLoading({ widgetId: Content.widgetIds.segmentsAnalysis, widgetType: widgetTypes.segmentsAnalysis })}
            />
          </div>
        </FeatureFlagsWithBlur>
      </div>
    );
  }
}

export default withLDConsumer()(enhance(Content));
