import servicesStore from 'stores/servicesStore';

export default class KeepAliveModule {
  static keepAliveInterval = 10 * 60 * 1000;

  static currentIntervalId = null;

  static start({ UID }) {
    if (KeepAliveModule.currentIntervalId) {
      clearInterval(KeepAliveModule.currentIntervalId);
    }

    const keepAliveIntervalId = setInterval(() => KeepAliveModule.sendKeepAlive({ UID, intervalId: keepAliveIntervalId }), KeepAliveModule.keepAliveInterval);
    KeepAliveModule.currentIntervalId = keepAliveIntervalId;

    KeepAliveModule.sendKeepAlive({ UID, intervalId: keepAliveIntervalId });
  }

  static stop() {
    if (!KeepAliveModule.currentIntervalId) {
      return;
    }

    clearInterval(KeepAliveModule.currentIntervalId);
    KeepAliveModule.currentIntervalId = null;
  }

  static async sendKeepAlive({ UID }) {
    if (!KeepAliveModule.currentIntervalId) {
      return;
    }

    try {
      await servicesStore.serverCommunication.serverRequest({
        method: 'GET',
        route: 'keep-alive',
      });
    } catch (error) {
      const isUnAuthorizedError = error.response.status === servicesStore.serverCommunication.errorStatus.unAuthorized;
      servicesStore.logger.error('failed to send keep alive', {
        UID,
        error: error.message,
        message: error.response?.message,
        isUnAuthorizedError,
      });

      if (isUnAuthorizedError) {
        KeepAliveModule.stop();
      }
    }
  }
}
